import React from 'react'
import './BartenderSec.css';
import Card from '../../../../components/CommonComp/Card/Card'

import BartenderLogo from '../../../../components/CommonComp/Assests/bartender 1.svg';
import ReportIcon from '../../../../components/CommonComp/Assests/shutterstock_1409636174.png'
import TicketIcon from '../../../../components/CommonComp/Assests/Ticket.png';
import ContentMngMentIcon from '../../../../components/CommonComp/Assests/jam_write-f.svg';
import ElipsIcon from '../../../../components/CommonComp/Assests/Ellipse 63.svg'

import { Link, NavLink } from 'react-router-dom';
import Header1 from '../../../../components/CommonComp/Header1/Header1';

const BartenderSec = () => {
  return (
    <div>
      
        <Header1/>

<div className='row card-row me-3 barsss'>
    <div className='col-lg-4 mb-3 mb-md-0'>
        <Link to='/dashboard-page/bartender-section/bartender-details' className='nav-link'>
             <Card img={BartenderLogo} content='Bartenders Details' />
        </Link>
    </div>
    <div className='col-lg-4  margin-25'>
         <Link to='/dashboard-page/bartender-section/ticket-management' className='nav-link'>
            <Card img={TicketIcon} content='Ticket Management' />
        </Link>
    </div>
    {/* <div className='col-lg-4  margin-25'>
        <Link to='/dashboard-page/bartender-section/content-management' className='nav-link'>
             <Card img={ContentMngMentIcon} content='Content Management' />
        </Link>
    </div> */}
    <div className='col-lg-4  margin-25'>
        <Link to='/dashboard-page/bartender-section/report-item' className='nav-link'>
             <Card img={ReportIcon} content='Reported Bartenders'/>
        </Link>
    </div>
</div>

    </div>
  )
}

export default BartenderSec
