import React, { useEffect, useState } from "react";
import "./BartenderDetails.css";
import CreateUserBtn from "../../../../../components/CommonComp/CreateUserBtn/CreateUserBtn";
import SearchBar2 from "../../../../../components/CommonComp/SearchBar2/SearchBar2";
import {
  allBartenderData,
  fetchParticularDetails,
  handleDeletBartenderUser,
  handleResumeUser,
  handleSuspendUser,
} from "../../../../../Api";
import { Link, useNavigate } from "react-router-dom";
import Card4 from "../../../../../components/CommonComp/Card4/Card4";
import Loader from "../../../../../components/CommonComp/Loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Pagination from "../../../../../components/CommonComp/Pagination/Pagination";

const BartenderDetails = () => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [filter, setFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [barDetails, setBarDetails] = useState([]);
  const [originalBarDetails, setOriginalBarDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [srno, setSrNo] = useState([]);
  const [myTimout, setMyTimeOut] = useState();

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const headers = [
    "SR No.",
    "Bartender Name",
    "Gigs Completed",
    "Years of Experience",
    "Ratings",
    "Earnings",
    "Acc Status",
    "Joined Date",
    "Action",
  ];

  const fetchData = async () => {
    try {
      const res = await allBartenderData({
        searchQuery,
        filter,
        activePage,
        startDate,
        endDate,
        token,
        setError,
      });
      setBarDetails(res.history);
      setOriginalBarDetails(res);

      setActivePage(res.page);
      setTotalPage(res.total_page);
      setNext(res.next);
      setPrev(res.prev);
      setLoading(false);

      const updatedSrNo = Array.from(
        { length: res.history.length },
        (_, index) => (activePage - 1) * 12 + index + 1
      );
      setSrNo(updatedSrNo);
    } catch (error) {
      setLoading(false);
      setError("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [activePage, filter, startDate, endDate]);

  useEffect(() => {
    clearTimeout(myTimout);
    setMyTimeOut(setTimeout(fetchData, 500));
  }, [searchQuery]);

  const handleBartenderClick = (bartenderId) => {
    navigate(`/bartender/bartenderDetails/User/profile/${bartenderId}`);
  };

  const handleCreateUserPage = () => {
    navigate("/bartender/bartenderDetails/CreateUser");
  };

  const handleSearch = (searchQuery, accountStatus, startDate, endDate) => {
    setSearchQuery(searchQuery);
    setFilter(accountStatus);
    setStartDate(startDate || ""); // Ensure startDate is not undefined
    setEndDate(endDate || ""); // Ensure endDate is not undefined
    setActivePage(1);
  };

  // const deleteBartender = async (id) => {
  //   try {
  //     const isConfirmed = window.confirm("Are you sure you want to delete this user?");
  //     if (!isConfirmed) {
  //       console.log("User deletion cancelled.");
  //       return;
  //     }
  //     await handleDeletBartenderUser({ uid:id, token});
  //     setBarDetails(prevBarDetails => prevBarDetails.filter(user => user._id !== id));

  //     console.log("User deleted successfully");

  //     toast.success("Bartender Deleted successfully")
  //   } catch (error) {
  //     toast.error("Some Error occured")
  //     console.error(error.message);
  //   }
  // };

  const suspendUser = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to suspend this Bartender?"
      );
      if (!isConfirmed) {
        return;
      }
     const response= await handleSuspendUser({ uid: id, token });
      fetchData();
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Error suspending bartender:", error);
    }
  };

  const handleResume = async (uid) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to Resume this Bartender Account?"
      );
      if (!isConfirmed) {
        return;
      }
     const response= await handleResumeUser({ uid, token });
      fetchData();
      toast.success(response.message);
    } catch (err) {
      toast.error(err.message);
      console.error("Error resuming user:", err);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "gray"; // Inactive
      case 1:
        return "#00992B"; // Active
      case 2:
        return "#F33"; // Blocked
      case 3:
        return "#F33"; // Suspended
      case 4:
        return "#F33"; // Deleted
      case 5:
        return "#FFBA00"; //Pending Verification
      default:
        return "gray"; // deafult
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="Bartender"
      to="/dashboard-page/bartender-section"
      className="All-color-breakcurm">
      &nbsp;Bartender
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Bartender Details</span>,
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
              {breadcrumbItems.map((item, index) => (
                <div className="all-breakcrum" key={index}>
                  {item}
                  {index < breadcrumbItems.length - 1 && " > "}
                </div>
              ))}
            </div>
            <div className="me-5">
              <CreateUserBtn
                onClick={handleCreateUserPage}
                data="Create New User"
              />
            </div>
          </div>

          <Card4
            data2={originalBarDetails?.total_count || "0"}
            data3={originalBarDetails?.total_user_month || "0"}
            data4={originalBarDetails?.total_active_user || "0"}
          />


          <div className="barom-app-text">
            Below is the list of bartenders in BAROOOM app
          </div>

          <SearchBar2 onSearch={handleSearch} />

          <div className="tbl-strucutre-design me-4">
            <div className="table-wrapper-bartender">
              <div className="table-responsive tbl-design">
                <table className="table table-borderless rounded-corners">
                  <thead>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index} className="text-center">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {barDetails.length === 0 ? (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Bartender found at this moment
                        </td>
                      </tr>
                    ) : (
                      barDetails.map((bartender, index) => {
                        return (
                          <tr
                            key={bartender._id}
                            style={{ textAlign: "center" }}>
                            <td>{srno[index]}</td>
                            <td>
                              <a
                                className="anchr-clik"
                                onClick={() =>
                                  handleBartenderClick(bartender._id)
                                }>
                                {bartender?.name || "-"}
                              </a>
                            </td>
                            <td>{bartender?.gigsCompleted || "-"}</td>
                            <td>{bartender?.YearofExperience || "-"}</td>
                            <td>{bartender?.Rating || "-"}</td>
                            <td>
                              {/* {"$" + `${bartender.earnings}` || "-"} */}

                                      
                          {"$"+ `${bartender?.earnings
                            ? parseFloat(bartender?.earnings).toFixed(2)
                            : "0"}`}
                            </td>

                            <td
                              style={{
                                color: getStatusColor(
                                  bartender?.account_status
                                ),
                              }}>
                              {(() => {
                                switch (bartender?.account_status) {
                                  case 0:
                                    return "Inactive";
                                  case 1:
                                    return "Active";
                                  case 2:
                                    return "Blocked";
                                  case 3:
                                    return "Suspended";
                                  case 4:
                                    return "Deleted";
                                  case 5:
                                    return "Pending Verification";
                                  default:
                                    return "Inactive";
                                }
                              })()}
                            </td>
                            <td>
                              {new Date(
                                bartender?.joinedDate
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })}
                            </td>
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn"
                                  type="button"
                                  id={`dropdownMenuButton${index}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <b>...</b>
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-end bordr-drp"
                                  aria-labelledby={`dropdownMenuButton${index}`}>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleBartenderClick(bartender?._id)
                                      }>
                                      View User 
                                    </a>
                                  </li>
                                  <li>
                                    {bartender?.account_status == 0 ||
                                    bartender?.account_status == 1 ||
                                    bartender?.account_status == 2 ||
                                    bartender?.account_status == 4 ||
                                    bartender?.account_status == 5 ? (
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          suspendUser(bartender?._id)
                                        }>
                                        Suspended User 
                                      </a>
                                    ) : (
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleResume(bartender?._id)
                                        }>
                                        Resume User
                                      </a>
                                    )}
                                    {/* account_status) {
                                  case 0:
                                    return "Inactive";
                                  case 1:
                                    return "Active";
                                  case 2:
                                    return "Blocked";
                                  case 3:
                                    return "Suspended";
                                  case 4:
                                    return "Deleted";
                                  case 5:
                                    return "Pending Verification";
                                  default:
                                    return "Inactive";
                                } */}
                                  </li>
                                  {/* <li><a className="dropdown-item" onClick={() => deleteBartender(bartender._id)}>Delete User Account</a></li> */}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                total_page={totalpage}
                next={next}
                prev={prev}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BartenderDetails;
