import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import LockIcon from '../../components/CommonComp/Assests/ant-design_lock-filled.svg';
import BaroomLogo from '../../components/CommonComp/Assests/Barooom 1.png';
import '../ForgetPass/code.css';
import EyeIcon from '../../components/CommonComp/Assests/eye.svg';
import LockIcon from '../../components/CommonComp/Assests/l.svg';
import Eyeclose from '../../components/CommonComp/Assests/eye-close.svg'
import { toast } from "react-toastify";

export default function CreatePass(props) {
  const navigate = useNavigate();
  const [password, setPassword] = useState(""); // State for password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 



  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword); 
  };
  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); 
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setError("");
  };

  const handleSubmit = () => {
    // Check if any input field is blank
    if (!password || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords did not match");
      toast.error("Passwords did not match");
      return;
    }

    // Check if password length is less than 8 characters
    if (password.length < 8) {
      setError("Password should be at least 8 characters long");
      toast.error("Password should be at least 8 characters long");
      return;
    }

    // Reset the password
    props.resetPassword(password, confirmPassword);

    // Show success message
    // toast.success("Password changed successfully");
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 custom-container-create imgeset">
      <div className="bg-white border border-5 border-warning rounded-3 custom-box-create">
        <div className="d-flex flex-column align-items-center p-2">
          <img src={BaroomLogo} alt="baroom-logo" className="custom-logo-create" />
          <div style={{marginTop:"-20px",fontWeight:"800",fontSize:'35px'}} className="create-new-passwords"> Create New Password</div>
          <section className="mt-3 text-center text-muted custom-section-create">
            <p>Your new password must be different from previously used passwords.</p>
          </section>
          {/* <div className="d-flex flex-column gap-3 mt-5 w-100 custom-input-container-create"> */}
            {/* <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white border-0">
                  <img src={LockIcon} alt="lock-icon" className="custom-icon-create" />
                </span>
              </div>
              <input
                type="password"
                className="form-control border-0 custom-input-create"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div> */}
            {/* <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white border-0">
                  <img src={LockIcon} alt="lock-icon" className="custom-icon-create" />
                </span>
              </div>
              <input
                type="password"
                className="form-control border-0 custom-input-create"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div> */}

<div className="d-flex gap-3 mt-3 align-items-center w-100 bg-white border border-gray-200 rounded-pill px-4 py-2 confrmpssword">
            <img src={LockIcon} className="my-auto w-6" />
            <div className="flex-grow-1">
              <div className="text-bold ms-2">New Password</div>
              <div className="text-muted d-flex">
              <input
                  type={showPassword ? "text" : "password"} 
                  placeholder="Enter your password"
                  className="Pass-input"
                  value={password}
                  required
                  onChange={handlePasswordChange}
                  // onChange={(e) => setUser((prev) => ({ ...prev, password: e.target.value }))}
                />
                
                  {showPassword ? (
                    <img src={EyeIcon} alt="Hide-Password-icon" style={{cursor:'pointer'}} onClick={handlePasswordVisibility}/>
                  ) : (
                    <img src={Eyeclose} alt="Show-Password-icon" style={{cursor:'pointer'}} onClick={handlePasswordVisibility}/>
                  )}
                 
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 mt-3 align-items-center w-100 bg-white border border-gray-200 rounded-pill px-4 py-2 confrmpssword">
            <img src={LockIcon} className="my-auto w-6" />
            <div className="flex-grow-1">
              <div className="text-bold ms-2">Confirm New Password</div>
              <div className="text-muted d-flex">
              <input
                  type={showConfirmPassword ? "text" : "password"} 
                  placeholder="Enter your password"
                  className="Pass-input"
                  value={confirmPassword}
                  required

                  onChange={handleConfirmPasswordChange}
                  // onChange={(e) => setUser((prev) => ({ ...prev, password: e.target.value }))}
                />
                
                  {showConfirmPassword ? (
                    <img src={EyeIcon} alt="Hide-Password-icon" style={{cursor:'pointer'}} onClick={handleConfirmPasswordVisibility}/>
                  ) : (
                    <img src={Eyeclose} alt="Show-Password-icon" style={{cursor:'pointer'}} onClick={handleConfirmPasswordVisibility}/>
                  )}
                 
              </div>
            </div>
          </div>

    {/* </div> */}
        
          <button
            onClick={handleSubmit}
            className="w-75 mt-4 custom-button-create resetpss"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
