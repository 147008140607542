import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GigsDetailinfo1 from '../../../../../../../components/CommonComp/GigsDetailinfo1/GigsDetailinfo1';
import GigsDetailinfo2 from '../../../../../../../components/CommonComp/GigsDetailinfo2/GigsDetailinfo2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { candidateJob } from '../../../../../../../Api';
import { useSelector } from 'react-redux';
import Loader from '../../../../../../../components/CommonComp/Loader/Loader';

const CandidateJob = () => {
  const token = useSelector((state) => state.user.user.token);
  const { id } = useParams();
  const location = useLocation();
  const jobDetailsId = location.state?.hiredJob?.Job?.JobId || [];

  const navigate=useNavigate();

  
  const handleGoBack=()=>{
    navigate(-1)
  }
  // Local state for each instance of CandidateJob
  const [selectedContent, setSelectedContent] = useState('detail1');
  const [value, setValue] = useState(0); // Initialize value to 0 to select the first tab by default
  const [showData, setShowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [estbName, setEstbName] = useState('');
  const [addres, setAddres] = useState('');


  
  const handleJob = async (jobId) => {
    setLoading(true);
    try {
      const response = await candidateJob({ token, jobId });
      setShowData(response); 
      setEstbName(response?.establishmentid?.establishmentName)
      setAddres(response?.establishmentid?.location)

    } catch (error) {
      console.error("Error fetching job details:", error);
      setShowData(null); 
    } finally {
      setLoading(false);
    }
  };

  // Fetch details for the first job on page load or when jobDetailsId changes
  useEffect(() => {
    if (jobDetailsId.length > 0) {
      handleJob(jobDetailsId[0]);
    }
  }, [jobDetailsId]); 

  // Function to handle tab click and fetch corresponding job details
  const handleTabClick = (event, newValue) => {
    setValue(newValue); // Update the active tab
    handleJob(jobDetailsId[newValue]); // Fetch the job details for the selected tab
  };

  
  const handleButtonClick = (content) => {
    setSelectedContent(content);
  };

  // Prepare data for the GigsDetailinfo1 component
  const aboutGig = showData ? {
    title: showData.jobTitle || 'N/A',
    gig: showData.jobDiscription || 'N/A',
    time: `${showData.startTime || 'N/A'} - ${showData.endTime || 'N/A'}`,
    pay: `$${showData.payHourly || 'N/A'}/hour`,
    skills: showData.skillsRequired || [],
    yearofexp: showData.yearsOfExperience || 'N/A',
    dueResp: showData.dutiesRes || [],
    benifits: showData.benifits || []
  } : {};

  // Prepare data for the GigsDetailinfo2 component
  const aboutEstab = showData && showData.establishmentid ? {
    aboutEstab: showData.establishmentid.discription || 'N/A',
    service: showData.establishmentid.serviceType || 'N/A',
    dressCode: showData.establishmentid.dressCode || 'N/A',
    posSoftware: showData.establishmentid.posSystem || 'N/A',
    custormFlow: showData.establishmentid.customerFlow || 'N/A',
    businnesYer: showData.establishmentid.yearsOfExperience || 'N/A',
    webLink: showData.establishmentid.websiteLink || 'N/A'
  } : {};

  return (
    <div>
      <div className='algn-top-bar'>
            <div className='brd-crum' style={{cursor:'pointer'}} onClick={()=>handleGoBack()} > &gt; Go back</div>
            
        </div>
      <div className='estbname-adress'>
        <p>{estbName||"Name not avilable"}</p>
        <p>
        {addres 
          ? `${addres.streetName || ""}, ${addres.city || ""}, ${addres.state || ""}, ${addres.postalCode || ""}`.trim().replace(/^,|,$|,,/g, '') || "Location not available" 
          : "Location not available"}
      </p>
      </div>

      <div className='three-btn'>
        <button
          type='button'
          className={`about-gig comndesing-btn ${selectedContent === 'detail1' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('detail1')}
        >
          About The Gig
        </button>
        <button
          type='button'
          className={`about-est comndesing-btn ${selectedContent === 'detail2' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('detail2')}
        >
          About Establishment
        </button>
      </div>

      {selectedContent === 'detail1' && (
        <div className='mt-4'>
          {loading ? (
            <p><Loader/></p>
          ) : (
            jobDetailsId.length > 0 ? (
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleTabClick}
                  indicatorColor="secondary"
                  aria-label="job tabs"
                >
                  {jobDetailsId.map((job, index) => (
                    <Tab
                      key={job}
                      value={index}
                      label={`Job ${index + 1}`}
                      sx={{ '&.Mui-selected': { color: '#F33' } }}
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <p>No job details available</p>
            )
          )}
          <GigsDetailinfo1 data={aboutGig} />
        </div>
      )}

      {selectedContent === 'detail2' && <GigsDetailinfo2 data={aboutEstab} />}
    </div>
  );
};

export default CandidateJob;
