import React from "react";
import "./Button.css";
const Button = ({ title, title1, onClick, disable, style }) => {
  return (
    <div onClick={onClick} disabled={disable}>
      {title && (
        <button type="button" className="btn butn" style={style}>
          {title}
        </button>
      )}
      {title1 && (
        <button type="button" className="Suspicious-Acccount">
          {title1}
        </button>
      )}
    </div>
  );
};

export default Button;
