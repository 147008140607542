import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { login, logout, updateUser } from "../redux/userSlice";
import { setUnAuthorized } from "../redux/globalSlice";
import { triggerBackgroundAuthentication } from "../Api";
import ModalBox from "../components/modal";

export const globalData = createContext(null);

export const GlobalContext = (props) => {
  const unauthorized = useSelector((state) => state.global.unauthorized);

  const dispatch = useDispatch();
  const [allow, setAllow] = useState(false);

  const loginCheck = async (usr2) => {
    try {
      const response = await triggerBackgroundAuthentication({
        token: usr2.token,
      });

      
      setAllow(true);
      dispatch(updateUser(usr2));
    } catch (error) {
      setAllow(false);
      localStorage.setItem("unauthorized", true);
      dispatch(setUnAuthorized(true));
      console.log("errror", error);
    }
  };

  useEffect(() => {
    const usr =
      localStorage.getItem("bsuser") || sessionStorage.getItem("bsuser");

    if (usr) {
      const usr2 = JSON.parse(usr);
     

      loginCheck(usr2);
    } else {
      setAllow(true);
    }
  }, []);

  const handleOk = async () => {
    console.log("Logging out and resetting state...");
    dispatch(logout());
    dispatch(setUnAuthorized(false));
    setAllow(true);
    console.log("State reset, user logged out");
  };

  return (
    <globalData.Provider value={null}>
      {allow && <>{props.children}</>}
      <ModalBox
        open={unauthorized}
        onClose={() => {}}
        header="Restricted !"
        message={"Login session expired. Please Login again !"}
        onOk={handleOk}
      />
    </globalData.Provider>
  );
};
