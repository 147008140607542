import React from 'react'
import './CreateUserBtn.css';
import CirclPlus from '../../../components/CommonComp/Assests/ant-design_plus-circle-outlined.svg';
const CreateUserBtn = ({onClick,data}) => {

   
  return (
    <>
      
      <div onClick={onClick}>
          <button type="button" className="btn create-user-buttom" >
              <img src={CirclPlus} alt='plus sign' className='plus-icon'/>&nbsp;&nbsp;{data}
        </button>
      </div>   
    
   
     
    </>
  )
}

export default CreateUserBtn
