import React from 'react'
import './CandidateProfile.css';
import Button from '../../../../../../../components/CommonComp/Button/Button'
import LockIcon from '../../../../../../../components/CommonComp/Assests/ant-design_lock-filled.svg';
import  UserIcon from '../../.././../../../../components/CommonComp/Assests/carbon_user-avatar.svg';
import EmailIcn from '../../../../../../../components/CommonComp/Assests/dashicons_email-alt.svg'
import PhoneIcon from '../../../../../../../components/CommonComp/Assests/carbon_phone-voice.svg';
// import SaveProfile from '../../../../../components/CommonComp/SaveProfile/SaveProfile'
import YearOfexpIcon from '../../../../../../../components/CommonComp/Assests/mdi_magic.svg';
import Usr2Icn from '../../../../../../../components/CommonComp/Assests/fa-solid_user-tie.svg';
import pdfIcon from '../../../../../../../components/CommonComp/Assests/bxs_file-pdf.svg';
import EditIcon from '../../../../../../../components/CommonComp/Assests/material-symbols-light_edit-square-outline.svg'
import Ed from '../../../../../../../components/CommonComp/Assests/fluent_edit-48-regular.svg'
import Elips4Icon from '../../../../../../../components/CommonComp/Assests/Ellipse 4.svg'
import ProfileImg from '../../../../../../../components/CommonComp/ProfileImg/ProfileImg';
import Card5 from '../../../../../../../components/CommonComp/Card5/Card5';
import BartenderProfile from '../../../../../BartenderSection/BartenderSec/BartenderProfile/BartenderProfile';
import { useNavigate } from 'react-router-dom';

const CandidateProfile = () => {

  const navigate=useNavigate();

  
const handleGoBack=()=>{
  navigate(-1)
}
  
    const style={
        cursor:"default"
    }
  return (
    <div>
       <div className='algn-top-bar'>
            <div className='brd-crum' style={{cursor:'pointer'}} onClick={()=>handleGoBack()} > &gt; Go back</div>
            
        </div>
        <BartenderProfile
        showMoreOptionButton={false}
        showSuspiciousButton={false}
        showBreadcrumb={false}
        showReferralBox={false}
        
      />
       
   
</div>
  )
}

export default CandidateProfile
