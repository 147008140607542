import React, { useEffect, useState } from "react";
import ReportTable from "../../../../components/CommonComp/ReportTable/ReportTable";
import SearchBar1 from "../../../../components/CommonComp/SearchBar1/SearchBar1";
import { useSelector } from "react-redux";
import { estbReport, GetMarkNoShow } from "../../../../Api";
import Loader from "../../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../../components/CommonComp/Pagination/Pagination";
import UserAvatar from "../../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

function MarkNoShowBar() {
  const token = useSelector((state) => state.user.user.token);

  const [loading, setLoading] = useState(true);
  const [absentBartenders, setAbsentBartenders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [myTimeout, setMyTimeOut] = useState();

  const fetchMarkNoShow = async () => {
    try {
      const response = await GetMarkNoShow({
        token: token,
        searchQuery,
        activePage,
      });
      setAbsentBartenders(response?.history);
      setActivePage(response?.page);
      setTotalPage(response?.total_page);
      setNext(response?.next);
      setPrev(response?.prev);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching report data:", error);
    }
  };

  useEffect(() => {
    fetchMarkNoShow();
  }, [activePage]);

  useEffect(() => {
    clearTimeout(myTimeout);

    setMyTimeOut(setTimeout(fetchMarkNoShow, 500));
  }, [searchQuery]);

  const handleSearch = (searchQuery) => {
    if (searchQuery != "" && searchQuery.trim() != "") {
      setSearchQuery(searchQuery);
      setActivePage(1);
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="EstablishmentTicket"
      to="/dashboard-page/Establishment-section"
      className="All-color-breakcurm">
      &nbsp;Establishment
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Absent Bartender's</span>,
  ];
  const headers = ["Bartender Name", "Job Title", "Establishment Name", "Date of Job"];



  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      <div className="comn-serc" style={{ marginTop: "77px" }}>
        <SearchBar1 data="Search by Bartender Name" onSearch={handleSearch} />
      </div>

      <div className="comnt-tb" style={{ marginTop: "67px" }}>
        {loading ? (
          <p>
            <Loader />
          </p>
        ) : (
          // <ReportTable filteredData={reportEstbData} />
        
            <div>
              <div className="table-responsive2 me-5 table-responsive">
                <table className="table table-responsive">
                  <thead
                    style={{ textAlign: "justify", backgroundColor: "transparent" }}>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index} scope="col" className="table-heading2">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="td-design2 table-responsive">
                    {absentBartenders.length === 0 ? (
                      <tr>
                        <td colSpan="4" className="no-reports">
                          Absent bartender's details not available at this moment
                        </td>
                      </tr>
                    ) : (
                      absentBartenders.map((rowData) => (
                       
                        <tr key={rowData._id}>
                          <td>
                            <div className="user">
                              <img
                                src={
                                  rowData?.bid?.pic
                                    ? `https://d28qcxi1juf339.cloudfront.net/${rowData?.bid?.pic}`
                                    : UserAvatar
                                }
                                alt="User-Image"
                              />
                              <div className="user-details">
                                <div className="name">
                                  {rowData?.bid?.name || "No Data"}
                                </div>
                                {/* <div className="email mt-1">
                                  {rowData?.UserDetails?.email || "No Data"}
                                </div> */}
                              </div>
                            </div>
                          </td>
                          <td className="centered-text">
                            {rowData?.jobid?.jobTitle || "No Data"}
                          </td>
                          <td className="centered-text">
                          <div className="user">
                              <img
                                src={
                                  rowData?.eid?.EprofileImg
                                    ? `https://d28qcxi1juf339.cloudfront.net/${rowData?.eid?.EprofileImg}`
                                    : UserAvatar
                                }
                                alt="User-Image"
                              />
                              <div className="user-details">
                                <div className="name">
                                  {rowData?.eid?.establishmentName || "No Data"}
                                </div>
                                {/* <div className="email mt-1">
                                  {rowData?.UserDetails?.email || "No Data"}
                                </div> */}
                              </div>
                            </div>
                          </td>
                          <td className="centered-text">
                           <div>{dayjs(rowData?.jobid?.startdate).format('DD-MM-YYYY')} , {dayjs(rowData?.jobid?.enddate).format('DD-MM-YYYY')}</div>
                          
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
        
              {/* Modal-related JSX removed */}
            </div>
        )}
        <Pagination
          total_page={totalpage}
          next={next}
          prev={prev}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
}

export default MarkNoShowBar;
