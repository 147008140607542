import React, { useEffect, useRef, useState } from "react";
import "./PushNotification.css";
import CirclPlus from "../../../components/CommonComp/Assests/ant-design_plus-circle-outlined.svg";
import UserIcon from "../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import EditIcon from "../../../components/CommonComp/Assests/jam_write.svg";
import crossIcon from "../../../components/CommonComp/Assests/crossIcon.png";
import AddImgIcon from "../../../components/CommonComp/Assests/fluent_image-add-20-regular.svg";
import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import EditIcons from "../../../components/CommonComp/Assests/akar-icons_edit.svg";
import DeleteIcon from "../../../components/CommonComp/Assests/fluent_delete-24-regular.svg";
import tick from "../../../components/CommonComp/Assests/check.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  CancleNotification,
  createNotification,
  DeleteTemplates,
  deletNotification,
  getNotification,
  GetTemplates,
  PostTemplates,
  updateNotification,
} from "../../../Api";
import Loader from "../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import ParentPush from "./ParentPush";
import { Link } from "react-router-dom";
import { ensureString } from "../../../utils/inputFormatter";
import Position from "rsuite/esm/internals/Overlay/Position";
import NotificationTemplate from "./NotificationTemplate";

const PushNotification = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [checked, setChecked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const [title, setTitle] = useState("");
  const [bodyData, setBodyData] = useState("");
  const [_id, set_id] = useState("");
  const [tempImg, settempImg] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [myTimout, setMyTimeOut] = useState();
  const [filter, setFilter] = useState("");

  const [change, setChange] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedNotificationId, setSelectedNotificationId] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [templateImgPreview, settemplateImgPreview] = useState("");
  const [backendImg, setBackendImg] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createTemplate, setCreateTemplate] = useState(false);
  const [isTemplate, setisTemplate] = useState(false);
  const [tempchange, setTempChange] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState("");
  const [newtemplateData, setNewTemplateData] = useState({
    title: "",
    body: "",
  });
  const [newtemplateImg, setnewtemplateImg] = useState("");

  const createModalRef = useRef();
  const editModalRef = useRef();

  const handleModalClose = () => {
    setShowModal(false);
    handleNotifcationApi();
  };
  // const handleModalOpen = (notificationId) => {
  //   setSelectedNotificationId(notificationId);
  //   setShowModal(true);
  // };

  const handleNotifcationApi = async () => {
    try {
      const response = await getNotification({
        token,
        activePage,
        searchQuery,
        filter,
      });
      setNotifications(response.history);
      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("err", error);
    }
  };

  const handleCreate = async () => {
    if (title && bodyData && selectedFile) {
      try {
        const response = await createNotification({
          token,
          title,
          bodyData,
          selectedFile,
        });
        setTitle("");
        setBodyData("");
        setSelectedFile(null);
        toast.success("Notification created successfully");
        setChange(!change);
        closeCreateModal();
      } catch (err) {
        toast.error("Error creating notification");
        console.log("Error creating notification:", err);
      }
    } else {
      toast.warning("All fields are required");
    }
  };

  const handleCreateTemplate = async () => {
    if (
      newtemplateData.body != "" &&
      newtemplateData.title != "" &&
      (!newtemplateImg || !tempImg)
    ) {
      try {
        const response = await PostTemplates({
          token,
          title: newtemplateData.title,
          body: newtemplateData.body,
          document: newtemplateImg,
          tempImg,
          _id,
        });
        toast.success("Notification Template created successfully");
        setnewtemplateImg("");
        settempImg("");
        set_id("");
        setNewTemplateData({ title: "", body: "" });
        setTempChange(!tempchange);
        setCreateTemplate(false);
        setCreateModalOpen(false);
      } catch (err) {
        toast.error(
          err?.response?.data?.message || "Error creating notification"
        );
        console.log("Error creating notification:", err);
      }
    } else {
      toast.warning("All fields are required");
    }
  };

  const handleEditNotification = async () => {
    if (title.trim == "" || !title || bodyData.trim == "" || !bodyData) {
      toast.error("Title or Description cannot be null");
      return;
    }

    try {
      const response = await updateNotification({
        token,
        id: selectedNotificationId,
        title,
        bodyData,
        selectedFile,
      });
      toast.success("Notification Updated Sucessfully");
      setChange(!change);
      closeEditModal();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("err5", error);
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      const confirm = window.confirm(
        "Are you sure want to delete this notification ?"
      );
      if (!confirm) {
        return;
      }
      await deletNotification({ token, id });
      toast.success("Notification deleted Sucessfully");
      setChange(!change);
    } catch (error) {
      console.log("error", error);
      toast.error("some error occured try again");
    }
  };

  const handleDeleteTemplate = async (_id) => {
    try {
      const confirm = window.confirm(
        "Are you sure want to delete this notification template ?"
      );
      if (!confirm) {
        return;
      }
      await DeleteTemplates({ token, _id });
      toast.success("Notification Template Deleted Sucessfully");
      setChange(!change);
      setTempChange(!tempchange);
    } catch (error) {
      console.log("error", error);
      toast.error("some error occured try again");
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const handleTemplateFileSelect = (e) => {
    const file = e.target.files[0];
    setnewtemplateImg(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      settemplateImgPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const handleModalOpen = (id) => {
    setSelectedNotificationId(id);
    setShowModal(true);
  };

  const openModal = (id, notification) => {
    setSelectedNotificationId(id);
    setTitle(notification.title || "");
    setBodyData(notification.body || "");
    setSelectedFile(notification.document || "");
    setBackendImg(notification.document ? notification.document : null);
    setModalOpen(true);
  };

  const cancleScheduleNotification = async (key) => {
    if (!key) {
      toast.error("Key Not Aval");
    } else {
      try {
        const confirm = window.confirm(
          "Are you sure want to cancle Scheduled notification"
        );
        if (!confirm) {
          return;
        }
        await CancleNotification({ key, token });
        setChange(!change);
      } catch (error) {
        toast.error("Error at canceling Notification");
      }
    }
  };

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setTitle("");
    setBodyData("");
    setSelectedFile(null);
    setImagePreview("");
    setModalOpen(false);
    setCreateModalOpen(false);
    setCreateTemplate(false);
  };

  const closeEditModal = () => {
    setTitle("");
    setBodyData("");
    setSelectedFile(null);
    setImagePreview("");
    setBackendImg("");
    setModalOpen(false);
  };

  const clearTemplateFilePreview = () => {
    settemplateImgPreview(null);
    setnewtemplateImg(null);
    settempImg(null);
    // setBackendImg(null);
  };

  const clearFilePreview = () => {
    setSelectedFile(null);
    setImagePreview(null);
    setBackendImg(null);
  };

  useEffect(() => {
    handleNotifcationApi();
  }, [activePage, filter, change]);

  useEffect(() => {
    // setActivePage(0);
    clearTimeout(myTimout);
    setMyTimeOut(setTimeout(handleNotifcationApi, 500));
  }, [searchQuery]);

  const handleNewTemplateCreate = () => {
    setCreateTemplate(!createTemplate);
    setCreateModalOpen(!createTemplate);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        createModalOpen &&
        createModalRef.current &&
        !createModalRef.current.contains(event.target)
      ) {
        closeCreateModal();
      }
      if (
        modalOpen &&
        editModalRef.current &&
        !editModalRef.current.contains(event.target)
      ) {
        closeEditModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [createModalOpen, modalOpen]);

  const headers = ["Date", "Details", "Status", "Action", "  "];

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="settings" to="/settings" className="All-color-breakcurm">
      &nbsp;Settings
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Push Notifications</span>,
  ];

  const handleTemplateClick = (template) => {
    if (activeTemplate == template._id) {
      setActiveTemplate("");
    } else {
      setActiveTemplate(template._id);
    }
    // setTitle(template.title);
    // setBodyData(template.body);
    // setBackendImg(template.document);
  };

  const EditTemplateClick = (template) => {
    {
      /* body
createdAt
document
title
 */
    }
    setCreateTemplate(true);
    setCreateModalOpen(true);
    settempImg(template?.document);
    setNewTemplateData({ body: template?.body, title: template?.title });
    set_id(template?._id);
    console.log(template);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
        <div className="me-5">
          <button
            type="button"
            className="btn create-user-buttom"
            onClick={openCreateModal}>
            <img src={CirclPlus} alt="plus sign" className="plus-icon" />
            &nbsp; Create New Notification
          </button>
        </div>
      </div>

      <div className="notification-list">
        <div>Notification List</div>

        <div className="custom-search-input-container-3">
          <div className="custom-search-icon-3">
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="custom-icon-size-3"
            />
          </div>
          <input
            type="search"
            placeholder="Search by Title"
            className="custom-search-input-3 me-4"
            value={searchQuery}
            onChange={(e) => {
              if (e.target.value.trim() !== "" || e.target.value === "") {
                let value = ensureString({ value: e.target.value });
                setSearchQuery(value);
              }
            }}
          />
          <select
            id="selectField"
            className="me-2 form-select custom-select multi"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}>
            <option value="">All</option>
            <option value="Now">Sent Notifications</option>
            <option value="Schedule">Scheduled Notifications</option>
            <option value="Draft">Draft</option>
          </select>
        </div>
      </div>

      {/* certe notificaton */}

      <div
        className={`modal fade ${createModalOpen ? "show" : ""}`}
        id="create-notification"
        tabIndex="-1"
        aria-labelledby="create-notificationLabel"
        aria-hidden={!createModalOpen}
        style={{
          display: createModalOpen ? "block" : "none",
          backgroundColor: createModalOpen
            ? "rgba(0, 0, 0, 0.7)"
            : "transparent",
        }}>
        <div
          className="modal-dialog modal-dialog-centered diglo"
          style={{ maxWidth: "750px" }}
          ref={createModalRef}>
          <div
            className="modal-content push-modl-contnt"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
            <div className="modal-header push-modl-header">
              <h6 className="modal-title crete-new" id="exampleModalLabel">
                Create New {!createTemplate ? `Notification` : `Template`}
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeCreateModal}></button>
            </div>
            <div
              className="modal-body d-flex flex-wrap revrsing-modal-direction"
              style={{ height: "500px", overflowY: "auto", flex: "1" }}>
              {/* Column 1: Title, Description, and Image Uploader */}
              {!createTemplate ? (
                <div
                  className="col-12 col-md-12 p-2  order-2 order-md-1"
                  style={{ height: "530px", overflowY: "auto" }}>
                  <div className="input-container1 ps-3 d-flex align-items-center mt-2 notiftctn">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="user-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Title"
                      className="ps-4 last-name comn-inpt-property notiftctn"
                      value={title}
                      onChange={(e) => {
                        let value = ensureString({ value: e.target.value });
                        setTitle(value);
                      }}
                    />
                  </div>
                  <div className="input-container1 ps-3 pt-3 d-flex align-items-center mt-4 notiftctn">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={EditIcon}
                        alt="textarea-Icon"
                        className="text-area-icon same-icon-size"
                      />
                    </div>
                    <textarea
                      placeholder="Description"
                      className="ps-4 your-bio2 notiftctn"
                      style={{ border: "none", resize: "none" }}
                      value={bodyData}
                      onChange={(e) => {
                        let value = ensureString({ value: e.target.value });
                        setBodyData(value);
                      }}
                    />
                  </div>
                  <div className="fileuploader mt-4">
                    <div className="upload-container">
                      <input
                        type="file"
                        id="fileInputCreate"
                        accept="image/png, image/jpeg, image/jpg"
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                      />
                      {!selectedFile && (
                        <label
                          htmlFor="fileInputCreate"
                          className="file-upload-label">
                          <img
                            src={AddImgIcon}
                            alt="Upload Icon"
                            style={{ width: "40px" }}
                          />
                          <div className="upload-text">
                            <div>
                              Upload Your Image Here (png, jpeg, jpg only)
                            </div>
                            <div style={{ fontSize: "17px", color: "#888585" }}>
                              (Max file size 5mb)
                            </div>
                          </div>
                        </label>
                      )}
                      {selectedFile && (
                        <div style={{ maxWidth: "350px" }}>
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="preview-image"
                            style={{ height: "78px", width: "200px" }}
                          />
                        </div>
                      )}
                      {selectedFile && (
                        <div className="selected-file-info">
                          {selectedFile.name} (
                          {(selectedFile.size / 1024).toFixed(2)} KB)
                          <br />
                          <button
                            className="mt-1"
                            onClick={clearFilePreview}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              backgroundColor: "#F33",
                              color: "#FFFF",
                              height: "23px",
                            }}>
                            Clear Image
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="crete-notfi text-align-center mt-4"
                    style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      className="cret-notification mb-4"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        !createTemplate
                          ? handleCreate()
                          : handleCreateTemplate();
                      }}>
                      Create Notification
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="col-12 col-md-12 p-2  order-2 order-md-1"
                  style={{ height: "530px", overflowY: "auto" }}>
                  <div className="input-container1 ps-3 d-flex align-items-center mt-2 notiftctn">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="user-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Template Title"
                      className="ps-4 last-name comn-inpt-property notiftctn"
                      value={newtemplateData.title}
                      onChange={(e) => {
                        // let value = ensureString({ value: e.target.value });
                        setNewTemplateData({
                          ...newtemplateData,
                          title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-container1 ps-3 pt-3 d-flex align-items-center mt-4 notiftctn">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={EditIcon}
                        alt="textarea-Icon"
                        className="text-area-icon same-icon-size"
                      />
                    </div>
                    <textarea
                      placeholder="Template Description"
                      className="ps-4 your-bio2 notiftctn"
                      style={{ border: "none", resize: "none" }}
                      value={newtemplateData.body}
                      onChange={(e) => {
                        // let value = ensureString({ value: e.target.value });
                        setNewTemplateData({
                          ...newtemplateData,
                          body: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="fileuploader mt-4">
                    <div className="upload-container">
                      <input
                        type="file"
                        id="fileInputCreate"
                        accept="image/png, image/jpeg, image/jpg"
                        style={{ display: "none" }}
                        onChange={handleTemplateFileSelect}
                      />
                      {!newtemplateImg && !tempImg && (
                        <label
                          htmlFor="fileInputCreate"
                          className="file-upload-label">
                          <img
                            src={AddImgIcon}
                            alt="Upload Icon"
                            style={{ width: "40px" }}
                          />
                          <div className="upload-text">
                            <div>
                              Upload Your Image Here (png, jpeg, jpg only)
                            </div>
                            <div style={{ fontSize: "17px", color: "#888585" }}>
                              (Max file size 5mb)
                            </div>
                          </div>
                        </label>
                      )}
                      {newtemplateImg && (
                        <div style={{ maxWidth: "350px" }}>
                          <img
                            src={templateImgPreview}
                            alt="Preview"
                            className="preview-image"
                            style={{ height: "78px", width: "200px" }}
                          />
                        </div>
                      )}
                      {tempImg && (
                        <div style={{ maxWidth: "350px" }}>
                          <img
                            src={`https://d28qcxi1juf339.cloudfront.net/${tempImg}`}
                            alt="Preview"
                            className="preview-image"
                            style={{ height: "78px", width: "200px" }}
                          />
                        </div>
                      )}
                      {tempImg && (
                        <div className="selected-file-info">
                          <button
                            className="mt-1"
                            onClick={clearTemplateFilePreview}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              backgroundColor: "#F33",
                              color: "#FFFF",
                              height: "23px",
                            }}>
                            Clear Image
                          </button>
                        </div>
                      )}
                      {newtemplateImg && (
                        <div className="selected-file-info">
                          {newtemplateImg.name} (
                          {(newtemplateImg.size / 1024).toFixed(2)} KB)
                          <br />
                          <button
                            className="mt-1"
                            onClick={clearTemplateFilePreview}
                            style={{
                              borderRadius: "20px",
                              border: "none",
                              backgroundColor: "#F33",
                              color: "#FFFF",
                              height: "23px",
                            }}>
                            Clear Image
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="crete-notfi text-align-center mt-4"
                    style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      className="cret-notification mb-4"
                      data-bs-dismiss="modal"
                      onClick={handleCreateTemplate}>
                      Create Template
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}
      {modalOpen && (
        <div
          className="modal fade show"
          id="editmodl"
          tabIndex="-1"
          aria-labelledby="create-notificationLabel"
          aria-hidden="false"
          data-bs-backdrop="static"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
          <div
            className="modal-dialog modal-dialog-centered diglo"
            style={{ maxWidth: "630px" }}
            ref={editModalRef}>
            <div className="modal-content push-modl-contnt">
              <div className="modal-header push-modl-header">
                <h6 className="modal-title crete-new" id="exampleModalLabel">
                  Edit Notification
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeEditModal}></button>
              </div>
              <div className="modal-body">
                <div className="input-container1 ps-3 d-flex align-items-center mt-2 notiftctn">
                  <div className="accnt-mangmnet-icons">
                    <img
                      src={UserIcon}
                      alt="user-Icon"
                      className="same-icon-size"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Title"
                    className="last-name comn-inpt-property notiftctn"
                    style={{ textIndent: "20px" }}
                    value={title}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.trim() !== "") {
                        setTitle(value);
                      } else {
                        setTitle("");
                      }
                    }}
                  />
                </div>
                <div className="input-container1 ps-3 pt-3 d-flex align-items-center mt-4 notiftctn">
                  <div className="accnt-mangmnet-icons">
                    <img
                      src={EditIcon}
                      alt="textarea-Icon"
                      className="text-area-icon same-icon-size"
                    />
                  </div>
                  <textarea
                    placeholder="Description"
                    className="ps-4 your-bio5 notiftctn"
                    style={{ border: "none" }}
                    value={bodyData}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.trim() !== "") {
                        setBodyData(value);
                      } else {
                        setBodyData("");
                      }
                    }}
                  />
                </div>

                <div className="fileuploader">
                  <div className="upload-container mt-4">
                    {imagePreview || backendImg ? (
                      <div className="preview-image-container">
                        <img
                          src={
                            backendImg
                              ? `https://d28qcxi1juf339.cloudfront.net/${backendImg}`
                              : imagePreview
                          }
                          alt="Notification-img"
                          className="user-img-edit"
                        />
                        <button
                          className="mt-1"
                          onClick={clearFilePreview}
                          style={{
                            borderRadius: "20px",
                            border: "none",
                            backgroundColor: "#F33",
                            color: "#FFFF",
                            height: "24px",
                            width: "75px",
                            fontSize: "10px",
                          }}>
                          Clear Image
                        </button>
                      </div>
                    ) : (
                      <>
                        <input
                          type="file"
                          id="fileInputEdit"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileSelect(e)}
                        />
                        {!selectedFile && (
                          <label
                            htmlFor="fileInputEdit"
                            className="filereder-txt"
                            style={{ cursor: "pointer" }}>
                            <img
                              src={AddImgIcon}
                              alt="Upload Icon"
                              style={{ width: "40px" }}
                            />
                            <div className="upload-text">
                              <div>
                                Upload Your Image Here (png,jpeg,jpg only)
                              </div>
                              <div
                                style={{ fontSize: "17px", color: "#888585" }}>
                                (Max file size 5mb)
                              </div>
                            </div>
                          </label>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="crete-notfi text-align-center mt-4"
                  style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="cret-notification mb-4"
                    data-bs-dismiss="modal"
                    onClick={handleEditNotification}>
                    Update Notification
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* table*/}
      <div style={{ display: "flex", width: "100%" }}>
        <div
          className="notification-table table-responsive"
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div
            className="table-responsive2"
            style={{ width: "100%", paddingInline: "4px", marginTop: "10px" }}>
            <table
              className="table table-responsive"
              style={{ borderRadius: "10px", width: "100%" }}>
              <thead
                style={{
                  textAlign: "left",
                  backgroundColor: "#EEEEEE",
                  borderRadius: "10px",
                }}>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} scope="col" className="table-heading2">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className="td-design2 table-responsive">
                {loading ? (
                  <tr>
                    <td colSpan="4">
                      <Loader />
                    </td>
                  </tr>
                ) : notifications.length === 0 ? (
                  <tr>
                    <td colSpan="4">No Notification available</td>
                  </tr>
                ) : (
                  notifications.map((notification, index) => (
                    <tr key={index}>
                      <td>
                        {new Date(notification?.createdAt).toLocaleDateString(
                          "en-US",
                          { month: "2-digit", day: "2-digit", year: "numeric" }
                        )}
                      </td>
                      <td>
                        <b>{notification?.title || "No title available"}</b>
                        <div>{notification?.body || "No data"}</div>
                      </td>
                      <td>
                        {notification.sent != true ? notification.type : "Sent"}
                      </td>

                      <td style={{ paddingTop: "18px" }}>
                        {notification.sent ? (
                          <p>
                            <img
                              src={tick}
                              alt="check icon"
                              style={{ width: "30px" }}
                            />
                            Sent successfully
                          </p>
                        ) : (
                          <>
                            {!notification.sent &&
                            notification.type != "Scheduled" ? (
                              <button
                                className="acpt ms-3"
                                // data-bs-toggle="modal"
                                // data-bs-target="#parent"
                                onClick={() => {
                                  handleModalOpen(notification._id);
                                }}>
                                Select User type
                              </button>
                            ) : (
                              <div className="mb-2 ms-3">{"Re-Scheduled"}</div>
                            )}
                          </>
                        )}
                      </td>
                      <td className="delet-edit-icon-notification">
                        {!notification.sent &&
                          notification.type != "Scheduled" && (
                            <>
                              <img
                                src={EditIcons}
                                className="edit-notification"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                                // onClick={() => handleEditNotification(notification._id)}
                                onClick={() =>
                                  openModal(notification._id, notification)
                                }
                              />
                              <img
                                src={DeleteIcon}
                                className="delete-notification"
                                style={{ cursor: "pointer" }}
                                data-bs-target="editmodl"
                                onClick={() =>
                                  handleDeleteNotification(notification._id)
                                }
                              />
                            </>
                          )}
                        {notification.type == "Scheduled" &&
                          notification.sent != true && (
                            <>
                              <img
                                src={crossIcon}
                                className="edit-notification"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  width: "25px",
                                }}
                                // onClick={() => handleEditNotification(notification._id)}
                                onClick={() =>
                                  cancleScheduleNotification(notification.key)
                                }
                              />
                            </>
                          )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              total_page={totalpage}
              next={next}
              prev={prev}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          </div>
        </div>
        <NotificationTemplate
          createModalOpen={createModalOpen}
          tempchange={tempchange}
          handleTemplateClick={handleTemplateClick}
          activeTemplate={activeTemplate}
          handleNewTemplateCreate={handleNewTemplateCreate}
          handleDeleteTemplate={handleDeleteTemplate}
          createTemplate={createTemplate}
          handleModalOpen={handleModalOpen}
          setisTemplate={setisTemplate}
          setCreateModalOpen={setCreateModalOpen}
          EditTemplateClick={EditTemplateClick}
        />
      </div>
      <ParentPush
        notificationId={selectedNotificationId}
        showModal={showModal}
        closeModal={handleModalClose}
        isTemplate={isTemplate}
      />
    </div>
  );
};

export default PushNotification;
