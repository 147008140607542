import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../../../components/CommonComp/Loader/Loader";
import Reactquil from "../../../components/CommonComp/Reactquill/Reactquil";
import { getReportContent, PatchReportContent } from "../../../Api";
import "./ReportsContent.css";
import Button from "../../../components/CommonComp/Button/Button";
function ReportsContent() {
  const token = useSelector((state) => state.user.user.token);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const AddNewCont = () => {
    const lastReport = reports[reports.length - 1];

    if (lastReport != "" && lastReport != null) {
      setReports([...reports, ""]);
    } else {
      toast.error("Empty Filed Exists");
    }
  };

  const RemoveNewCont = (ind) => {
    const lastReport = reports.length;

    if (lastReport > 1) {
      const updatedReports = reports.filter((_, index) => index !== ind);
      setReports(updatedReports);
    } else {
      toast.error("Cannot remove the last report or empty field exists.");
    }
  };

  const GetReport = async () => {
    try {
      const response = await getReportContent({ token });
      // setPrivacyPolicy(response.PrivacyPolicy);
      setReports(response.Report);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateReportContent = async () => {
    const emptyReportIndex = reports.findIndex(
      (report) => report.trim() === ""
    );

    if (reports.length === 0) {
      toast.error("Reports array cannot be empty.");
      return;
    }

    if (emptyReportIndex !== -1) {
      toast.error(
        `Report at position ${
          emptyReportIndex + 1
        } is empty. Please fill it out.`
      );
      return;
    }

    try {
      const response = await PatchReportContent({ token, data: reports });
      setReports(response.Report);
      setLoading(false);
      toast.success(response.message);
    } catch (err) {
      console.log(err);
      toast.error("Failed to update reports. Please try again.");
    }
  };

  useEffect(() => {
    GetReport();
  }, []);

  // const handlePrivacyContentChange = (content) => {
  //   setPrivacyPolicy(content);
  // };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="contentPrivacyPolicy"
      to="/content-management"
      className="All-color-breakcurm">
      &nbsp;Content Management
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Report Content</span>,
  ];
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          marginTop: "5%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
        }}>
        {!loading &&
          reports?.map((elem, ind) => (
            <div
              key={`Report_${ind}`}
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                paddingInline: "30px",
              }}>
              {ind + 1}.
              <input
                type="text"
                value={elem}
                style={{
                  width: "80%",
                  background: "#EFEFEF",
                  border: 0,
                  borderRadius: "18px",
                  paddingLeft: "10px",
                }}
                onChange={(e) => {
                  const updatedReports = [...reports];
                  updatedReports[ind] = e.target.value;
                  setReports(updatedReports);
                }}
              />
              <button
                className="ReprotAddBtn0"
                style={{
                  background: "#FF3333",
                  color: "white",
                  border: 0,
                }}
                onClick={() => RemoveNewCont(ind)}>
                &#10006;
              </button>
            </div>
          ))}

        <div style={{ alignItems: "center", textAlign: "center" }}>
          <button
            className="ReprotAddBtn0"
            style={{ background: "#FF3333", color: "white", border: 0 }}
            onClick={() => AddNewCont()}>
            &#10010;
          </button>
        </div>
      </div>

      <div className="about-btn">
        <Button
          title="Update Report Contents"
          onClick={() => UpdateReportContent()}
        />
      </div>
    </div>
  );
}

export default ReportsContent;
