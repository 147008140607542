import React, { useEffect, useState } from "react";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import EmailIcon from '../../components/CommonComp/Assests/mail.png';
import LockIcon from '../../components/CommonComp/Assests/l.svg';
import EyeIcon from '../../components/CommonComp/Assests/eye.svg';
import passShowIcon from  '../CommonComp/Assests/icons8-hide-96.png'
import BaroomLogo from '../CommonComp/Assests/Barooom 1.png'
import { hanldeSignUp } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/userSlice";
import Eyeclose from '../CommonComp/Assests/eye-close.svg'
import { toast } from "react-toastify";
import NotAuthenticatedLayout from "../layouts/NotAuthenticatedLayout";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [user, setUser] = useState({
    email: "",
    password: "",
    keepLogged: false
  });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); 
  const [disableSendOtp, setDisableSendOtp] = useState(false); 
 


  const handleSendOtp = async () => {


    if (!user.email || !user.password) {
      toast.warning("Email and Password required");
      return;
    }


    try {
      let role=0;
      setDisableSendOtp(true);
      const loginuser = await hanldeSignUp({ user, setError,role });
   
      navigate("/verify",{state:{email:user.email,id:loginuser.refId,keepLogged:user.keepLogged}});

      toast.info("Verify your OTP");
    } 
    catch (err) {
      setDisableSendOtp(false);
      toast.error("Incorrect email or password");
      console.log("error", err);
    } 
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword); 
  };

  

 const  handleForget=()=>{
  navigate('/forgetPass')
 }

  return (
    <NotAuthenticatedLayout>
<div className="d-flex align-items-center justify-content-center min-vh-100 Outer">
     <div className="d-flex flex-column align-items-center bg-white border border-5 border-warning rounded-3 p-2 custom-container-sign">
          <img src={BaroomLogo} alt="Baroom-Logo" className="mb-4 custom-logo-login" />
          <div className="welcome-text-frst">Welcome! Let’s Get Started!</div>
          <div className="d-flex gap-3 mt-3 align-items-center w-100 bg-white border border-gray-200 rounded-pill px-4  custom-input-container-signup">
            <img  src={EmailIcon} className="w-6" />
            <div className="flex-grow-1 pt-1">
              <div className="text-bold ms-2">Email Address</div>
              
              <input
                  type="email"
                  placeholder="Your email address"
                  className="Email-inpt"
                  value={user.email}
                  required
                  onChange={(e) => setUser((prev) => ({ ...prev, email: e.target.value }))}
                />
            
            </div>
          </div>
          <div className="d-flex gap-3 mt-3 align-items-center w-100 bg-white border border-gray-200 rounded-pill px-4  custom-input-container-signup">
            <img src={LockIcon} className="w-6" />
            <div className="flex-grow-1 pt-1">
              <div className="text-bold ms-2">Password</div>
             
              <input
                  type={showPassword ? "text" : "password"} 
                  placeholder="Enter your password"
                  className="Pass-input"
                  value={user.password}
                  required
                  onChange={(e) => setUser((prev) => ({ ...prev, password: e.target.value }))}
                />
                
                  {showPassword ? (
                    <img src={EyeIcon} alt="Hide-Password-icon" style={{cursor:'pointer'}} onClick={handlePasswordVisibility}/>
                  ) : (
                    <img src={Eyeclose} alt="Show-Password-icon" style={{cursor:'pointer'}} onClick={handlePasswordVisibility}/>
                  )}
                 
              
            </div>
          </div>



          <div className="mb-2 mt-2 keepmeloign">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="keepLoggedIn"
                  value={user.keepLogged}
                  onChange={() => setUser((prev) => ({ ...prev, keepLogged: !user.keepLogged }))}
                />
                <label className="form-check-label logged-in" htmlFor="keepLoggedIn">Keep Me Logged in</label>
              </div>
              <div className="mt-3 mt-sm-3 ms-1 ms-sm-2"> 
                <span className="mt-3 mt-sm-0 forgetPassword" style={{cursor:"pointer"}} onClick={()=>handleForget()}>Forgot Your Password?</span>
              </div>
          </div> 

          
         

          <div className="mb-5 mt-4 butn-contner">
              <button className="button1" onClick={()=>handleSendOtp()} disabled ={disableSendOtp}>
              {"Send OTP"}
              </button>
            </div>
        </div>
        </div>
    </NotAuthenticatedLayout>
  );
};

export default Signup;
