import React, { useEffect, useState } from "react";
import "./PushNotification.css";
import test from "../../../components/CommonComp/Assests/sucess.png";
import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import Delet from "../../../components/CommonComp/Assests/fluent_delete-24-regular.svg";
import LessthanIcon from "../../../components/CommonComp/Assests/Vector.png";
import AddCircle from "../../../components/CommonComp/Assests/Addcircle.png";
import { deletGroupUser, handleParticularGroupUsers } from "../../../Api";
import { useSelector } from "react-redux";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import Loader from "../../../components/CommonComp/Loader/Loader";
import { toast } from "react-toastify";
import FinalUserPageModal from "./FinalUserPageModal";
import UserAvtar from "../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import { ensureString } from "../../../utils/inputFormatter";

const AddMoreUserPageModal = ({
  setActiveId,
  gid,
  activeGroup,
  onGroupUserIdsChange,
}) => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [myTimeout, setMyTimeout] = useState();

  const [gName, setGname] = useState("");
  const [gImg, setGimg] = useState("");
  const [gUser, setGuser] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [change, setChange] = useState(false);

  const groupUser = async () => {
    try {
      const response = await handleParticularGroupUsers({
        token,
        searchQuery,
        activePage,
        gid,
      });
      setUsers(response.history);
      setGname(response?.group_name);
      setGimg(response?.group_img);
      setGuser(response?.group_users);
      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error in fetching group users", error);
    }
  };

  useEffect(() => {
    groupUser();
  }, [activePage, change]);

  useEffect(() => {
    setActivePage(0);

    clearTimeout(myTimeout);
    setMyTimeout(setTimeout(groupUser, 500));
  }, [searchQuery]);

  const deleteUserFromGroup = async (id) => {
    try {
      if (users.length == 1 || users.length <= 1) {
        toast.error("Group Should Have alteast 1 User.");
        return;
      }
      const confirm = window.confirm("Are you sure want to delet this user");
      if (!confirm) {
        return;
      }
      let action = false;
      const response = await deletGroupUser({ token, action, id, gid });

      toast.success("User deleted");
      setChange(!change);
    } catch (error) {
      toast.error("Some error occured try again");
      console.log("error in deleting", error);
    }
  };

  const handleAddUserClick = () => {
    const userIdsInGroup = users.map((user) => user._id);
    setActiveId(5);
    setIsModalOpen(true);
    onGroupUserIdsChange(userIdsInGroup);
  };
  return (
    <div className="add-more-user-container">
      <div className="group-icon-name-count">
        <img
          src={LessthanIcon}
          alt="less-than-icon"
          style={{ height: "15px", cursor: "pointer" }}
          onClick={() => setActiveId(3)}
        />

        <div className="form-check user-item-addmorepage ">
          <img
            src={
              gImg ? `https://d28qcxi1juf339.cloudfront.net/${gImg}` : UserAvtar
            }
            alt="User img"
            className="user-img"
          />
          <div className="ms-3">
            <div className="user-name">{gName}</div>
            <div className="user-email">{gUser ? gUser : ""} Users</div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="Add-new-usr ms-5 d-flex justify-content-center align-items-center gap-2"
            onClick={() => handleAddUserClick()}>
            <img
              src={AddCircle}
              alt="add-circle-img"
              className="add-crcl-icon"
            />
            <div>Add More User</div>
          </button>
          {/* {isModalOpen && (
            <FinalUserPageModal
              gid={gid}

              userIdsInGroup={userIdsInGroup}
            />
          )} */}
        </div>
      </div>

      <div className="ms-2 mt-2 fs-4 align-items-stretch">
        <div className="search-container w-100">
          <img src={SearchIcon} alt="search-icon" />
          <input
            type="search"
            className="specificUserSeacrh w-100"
            placeholder="Search User"
            value={searchQuery}
            onChange={(e) => {
                if (e.target.value.trim() !== "" || e.target.value === "") {
              let value = ensureString({ value: e.target.value });
           
                setSearchQuery(value);
              
            }
            }}
          />
        </div>
      </div>

      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {users && users.length == 0 ? (
            <div className="text-center mt-4">No users found.</div>
          ) : (
            <>
              {users.map((item, index) => (
                <div
                  key={item._id}
                  className="d-flex flex-row justify-content-between mt-4 mb-4">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="form-check user-item-addmorepage">
                      <img
                        src={
                          item.pic
                            ? `https://d28qcxi1juf339.cloudfront.net/${item.pic}`
                            : UserAvtar
                        }
                        alt="User img"
                        className="user-img"
                      />
                      <div className="user-details ms-3">
                        <div className="user-name">
                          {item?.name || "Name not available"}
                        </div>
                        <div className="user-email">
                          {item?.email || "Email not available"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Delet}
                    onClick={() => deleteUserFromGroup(item._id)}
                    style={{ cursor: "pointer" }}
                    alt="delete-icon"
                    className="me-2"
                  />
                </div>
              ))}
            </>
          )}
        </>
      )}
      <Pagination
        total_page={totalPage}
        next={next}
        prev={prev}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  );
};
export default AddMoreUserPageModal;
