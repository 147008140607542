import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import { BsJustify } from "react-icons/bs";
import dashicon from "../Assests/bxs_dashboard.png";
import AdminIcon from "../Assests/clarity_administrator-line.png";
import SettingIcon from "../Assests/ci_settings.png";
import BaroomIcon from "../Assests/Barooom 1.png";
import contnetIcon from "../Assests/contnt.svg";
import paymentIcon from "../Assests/payment.svg";
import { Link, useLocation } from "react-router-dom";

function Sidebar({ openSidebarToggle, OpenSidebar, isMdOrAbove }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("dashboard-page");
  const [chnge, setChnge] = useState(false);

  useEffect(() => {
    
    setActiveLink(location.pathname.split("/").filter((lik) => lik)[0]);

  }, [location.pathname]);

  const handleLinkClick = () => {
    setChnge(!chnge);
    OpenSidebar();
  };

  const sidebarTab = [
    "dashboard-page",
    "content-management",
    "payment-management",
    "account-management",
    "admin-management",
    "charge-management",
    "settings",
  ];

  return (
    <aside
      id="sidebar"
      className={`sidebar-responsive ${
        !isMdOrAbove && !openSidebarToggle ? "d-none" : ""
      }`}>
      <div className="sidebar-title">
        <div className="sidebar-brand">
          {isMdOrAbove && !openSidebarToggle && (
            <BsJustify className="icon close_icon" onClick={OpenSidebar} />
          )}
          <span className="icon close_icon crossicon" onClick={OpenSidebar}>
            X
          </span>
          <div className="text-center Broom-container">
            <div className="barooom-text">
              <img src={BaroomIcon} alt="Baroom Icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-list">
        <div
          className={`ms-2 sidebar-list-item sidebar-list-dash dashbord ${
             activeLink === "dashboard-page" ? "active" 
                : activeLink==="bartender"?"active"
                : activeLink==="estb"?"active"
                : activeLink==="dashboard"?"active"
                : activeLink==="candidateProfile"?"active"
                : activeLink==="jobposting"?"active":""
          }`}>
          <Link
            to="/dashboard-page"
            className={`anch-icon ${
              activeLink === "dashboard-page" ? "active" 
                : activeLink==="bartender"?"active":""
            }`}
            onClick={() => handleLinkClick("dashboard-page")}>
            <img src={dashicon} alt="Dashboard Icon" className="dash-icon" />
            &nbsp;{" "}
            <span
              className={`dash-text ${
                activeLink === "dashboard-page" ? "bold" :""
              }`}>
              Dashboard
            </span>
          </Link>
        </div>
        <div
          className={`ms-2 sidebar-list-item sidebar-list-content ${
            activeLink === "content-management" ? "active" : ""
          }`}>
          <Link
            to="/content-management"
            className={`anch-icon ${
              activeLink === "content-management" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("content-management")}>
            <img
              src={contnetIcon}
              alt="Content Management Icon"
              className="content-icon"
            />
            &nbsp;{" "}
            <span
              className={`content-text ${
                activeLink === "content-management" ? "bold" : ""
              }`}>
              Content Management
            </span>
          </Link>
        </div>

        <div
          className={`ms-2 sidebar-list-item sidebar-list-payment ${
            activeLink === "payment-management" ? "active" : ""
          }`}>
          <Link
            to="/payment-management"
            className={`anch-icon ${
              activeLink === "payment-management" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("payment-management")}>
            <img
              src={paymentIcon}
              alt="Payment Management Icon"
              className="payment-icon"
            />
            &nbsp;
            <span
              className={`payment-text ${
                activeLink === "payment-management" ? "bold" : ""
              }`}>
              Payment Management
            </span>
          </Link>
        </div>

        <div
          className={`ms-2 sidebar-list-item sidebar-list-Accnt ${
            activeLink === "account-management" ? "active" : ""
          }`}>
          <Link
            to="/account-management"
            className={`anch-icon ${
              activeLink === "account-management" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("account-management")}>
            <img
              src={AdminIcon}
              alt="Account Management Icon"
              className="admin-icon"
            />
            &nbsp;{" "}
            <span
              className={`account-text ${
                activeLink === "account-management" ? "bold" : ""
              }`}>
              Account Management
            </span>
          </Link>
        </div>
        <div
          className={`ms-2 sidebar-list-item sidebar-list-admin ${
            activeLink === "admin-management" ? "active" : ""
          }`}>
          <Link
            to="/admin-management"
            className={`anch-icon ${
              activeLink === "admin-management" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("admin-management")}>
            <img
              src={AdminIcon}
              alt="Admin Management Icon"
              className="manag-icon"
            />
            &nbsp;
            <span
              className={`manag-text ${
                activeLink === "admin-management" ? "bold" : ""
              }`}>
              Admin Management
            </span>
          </Link>
        </div>
        <div
          className={`ms-2 sidebar-list-item sidebar-list-admin ${
            activeLink === "charge-management" ? "active" : ""
          }`}>
          <Link
            to="/charge-management"
            className={`anch-icon ${
              activeLink === "charge-management" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("charge-management")}>
            <img
              src={paymentIcon}
              alt="charge Management Icon"
              className="manag-icon"
            />
            &nbsp;
            <span
              className={`manag-text ${
                activeLink === "charge-management" ? "bold" : ""
              }`}>
              Charge Management
            </span>
          </Link>
        </div>
        <div
          className={`ms-2 sidebar-list-item sidebar-list-setting ${
            activeLink === "settings" ? "active" : ""
          }`}>
          <Link
            to="/settings"
            className={`anch-icon ${activeLink === "settings" ? "active" : ""}`}
            onClick={() => handleLinkClick("settings")}>
            <img
              src={SettingIcon}
              alt="Settings Icon"
              className="setting-icon"
            />
            &nbsp;
            <span
              className={`setting-text ${
                activeLink === "settings" ? "bold" : ""
              }`}>
              Settings
            </span>
          </Link>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
