import React from 'react';
import './Card2.css';

const Card2 = ({ img, content }) => {
  return (

    <div className="card crd2">
      <div className="card-body d-flex flex-column align-items-center justify-content-center">
      {img && <img src={img} className="card-img-top crd-icons" alt="Card Image" />}
        <div className="text-center">{content}</div>
      </div>
    </div>
  );
};

export default Card2;
