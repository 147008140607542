import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";


const NotAuthenticatedLayout = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const navigator = useNavigate();
  // const user1 = sessionStorage.getItem("ID");
  const [allowView, setAllowView] = useState(false);
  // console.log(user);
  // console.log(user?.account_status);
  
  useEffect(() => {
    if (user) {
        navigator("/dashboard-page");
    } else {
      setAllowView(true);
    }
  }, [user]);

  return <>{allowView && <>{children}</>}</>;
};

export default NotAuthenticatedLayout;
