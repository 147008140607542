import React, { useEffect, useRef, useState } from "react";
import FiveBtnModal from "./FiveBtnModal";
import SchedulePagesModal from "./SchedulePagesModal";
import GroupModal from "./GroupModal";
import AddMoreUserPageModal from "./AddMoreUserPageModal";
import FinalUserPageModal from "./FinalUserPageModal";
import CreateNewGroupModal from "./CreateNewGroupModal";
import SpecificUserModal from "./SpecificUserModal";
import SendNowAndLaterOnModal from "./SendNowAndLaterOnModal";
import { notificationtToAny } from "../../../Api";
import { useSelector } from "react-redux";

const ParentPush = ({ notificationId, showModal, closeModal, isTemplate }) => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [type, setType] = useState("");
  const [gid, setGid] = useState([]);
  const [ids, setIds] = useState([]);
  const [notificationType, setNotificationType] = useState("");
  const [notifyTime, setNotifyTime] = useState("");
  const [notifyDate, setNotifyDate] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [sent, setSent] = useState(false);
  const [activeId, setActiveId] = useState(0);

  const [groupUserIds, setGroupUserIds] = useState([]);

  const handleGroupUserIds = (userIds) => {
    setGroupUserIds(userIds);
    // console.log("Received User IDs from child:", userIds);
  };

  const [activeGroup, setActiveGroup] = useState({
    gName: "",
    gImg: "",
    gUesr: "",
  });
  const modalRef = useRef(null);

  useEffect(() => {
    if (sent) {
      sendNotification();
    }

    return () => {
      setActiveId(0);
    };

    // removed this from useffect arry -->  notificationType,
  }, [sent]);

  // useEffect(() => {
  //   const handleModalHidden = () => {
  //     setActiveId(0);
  //     closeModal();
  //   };

  //   const modalElement = document.getElementById('parent');
  //   modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

  //   return () => {
  //     modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
  //   };
  // }, [closeModal]);

  // this added for close btn issue
  useEffect(() => {
    if (showModal) {
      const modalElement = modalRef.current;
      const bootstrapModal = new window.bootstrap.Modal(modalElement);
      bootstrapModal.show();

      modalElement.addEventListener("hidden.bs.modal", () => {
        closeModal();
        setActiveId(0);
      });
      return () => {
        bootstrapModal.hide();
      };
    }
  }, [showModal, closeModal]);

  const sendNotification = async () => {
    if (sent) {
      try {
        const response = await notificationtToAny({
          notificationId,
          type,
          ids,
          notificationType,
          notifyTime,
          notifyDate,
          timeZone,
          gid,
          token,
          isTemplate,
        });

        setSent(false);
        setActiveId(0);
        setNotifyDate("");
        setNotifyTime("");
        setTimeZone("");
        setIds([]);
        setGid([]);
      } catch (error) {
        setSent(false);
        setActiveId(0);
        setNotifyDate("");
        setNotifyTime("");
        setTimeZone("");
        setIds([]);
        setGid([]);
      }
    }
  };

  const active = () => {
    switch (activeId) {
      case 0:
        return (
          <>
            <FiveBtnModal
              type={type}
              setType={setType}
              setActiveId={setActiveId}
            />
          </>
        );
      case 1:
        return (
          <SendNowAndLaterOnModal
            notificationType={notificationType}
            setNotificationType={setNotificationType}
            setSent={setSent}
            setActiveId={setActiveId}
          />
        );
        break;
      case 2:
        return (
          <SchedulePagesModal
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            notifyDate={notifyDate}
            setNotifyDate={setNotifyDate}
            notifyTime={notifyTime}
            setNotifyTime={setNotifyTime}
            setSent={setSent}
            setActiveId={setActiveId}
          />
        );
        break;

      case 3:
        return (
          <GroupModal
            setActiveId={setActiveId}
            setGid={setGid}
            setActiveGroup={setActiveGroup}
          />
        );
        break;
      case 4:
        return (
          <AddMoreUserPageModal
            setActiveId={setActiveId}
            gid={gid}
            activeGroup={activeGroup}
            onGroupUserIdsChange={handleGroupUserIds}
          />
        );
        break;
      case 5:
        return (
          <FinalUserPageModal
            setActiveId={setActiveId}
            setIds={setIds}
            ids={ids}
            setGid={setGid}
            gid={gid}
            groupUserIds={groupUserIds}
          />
        );
        break;
      case 6:
        return <CreateNewGroupModal setActiveId={setActiveId} />;
        break;
      case 7:
        return <SpecificUserModal setIds={setIds} setActiveId={setActiveId} />;
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* Modal Backdrop */}
      {showModal && <div className="show"></div>}

      {/* Modal Content */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        ref={modalRef}
        tabIndex="-1"
        aria-hidden={!showModal}
        inert>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content allmodalborder">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn-close me-3 mt-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}></button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center">
              {active()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentPush;
