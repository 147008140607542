import React, { useState } from "react";
import "./Table.css";
import { deleteReview } from "../../../Api";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";

const Table1 = ({ headers, reviewData, handleDeleteReview}) => {

  const token=useSelector((state)=>state.user.user.token);

 
  const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const decimal = rating - fullStars;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={i} className="bi bi-star-fill yellow-star"></span>);
    }

    if (decimal > 0) {
      stars.push(<span key="partial" className="bi bi-star-half yellow-star"></span>);
    }

    for (let i = stars.length; i < 5; i++) {
      stars.push(<span key={i} className="bi bi-star yellow-star"></span>);
    }
    return <div>{stars}</div>;
  };

  
  // const handleDeleteReview = async (id) => {
  //   try {
  //     const isConfirmed = window.confirm(
  //       "Are you sure you want to delete this Review?"
  //     );
  //     if (!isConfirmed) {
       
  //       return;
  //     }
  //     const reviewToDelete = reviewData.find((review) => review._id === id);
  //     if (!reviewToDelete) {
  //       console.log("Review not found for deletion");
  //       return;
  //     }
  //     const response = await deleteReview({ rid:id, token:token });
  //     setChange(!change)
  //     toast.success("Review Deleted Succesfully")
  //     console.log("Deleted review:", reviewToDelete,response);
  //   } catch (error) {
  //     toast.warning("Some error occured")
  //     console.log("Error deleting review:", error);
  //   }
  // };
 


  return (
    <div>
        <div className="table-responsive1 me-5 table-responsive">
          <table className="table table-responsive">
            <thead style={{ textAlign: "justify", backgroundColor: "transparent" }}>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} scope="col" className="table-heading">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="td-design table-responsive">
              {reviewData.length > 0 ? ( 
            
                reviewData.map((row, index) => (                          
                 
                  <tr key={index}>
                    <td>{row?.Reviewer?.establishmentName  ? `Review By: ${row.Reviewer.establishmentName}` : row?.Reviewer?.name ? `Review By: ${row.Reviewer.name}`:""}</td>
                    <td>
                      <div className="row-detail">{row?.detail}</div>
                    </td>
                    <td>{<StarRating rating={row?.overall} />}</td>
                    <td>{row?.date}</td>
                    <td>
                      <div className="dropdown drop-down">
                        <button
                          className="btn"
                          type="button"
                          id={`dropdownMenuButton${row._id}`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <b>...</b>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby={`dropdownMenuButton${row._id}`}
                        >
                          <li>
                            <a className="dropdown-item" onClick={() => handleDeleteReview(row._id)}>
                              Delete Review
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={headers?.length} style={{ textAlign: "center" }}>
                  Reviews not available at this moment
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </div>
  );
};

export default Table1;