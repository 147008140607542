import React, { useState ,useEffect} from 'react';
// import './chk.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Route, Routes } from 'react-router-dom';
import './Main.css';
import Home from '../Home/Home';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../../../fireBase';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../redux/globalSlice';
import { notifiedToken } from '../../../Api';

function Main({ children }) {
    const token = useSelector((state) => state?.user?.user?.token);
    const dispatch=useDispatch();
    
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [isMdOrAbove, setIsMdOrAbove] = useState(window.innerWidth >= 992);
    

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };
 
    const handleResize = () => {
        setIsMdOrAbove(window.innerWidth >= 992); 
    };
  
    // window.addEventListener('resize', handleResize);

    useEffect(() => {
        
        window.addEventListener('resize', handleResize);

        
        requrestPermission();

        onMessage(messaging, (payload) => {
            dispatch(setNotification(true));
        });

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    // useEffect(() => {
    //     requrestPermission();
      
    //   onMessage(messaging,(payload)=>{dispatch(setNotification(true))})
    
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
       
    // }, []);


    async function requrestPermission() {
       
        try {
      
            const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const Tok = await getToken(messaging, {
            vapidKey:
              "BCjoKlhc791En9xNRcAP9GJC6AMhnGHmCiKcYZ-1TYrz7BTi6YNnsncHq9dPNJjQV3AvUjBHfRlkm4GJ69JZWnE",
          });
          
          await notifiedToken({Tok,token})
          
        } else if (permission === "denied") {
          alert("You denied for the notification");
        }
        } catch (error) {
            console.log("errror cretingt otken",error);
        }
        
      }


    return (
        <AuthenticatedLayout>
            <div className='grid-container'>
                <Sidebar isMdOrAbove={isMdOrAbove} openSidebarToggle={isMdOrAbove ? true : openSidebarToggle} OpenSidebar={OpenSidebar} />
                    <div className="main-content">
                        <Header OpenSidebar={OpenSidebar} isMdOrAbove={isMdOrAbove} />
                        {/* <Home /> */}
                        <main className='main-container container-fluid card-container me-2'>
                            { children }
                        </main>
                        
                    </div>
            </div>
     </AuthenticatedLayout>
    );
}

export default Main;
