export const componentsList = [
    {
      name: "Bartender",
      values: [
        { Bartender: "/dashboard-page/bartender-section" },
        { "Bartender Details": "/dashboard-page/bartender-section/bartender-details" },
        { "Bartender Ticket": "/dashboard-page/bartender-section/ticket-management" },
        { "Bartender Reports": "/dashboard-page/bartender-section/report-item" },
      ],
    },
    {
        name: "Establishment",
        values: [
          { Establishment: "/dashboard-page/Establishment-section" },
          { "Establishment Details": "/dashboard-page/Establishment-section/establishmnet-details" },
          { "Establishment Ticket": "/dashboard-page/Establishment-section/ticket-management" },
          { "Establishment Reports": "/dashboard-page/Establishment-section/report-item" },
          { "Absent Bartender's":"/dashboard-page/Establishment-section/mark-no-show" },
        ],
      },
      {
        name: "Content Management",
        values: [
          {"Content Management": "/content-management"},
          {FAQ:"/content-management/FAQ"},
          {"About Section":"/content-management/About"},
          {"Terms & Conditions":"/content-management/Terms&Condition"},
          {Privacy:"/content-management/privacyPolicy"},
         
        ],
      },
      
      {
        name: "Payment Management",
        values: [
          { "Payment Management":"/payment-management" },
        ],
      },
      {
        name: "Account Management",
        values: [
          { "Account Management":"/account-management" },
        ],
      },
      {
        name: "Admin Management",
        values: [
          { "Admin Management":"/admin-management" },
        ],
      },
      {
        name: "Charge Management",
        values: [
          { "Charge Management":"/charge-management" },
        ],
      },
      {
        name: "Setting",
        values: [
          { Setting:"/settings" },
          { "PushNotification":"/settings/push-notification" },
        ],
      },
  ];