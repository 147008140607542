import React, { useState } from "react";
import "./AbcRes.css";
import GigsDetailinfo1 from "../GigsDetailinfo1/GigsDetailinfo1";
import GigsDetailinfo2 from "../GigsDetailinfo2/GigsDetailinfo2";
import GigsDetailinfo3 from "../GigsDetailinfo3/GigsDetailinfo3";
import { Link, useLocation, useParams } from "react-router-dom";

const AbcRes = () => {
  const location = useLocation();
  const data = location.state.establishmentData;
  const bartenderId=location?.state?.establishmentData?.assigned[0]?.bartender_id
  
  const usrName = location.state.userName;

  const [selectedContent, setSelectedContent] = useState("detail1");

  const handleButtonClick = (content) => {
    setSelectedContent(content);
  };

  const aboutGig = {
    title: data?.jobTitle,
    gig: data?.jobDiscription,
    time: `${data?.shiftSTime} - ${data?.shiftETime}`,
    pay: `$${data?.payHourly}/hour`,
    skills: data?.skillsRequired,
    yearofexp: data.yearsOfExperience,
    dueResp: data?.dutiesRes,
  };
  const aboutEstab = {
    aboutEstab: data?.EstablishemtDetails?.discription,
    service: data?.EstablishemtDetails?.serviceType,
    dressCode: data?.EstablishemtDetails?.dressCode,
    posSoftware: data?.EstablishemtDetails?.posSystem,
    custormFlow: data?.EstablishemtDetails?.customerFlow,
    businnesYer: data?.EstablishemtDetails?.yearsOfExperience,
    webLink: data?.EstablishemtDetails?.websiteLink,
  };

  const benifits = {
    // make it arry in backend
    benifits: data?.benifits,
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="Bartender" to="/dashboard-page/bartender-section" className='All-color-breakcurm'>&nbsp;Bartender</Link>,
    <Link key="BartenderDetails" to="/dashboard-page/bartender-section/bartender-details" className='All-color-breakcurm'>&nbsp;Bartender Details</Link>,
    <Link key="BartenderName" to={`/bartender/bartenderDetails/User/profile/${bartenderId}`}className='All-color-breakcurm'>&nbsp;{usrName ||"User"}</Link>,
    <Link key="BartenderGigsAttended" to={`/bartender/bartenderDetails/User/gigs/${bartenderId}`}className='All-color-breakcurm'>&nbsp;Gigs Attended</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;
   {data?.EstablishemtDetails?.establishmentName}
  </span>
  ];

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 

      <div className="estbname-adress">
        <p>{data?.EstablishemtDetails?.establishmentName}</p>
        {data?.EstablishemtDetails?.location ? (
          <p>
            {data?.EstablishemtDetails.location.streetName}{" "}
            {data.EstablishemtDetails.location.city}{" "}
            {data.EstablishemtDetails.location.state}{" "}
            {data.EstablishemtDetails.location.postalCode}
          </p>
        ) : (
          "Location not available"
        )}
      </div>

      <div className="three-btn">
        <button
          type="button"
          className={`about-gig comndesing-btn ${
            selectedContent === "detail1" ? "selected" : ""
          }`}
          onClick={() => handleButtonClick("detail1")}
        >
          About The Gig
        </button>
        <button
          type="button"
          className={`about-est comndesing-btn ${
            selectedContent === "detail2" ? "selected" : ""
          }`}
          onClick={() => handleButtonClick("detail2")}
        >
          About Establishment
        </button>
        <button
          type="button"
          className={`about-bentfit comndesing-btn ${
            selectedContent === "detail3" ? "selected" : ""
          }`}
          onClick={() => handleButtonClick("detail3")}
        >
          Benefits
        </button>
      </div>

      {selectedContent === "detail1" && <GigsDetailinfo1 data={aboutGig} />}
      {selectedContent === "detail2" && <GigsDetailinfo2 data={aboutEstab} />}
      {selectedContent === "detail3" && <GigsDetailinfo3 data={benifits} />}
    </div>
  );
};

export default AbcRes;
