import React, { useEffect, useRef, useState } from "react";
import "./FAQ.css";
import EditIcon from "../../../components/CommonComp/Assests/akar-icons_edit.svg";
import DeleteIcon from "../../../components/CommonComp/Assests/fluent_delete-24-regular.svg";
import CreateUserBtn from "../../../components/CommonComp/CreateUserBtn/CreateUserBtn";
import Button1 from "../../../components/CommonComp/Button1/Button1";
import TextEditor from "../../../components/CommonComp/TextEditor/TextEditor";
import { addFaq, deletFaqs, editFaq, getFaq } from "../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../components/CommonComp/Loader/Loader";
import { toast } from "react-toastify";
import DOMPurify from 'dompurify';
import { Link } from "react-router-dom";

const FAQ = () => {
  const token = useSelector((state) => state.user.user.token);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingFAQ, setEditingFAQ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const modalRef = useRef();
  const editModalRef = useRef(null);
  

  const [editFq, setEditFq] = useState({
    _id: "",
    Question: "",
    Answer: "",
  });
  
  const [faq, setFaq] = useState([
    {
      Question: "",
      Answer: "",
    },
  ]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCheck(!check);
    setEditingFAQ(false);
  };

  const handleOpenEditModal = (faq) => {
    setEditFq(faq);
    
    setShowEditModal(true);
    setEditingFAQ(true);
    setIsEdit(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingFAQ(false);
    setIsEdit(false);
  };

  const divstyle = {
    backgroundColor: "#EFEFEF",
  };

  const fetchFaq = async () => {
    try {
      const response = await getFaq({ token });
      if (response.FAQ.length > 0) {
        setFaq(response.FAQ);
        setLoading(false);
      
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFaq();
    return () => {
      setFaq({
        Question: "",
        Answer: "",
      });
    };
  }, [check]);

  const addFaqHandler = async (updatedQuestion, updatedAnswer) => {
    try {
      if (!updatedQuestion || !updatedAnswer) {
        toast.error("Please fill in both the question and answer fields.");
        return;
      }
  
      const faq = {
        question: updatedQuestion,
        answer: updatedAnswer,
      };
      const response = await addFaq({ faq: faq, token: token });
      
      setFaq(response.FAQ);
      handleCloseModal();
      fetchFaq();
      toast.success("New FAQ added");
    } catch (error) {
      toast.error("Some error occurred");
      console.log("Error adding FAQ:", error);
    }
  };

  const updteFaq = async (updatedQuestion, updatedAnswer) => {
    try {
      const response = await editFaq({
        _id: editFq._id,
        Question: updatedQuestion,
        Answer: updatedAnswer,
        token: token,
      });

      // setFaq(response.FAQ);
      toast.success("FAQ updated successfully");
      handleCloseEditModal();
      fetchFaq()
    } catch (error) {
      toast.error("Some error occurred");
      console.log("Error updating FAQ:", error);
    }
  };

  const updateValueFromTextEditor = (updatedQuestion, updatedAnswer) => {
    const data = {
      _id: editFq._id,
      Question: updatedQuestion,
      Answer: updatedAnswer,
    };
    setEditFq(data);
    updteFaq();
    // call update api func
  };

  const deletFaq = async (id) => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete this FAQ?");
      if (!isConfirmed) {
       
        return;
      }
      const response = await deletFaqs({ id: id, token: token });
      toast.success("FAQ deleted successfully");
   
      fetchFaq();
    } catch (error) {
      console.log("Error deleting FAQ:", error);
    }
  };


  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       handleCloseModal();
  //     }
  //   };

  //   if (showModal) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [showModal, handleCloseModal]);
  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
    if (editModalRef.current && !editModalRef.current.contains(event.target)) {
      handleCloseEditModal();
    }
  };

  useEffect(() => {
    if (showModal || showEditModal) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = 'hidden'; // Prevent scrolling on background
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = 'auto'; // Allow scrolling on background
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = 'auto'; // Reset scrolling on unmount or modal close
    };
  }, [showModal, showEditModal]);

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="contentFaq" to="/content-management" className='All-color-breakcurm'>&nbsp;Content Management</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;FAQ</span>
  ];
  
  return (
    <>
       <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
        <div className="me-5">
          <CreateUserBtn
            onClick={handleOpenModal}
            data="Create New FAQ"
          />
        </div>
      </div>

      {loading ? (
        <Loader /> 
      ) : (
        !loading && faq.length > 0 ? (
          faq.map((fq, index) => {
            return (
              <div className="faq-bttn-wraper me-5" key={index}>
                <div className="faq-edit-dlt">
                  <button
                    className="faq-edit-btn"
                    onClick={() => handleOpenEditModal(fq)}
                  >
                    <img src={EditIcon} alt="Edit" /> Edit
                  </button>
                  <button
                    className="faq-delet-btn"
                    style={{ color: "#F33" }}
                    onClick={() => deletFaq(fq._id)}
                  >
                    <img src={DeleteIcon} alt="Delete" /> Delete
                  </button>
                </div>
                <div className="faq-container mb-3">
                  <div className="faq-first-question">
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fq.Question) }} />
                  </div>
                  <div className="faq-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fq.Answer) }} />
                </div>
              </div>
            );
          })
        ) : (
          <div>No FAQs available</div>
        )
      )}

     {!isEdit && (
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered" ref={modalRef}>
            <div className="modal-content modl-design modl-width-faq">
              <div className="modal-header modl-header-design">
                <h3 className="modal-title">Create New FAQ</h3>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <TextEditor
                  divStyle={divstyle}
                  question=''
                  answer=''
                  onCreateFaq={addFaqHandler}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isEdit && (
        <div
          className={`modal fade ${showEditModal ? "show" : ""}`}
          style={{ display: showEditModal ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered" ref={editModalRef}>
            <div className="modal-content modl-design  modl-width-faq">
              <div className="modal-header modl-header-design">
                <h3 className="modal-title">Edit FAQ</h3>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseEditModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <TextEditor
                  divStyle={{ backgroundColor: "#EFEFEF" }}
                  question={editFq.Question}
                  answer={editFq.Answer}
                  onUpdateFaq={updteFaq}
                  isEdit={isEdit}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`modal-backdrop fade ${showModal || showEditModal ? "show" : ""}`}
        style={{ display: showModal || showEditModal ? "block" : "none", backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      ></div>
    </>
  );
};

export default FAQ;
