import React, { useState } from "react";
import "./Pagination.css";

const Pagination = ({ total_page, prev, next, activePage, setActivePage }) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const maxVisibleButtons = 8; // Maximum number of visible buttons

  const debounceSetActivePage = (page) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setActivePage(page);
      setDebounceTimeout(null);
    }, 300); // Adjust the debounce delay 

    setDebounceTimeout(timeout);
  };

  const DynamicDivs = ({ count, activePage }) => {
    let startPage = Math.max(1, activePage - Math.floor(maxVisibleButtons / 2));
    let endPage = Math.min(count, startPage + maxVisibleButtons - 1);


    if (endPage - startPage < maxVisibleButtons - 1) {
      startPage = Math.max(1, endPage - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => debounceSetActivePage(i)}
          className={`page-button ${i === activePage ? "active" : ""}`}>
          {i}
        </button>
      );
    }

    return (
      <div className="page-buttons">
        {startPage > 1 && (
          <>
            <button onClick={() => debounceSetActivePage(1)} className="page-button">
              1
            </button>
            {startPage > 2 && <span className="ellipsis">...</span>}
          </>
        )}
        {pages}
        {endPage < count && (
          <>
            {endPage < count - 1 && <span className="ellipsis">...</span>}
            <button onClick={() => debounceSetActivePage(count)} className="page-button">
              {count}
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="pagination-container">
      {prev && activePage > 1 && (
        <button
          className="prev-btn"
          onClick={() => debounceSetActivePage(activePage - 1)}>
          Previous
        </button>
      )}

      <DynamicDivs count={total_page} activePage={activePage} />

      {next && activePage < total_page && (
        <button
          className="next-btn"
          onClick={() => debounceSetActivePage(activePage + 1)}>
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;
