import React, { useEffect, useState } from "react";
import ReportTable from "../../../../components/CommonComp/ReportTable/ReportTable";
import SearchBar1 from "../../../../components/CommonComp/SearchBar1/SearchBar1";
import { useSelector } from "react-redux";
import { estbReport } from "../../../../Api";
import Loader from "../../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../../components/CommonComp/Pagination/Pagination";
import { Link } from "react-router-dom";

const ReportEst = () => {
  const token = useSelector((state) => state.user.user.token);

  const [loading, setLoading] = useState(true);
  const [reportEstbData, setReportEstbData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [myTimeout, setMyTimeOut] = useState();

  const fetchEstbReportData = async () => {
    try {
      const response = await estbReport({
        role: 2,
        token: token,
        searchQuery,
        activePage,
      });
      setReportEstbData(response?.history);
      setActivePage(response?.page);
      setTotalPage(response?.total_page);
      setNext(response?.next);
      setPrev(response?.prev);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching report data:", error);
    }
  };

  useEffect(() => {
    fetchEstbReportData();
  }, [activePage]);

  useEffect(() => {
    clearTimeout(myTimeout);

    setMyTimeOut(setTimeout(fetchEstbReportData, 500));
  }, [searchQuery]);

  const handleSearch = (searchQuery) => {
    if (searchQuery != "" && searchQuery.trim() != "") {
      setSearchQuery(searchQuery);
      setActivePage(1);
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="EstablishmentTicket"
      to="/dashboard-page/Establishment-section"
      className="All-color-breakcurm">
      &nbsp;Establishment
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Reports</span>,
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      <div className="comn-serc" style={{ marginTop: "77px" }}>
        <SearchBar1 data="Search by Name" onSearch={handleSearch} />
      </div>

      <div className="comnt-tb" style={{ marginTop: "67px" }}>
        {loading ? (
          <p>
            <Loader />
          </p>
        ) : (
          <ReportTable filteredData={reportEstbData} />
        )}
        <Pagination
          total_page={totalpage}
          next={next}
          prev={prev}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
};

export default ReportEst;
