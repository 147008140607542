import React from "react";
import Card2 from "../../components/CommonComp/Card2/Card2";
import { Link } from "react-router-dom";

const Content = () => {
  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Content Management</span>,
  ];
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      <div
        className="row card-row content-mang "
        style={{ margin: "32px 211px 0 0", width: "90%" }}>
        <div className="col-lg-3 mb-3 mb-md-0">
          <Link to="/content-management/FAQ" className="nav-link">
            <Card2 content="FAQ" />
          </Link>
        </div>
        <div className="col-lg-3  margin-25">
          <Link to="/content-management/About" className="nav-link">
            <Card2 content="About Section" />
          </Link>
        </div>
        <div className="col-lg-3  margin-25">
          <Link to="/content-management/Terms&Condition" className="nav-link">
            <Card2 content="Terms & Conditions" />
          </Link>
        </div>

        <div className="col-lg-3  margin-25">
          <Link to="/content-management/privacyPolicy" className="nav-link">
            <Card2 content="Privacy Policy" />
          </Link>
        </div>
       
        <div className="col-lg-3  margin-25">
          <Link to="/content-management/reprotcontent" className="nav-link">
            <Card2 content="Report Content" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Content;
