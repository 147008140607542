import React, { useEffect, useState } from 'react';
import './EarningsPage.css';
import Card6 from '../../../../../components/CommonComp/Card6ndTable/Card6';
import { Link, useParams } from 'react-router-dom';
import { totlEarnings } from '../../../../../Api';
import { useSelector } from 'react-redux';
import Loader from '../../../../../components/CommonComp/Loader/Loader';
import Pagination from '../../../../../components/CommonComp/Pagination/Pagination';

const EarningsPage = () => {
  const token = useSelector((state) => state.user.user.token);
  const { id } = useParams();
  
  const [earnData, setEarnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalEarnings,setTotalEarning]=useState();
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage]=useState(1);
  const [srno,setSrNo]=useState([]);
  
  const [userName,setUserName]=useState('')


  const result = async () => {
    try {
      const earningResponse = await totlEarnings({ uid: id, token: token,activePage});
      setUserName(earningResponse.UserName);
      
      setEarnData(earningResponse.history); 
      setTotalEarning(earningResponse?.TotalEarnings)
      setActivePage(earningResponse.page)
      setTotalPage(earningResponse.total_page)
      setNext(earningResponse.next);
      setPrev(earningResponse.prev);

      const updatedSrNo = Array.from({ length: earningResponse.history.length }, (_, index) => (activePage - 1) * 12 + index + 1);
      setSrNo(updatedSrNo);

      setLoading(false);
      
    } catch (err) {
      setLoading(false);
    }
  };
 


  useEffect(() => {
    result();
  }, [activePage]);

  
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };


  const getStatus = (status) => {
    switch (status) {
      case 'initialized':
        return 'Pending';
      case 'processed':
        return 'Processed';
      case 'completed':
        return 'Paid';
      default:
        return 'Not avilable';
    }
  };

  const tableData = {
    headers: ['ID', 'Establishment Name', 'Address', 'Shift Time', 'Amount', 'Status', 'Date'],

    rows: earnData.map((earning, index) => 
      
        // const location=JSON.parse(earning?.eid?.location)
   [
      `${srno[index]}`,
      earning?.eid?.establishmentName ? earning?.eid?.establishmentName : "Name not available",
      // location ? `${location?.streetName}, ${location?.city}, ${location?.state}, ${location?.postalCode}` : 'Location Not available',
      earning?.eid?.location ? `${earning?.eid?.location?.streetName}, ${earning?.eid?.location?.city}, ${earning?.eid?.location?.state}, ${earning?.eid?.location?.postalCode}` : 'Location Not available',
      earning?.shiftTime ? `${earning.shiftTime} (${parseFloat(earning?.duration || 0).toFixed(0)} hr)` : 'Shift time not available',
      // `$${earning?.amount}`,
      earning?.amount ? `$${parseFloat(earning.amount).toFixed(2)}` : "$0.00",
      getStatus(earning?.status),
      (earning?.paymentDate || "Date not available")
    ])
  };
  
 
  
  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="Bartender" to="/dashboard-page/bartender-section" className='All-color-breakcurm'>&nbsp;Bartender</Link>,
    <Link key="BartenderDetails" to="/dashboard-page/bartender-section/bartender-details" className='All-color-breakcurm'>&nbsp;Bartender Details</Link>,
    <Link key="BartenderEarnings" to={`/bartender/bartenderDetails/User/profile/${id}`}className='All-color-breakcurm'>&nbsp;{userName ||"User"}</Link>,
   <span style={{fontWeight:'bolder'}}>&nbsp;
    Earnings
  </span>
  ];


  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 

      <div className='erning-box'>
        {loading ? (
          <Loader />
        ) : (
          <>
       <Card6 
            data={`$${totalEarnings ? parseFloat(totalEarnings).toFixed(2) : "0.00"}`} 
            data1="Total Earnings" 
            tableData={tableData} 
          />


          <Pagination  total_page={totalpage} next={next} prev={prev} activePage={activePage} setActivePage={setActivePage} />
          </>
        )}
      </div>
    </div>
  );
}

export default EarningsPage;
