import React, { useState, useEffect, useRef } from "react";
import "./ComnTick.css";
import { useSelector } from "react-redux";
import {
  HandleTicketMangEstb,
  resolveTicket,
  ticketGetMsg,
  ticketSendMsg,
} from "../../../Api";
import { toast } from "react-toastify";
import sendLogo from "../Assests/send.png";
import Loader from "../Loader/Loader";
import NavigationIcon from "@mui/icons-material/Navigation";

const ComnTick = ({role}) => {

  const token = useSelector((state) => state.user.user.token);
  const user = useSelector((state) => state.user.user);
  const [activeTab, setActiveTab] = useState(0);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [chnge, setChnge] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  
  
  const [ticketData, setTicketData] = useState({
    next: false,
    page: "",
    prev: false,
    resolved: 0,
    search: "",
    total_page: "",
    unresolved: 0,
    history: [],
  });

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [filter, setfilter] = useState(0);

  const messageInputRef = useRef();

  const fetchTicketData = () => {
    
    HandleTicketMangEstb({
      token,
      activePage,
      filter: filter,
      role:role?role:1
    })
      .then((result) => {
        const { next, prev, resolved, total_page, unresolved, history } =
          result;
        // console.log(resolved,unresolved);
        
        setTicketData(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        console.error("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    fetchTicketData();
  }, [prev, next, activePage]);

  useEffect(() => {
    fetchTicketData();
  }, [filter]);

  const getMsg = async (ticket) => {
    try {
      setLoading(true);
      const response = await ticketGetMsg({ token, chatId: ticket.chatId });

      setChatMessages(response.history);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const sendMsg = async () => {
    const trimmedMessage = messageInput.trim();
    if (trimmedMessage === "") {
      toast.warning("Message cannot be empty");
      return;
    }
    try {
      const response = await ticketSendMsg({
        token,
        chatId: selectedTicket.chatId,
        text: messageInput,
      });

      setChnge(!chnge);
      setMessageInput("");
    } catch (error) {
      console.log("Error sending message", error);
    }
  };

  const handleResolveTicket = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to mark this ticket as resolved?"
      );
      if (!isConfirmed) {
        return;
      }
      const response = await resolveTicket({ token, ticketid: id });

      setSelectedTicket((prev) => ({
        ...prev,
        isopen: false,
      }));
      setChnge(!chnge);
      fetchTicketData();
      setShowModal(false); // Close the modal
      toast.success("Ticket Closed");
    } catch (error) {
      toast.error("Some error occurred while resolving the ticket.");
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (selectedTicket) {
      getMsg(selectedTicket);
    }
  }, [selectedTicket, chnge, forceUpdate]);

  const handleTabClick = (tabId) => {
    setfilter(tabId);
    setActiveTab(tabId);
    setShowOffCanvas(false);
  };

  const handleAllTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setChatMessages([]); // Clear previous chat messages when selecting a new ticket
    setShowOffCanvas(true);
    setForceUpdate(!forceUpdate);
  };

  const renderChatMessages = () => {
    return chatMessages.map((message, index) => (
      <div
        key={index}
        className={`chat-message ${
          message.from === user._id ? "sent" : "received"
        }`}>
        <div className="message-text">{message.text}</div>
      </div>
    ));
  };

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 0 ? "active" : ""}`}
            onClick={() => handleTabClick(0)}
            type="button"
            role="tab"
            aria-selected={activeTab === 0}>
            All Tickets ({ticketData.resolved + ticketData.unresolved || 0})
            {activeTab === 0 && <div className="btm-line"></div>}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
            type="button"
            role="tab"
            aria-selected={activeTab === 1}>
            Unresolved ({ticketData.unresolved || 0})
            {activeTab === 1 && <div className="btm-line"></div>}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClick(2)}
            type="button"
            role="tab"
            aria-selected={activeTab === 2}>
            Resolved ({ticketData.resolved || 0})
            {activeTab === 2 && <div className="btm-line"></div>}
          </button>
        </li>
      </ul>

      <div className="tab-content" id="myTabContent">
        <div>
          {ticketData?.history?.map((ticket) => {
            return (
              <div
                key={ticket._id}
                className="all-ticket-section"
                onClick={() => handleAllTicketClick(ticket)}>
                <div className="wraper1">
                  <div className="ticket-title">{ticket?.title}</div>
                  <div style={{ color: "#707070" }}>{ticket?.RaisedDate}</div>
                </div>
                <div className="ticket-content">{ticket?.description}</div>
                <div
                  className={`ticket-priority ${
                    ticket.isopen ? "unreslv" : "reslved"
                  }`}>
                  <div className="high">
                    <p>
                      Priority:<span style={{ Color: "#465CD9" }}> High</span>
                    </p>
                  </div>
                  <div className="status">
                    <p>
                      Status:{" "}
                      <span
                        className={ticket.isopen ? "in-progress" : "completed"}>
                        {ticket.isopen ? "In Progress" : "Completed"}
                      </span>
                    </p>
                  </div>
                  {ticket.UserData && (
                    <div className="HandleBy">
                      <p>Handled By: {ticket?.UserData?.email}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showOffCanvas && selectedTicket && (
        <div className="off-canvas">
          <div className="off-canvas-content">
            <div className="sd-bar">
              <p>Reply To</p>
              <div
                onClick={() => setShowOffCanvas(false)}
                style={{ cursor: "pointer" }}>
                X
              </div>
            </div>
            <div className="sd-bar-mail">
              {selectedTicket?.UserData?.email || "No data"}
            </div>
            <div className="prioty-status me-3">
              <div className="prorty">
                <p>
                  Priority: <span style={{ color: "#465CD9" }}> High</span>
                </p>
              </div>
              <div className="statusss">
                <p>Status</p>
                <div className="dropdown">
                  <button
                    className="btn w-100 border border-1 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {selectedTicket.isopen ? "In Process" : "Resolved"}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    {selectedTicket.isopen ? (
                      <>
                        <li>
                          <a className="dropdown-item">In Process</a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() =>
                              handleResolveTicket(selectedTicket._id)
                            }>
                            Resolved
                          </a>
                        </li>
                      </>
                    ) : (
                      <li>
                        <a className="dropdown-item" disabled>
                          Resolved
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="lorem-eps-text">{selectedTicket.title}</div>
            <div className="long-txt">{selectedTicket.description}</div>
            <div className="sde-bar-box">
              {loading ? (
                <Loader />
              ) : (
                <div className="chat-messages">{renderChatMessages()}</div>
              )}
            </div>
            {selectedTicket.isopen ? (
              <div className="input-send-btn">
                <input
                  ref={messageInputRef}
                  type="text"
                  className="send-reply"
                  placeholder="Send Reply"
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                />
                <button
                  className="side-bar-btnn mt-4 mb-5"
                  onClick={() => sendMsg()}>
                  {/* <img src={sendLogo} alt='send-logo' /> */}
                  <NavigationIcon className="right-turn-logo" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      )}

      {/* {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to mark this ticket as resolved?</p>
            <button onClick={() => handleResolveTicket(selectedTicket._id)}>Yes</button>
            <button onClick={() => setShowModal(false)}>No</button>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ComnTick;
