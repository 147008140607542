import React, { useEffect, useState } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import Header1 from '../Header1/Header1';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import BartenderLogo from '../Assests/bartender 1.svg';
import EstablishmentIcon from '../Assests/shutterstock_2330168917 1.png';
import MoneyreciveIcon from '../Assests/Shutterstock_2235714181 1.png';
import Card from '../Card/Card';
import { useSelector } from 'react-redux';
import { barGraph } from '../../../Api';


function Home() {

  const token = useSelector((state) => state.user.user.token);
  const [data,setData]=useState([]);
  const [curentDte,setCurrentDte]=useState(new Date().getFullYear())


  const getBarData=async()=>{
      try {
        const response= await barGraph(token);
        setData(response)
        
      } catch (error) {
        console.log("error",error)
      }
  }

  useEffect(()=>{
    getBarData();
  },[])

  return (
    <>
      <Header1 />
      <div className='row card-row me-3 crdtop'>
        <div className='col-lg-4  mb-md-0'>
          <Link to='/dashboard-page/bartender-section' className='nav-link'>
            <Card img={BartenderLogo} content='Bartenders' />
          </Link>
        </div>
        <div className='col-lg-4'>
          <Link to="/dashboard-page/Establishment-section" className='nav-link'>
            <Card img={EstablishmentIcon} content='Establishments' />
          </Link>
        </div>
        <div className='col-lg-4'>
          <Link to="/payment-management" className='nav-link'>
            <Card img={MoneyreciveIcon} content='Payment Management' />
          </Link>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='chrt-elemnt'>
          <div className='chrt-content'>
            <div>Reports & Analytics</div>
            <span>{`No of bartenders and establishments enrolled in ${curentDte}`}</span>
          </div>
        

          <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data}>
          
               {/* <Tooltip/> */}
               <Legend 
                    layout="horizontal" 
                    align="right" 
                    verticalAlign="top" 
                    wrapperStyle={{
                      marginRight: -25,
                      marginTop: -56,
                    }}
                  />
                <Bar dataKey="bartender" fill="#F33" radius={[25, 25, 25,25]}  barSize={25}  name="bartenders" />
                <Bar dataKey="establishments" fill="#B6B6B6" radius={[25, 25,25,25]}  barSize={25} name="establishments" />
                {/* <Bar dataKey="dummy" fill="transparent" /> */}
                <XAxis dataKey="month" />
                
              </BarChart>
              </ResponsiveContainer>
        </div>
        
      </div>
    </>
  );
}

export default Home;
