import React from 'react'
import './PushNotification.css';
import { useSelector } from 'react-redux';
import { sendNow } from '../../../Api';
import { toast } from 'react-toastify';

const SendNowAndLaterOnModal = ({notificationType,setNotificationType,setSent, setActiveId,setChange}) => {

  const handleSendNow=()=>{
    setNotificationType(0)
    setSent(true)
    
    toast.success("Notification sent")
  }

  return (
    <div>
      <div className="both-send-schdulebtn  mb-4"> 
        <button className="custom-button-now" data-bs-dismiss="modal" onClick={()=>handleSendNow()}>Send Now</button>
        <button className="custom-button-now"   onClick={()=>{setNotificationType(1);setActiveId(2)}}>Schedule</button>
      </div>
   </div>
  
  )
}

export default SendNowAndLaterOnModal
