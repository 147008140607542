import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgetPassApi, resendOtpApi, resetPassApi, verifyApi } from "../../Api";
import CodeVerifyPass from "../../Pages/ForgetPass/CodeVerifyAccount";
import CreatePass from "../../Pages/ForgetPass/CreatePass";
import LockIcon from '../../components/CommonComp/Assests/fluent_person-32-filled.svg';
import BaroomLogo from '../../components/CommonComp/Assests/Barooom 1.png';
import '../OtpPage/Verify.css'; 
import { toast } from "react-toastify";

export default function ForgetPass(props) {
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [steps, setSteps] = useState(0);
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [user, setUser] = useState({ email: "" });
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setError(null);
  };

  const handleSendOTP = async () => {
    if (!user.email.trim()) {
      setError("Please enter your email address or phone number.");
      toast.warning("Fill your email or phone number")
      return;

    }
  
    try {
    
      const response = await forgetPassApi({ email: user.email });
     
      setId(response.data.id);
      setSteps(1);
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      setError(error.message);
      toast.error(error.message);
    }
  };
  
  const verifyOtp = async (otp) => {
    try {
     
      let type=1;
      if(otp){
        const response = await verifyApi({ refId: id, otp: otp, type: type });
       
        setToken(response.data.token);
        setSteps(2);
        toast.success("OTP verified successfully");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error.message);
      setError(error.message);
      toast.error(error.message);
    }
  };
  
  const resetPassword = async (password, confirmPassword) => {
    try {
     
      let type=1;
      await resetPassApi({ newPassword: password, cPassword: confirmPassword, token: token ,type:type});
      
      setTimeout(() => navigate("/"), 1000);
      toast.success("Password reset successfully");
    } catch (error) {
      console.error("Error resetting password:", error.message);
      setError(error.message);
      toast.error(error.message);
    }
  };
  
  const newOtp = async () => {
    try {
     
      await resendOtpApi({ refId: id });
      // setSuccess("Otp resent successfully");
      // setError("");
      toast.success("OTP resent successfully");
    } catch (error) {
      console.error("Error resending OTP:", error.message);
      // setError(error.message);
      // setSuccess("");
      toast.error("Failed to resend OTP. Please try again.");
    }
  };
  
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 forgetps">
      {steps === 0 && (
        <div className="d-flex flex-column align-items-center bg-white border border-5 border-warning rounded-3 p-2 custom-container1">
          <img src={BaroomLogo} alt="Baroom-Logo" className="mb-4 custom-logo1" />
          <div className="font-weight-bold forget-your-pass">Forgot Your Password?</div>
          <div className="mt-2 text-center text-muted">
            Don’t worry! Enter the email address or phone number and we’ll send an email with instructions with OTP to reset your password.
          </div>
          <div className="d-flex gap-3 mt-5 align-items-center w-100 bg-white border border-gray-200 rounded-pill px-4 py-2 custom-input-container">
            <img loading="lazy" src={LockIcon} className="my-auto w-6" />
            <div className="flex-grow-1">
              <div className="text-dark phone-email-text pt-1">Enter Email ID or Phone Number</div>
              <div className="text-muted phone-email-text pt-1">
                <input
                  className="border-0 outline-none w-100 custom-input"
                  type="text"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  placeholder="Your email address or phone number"
                  aria-label="Email address or phone number"
                />
              </div>
            </div>
          </div>
          {/* {error && <div className="text-danger mt-2">{error}</div>} */}
          <button
            className="sendotpforget w-100 mt-5 mb-3"
            onClick={handleSendOTP}
            style={{height:'50px'}}
          >
            Send OTP
          </button>
        </div>
      )}
      {steps === 1 && <CodeVerifyPass verifyOtp={verifyOtp} error={error} setError={setError} newOtp={newOtp} success={success} type={1} />}
      {steps === 2 && <CreatePass resetPassword={resetPassword} />}
    </div>
  );
}
