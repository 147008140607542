import React from "react";
import SearchBar2 from "../../../../components/CommonComp/SearchBar2/SearchBar2";
import "./Suscription.css";
import Button2 from "../../../../components/CommonComp/Button2/Button2";
import Button3 from "../../../../components/CommonComp/Button3/Button3";
import ReportTable from "../../../../components/CommonComp/ReportTable/ReportTable";
import ElipsIcon from "../../../../components/CommonComp/Assests/Ellipse 63.svg";
import { useNavigate } from 'react-router-dom';
// import AddSusciption from "./Addsucription/AddSusciption";
const Suscription = () => {

  const navigate = useNavigate();

 const handleNavigate=()=>{
  navigate('/dashboard/Establishment/SubscriptionManagement/ManageYourPlans')
 }

  const tableData = {
    headers: ["Name", "Date of Subscription", "Subscription Plan", "Amount"],
    rows: [
      [
        <div
          style={{
            display: "flex",
            alignItems: "end",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <img src={ElipsIcon} alt="John Doe" style={{ marginRight: "10px" }} />
          <div>
            <b>John Doe</b>
            <p>John@ankemail.com</p>
          </div>
        </div>,
        "Jan 01, 2022",
        "Gold",
        "$50.00/mo",
        "download invoice",
      ],
      [
        <div
          style={{
            display: "flex",
            alignItems: "end",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <img src={ElipsIcon} alt="John Doe" style={{ marginRight: "10px" }} />
          <div>
            <b>John Doe</b>
            <p>John@ankemail.com</p>
          </div>
        </div>,
        "Jan 01, 2022",
        "Gold",
        "$50.00/mo",
        "download invoice",
      ],
    ],
  };
  return (
    <div>
      <div className="algn-top-bar">
        <div className="brd-crum">
          Home &gt; Establishment &gt; <b>Subscriptions</b>
        </div>
      </div>

      <div className="suscription-container">
        <div className="suscription">
          <div className="boxes">
            <p className="boxes-text1">Total Subscriptions</p>
            <p className="boxes-text2">2345</p>
          </div>
          <div className="boxes">
            <p className="boxes-text1">Revenue Generated</p>
            <p className="boxes-text2">$67890</p>
          </div>
        </div>
        <div className="butn-waper-suscription">
          <button type="submit" className="suscription-more-btn" onClick={handleNavigate}>Manage Your Plans</button>
        </div>
      </div>
      <div className="suscription-serch-container">
        <SearchBar2 placeholder="Search Establishment"/>
      </div>

      <div className="suscription-report-tbl">
        <ReportTable tableData={tableData} />
      </div>
    </div>
 
  );
};

export default Suscription;
