import React, { useState, useEffect } from "react";
import "./SearchBar2.css";
import SearchIcon from "../../CommonComp/Assests/charm_search.svg";
import MultiIcon from "../../CommonComp/Assests/mi_filter.svg";
import { toast } from "react-toastify";
import { ensureString } from "../../../utils/inputFormatter";

const SearchBar2 = ({ placeholder = "Search by Name", onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [accountStatus, setAccountStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleInputChange = (value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleFilterChnge = (e) => {
    setAccountStatus(e.target.value);
    onSearch(searchQuery, e.target.value);
    setShowDropdown(false);
  };

  const handleSearch = () => {
    onSearch(searchQuery, accountStatus, startDate, endDate);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    onSearch(searchQuery, accountStatus, e.target.value, endDate);
    setShowDropdown(false);
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && selectedEndDate < startDate) {
      toast.error("End date must be greater than start date.");
      return;
    }
    setEndDate(selectedEndDate);
    onSearch(searchQuery, accountStatus, startDate, selectedEndDate);
    setShowDropdown(false);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".custom-dropdown") &&
      !event.target.closest(".multi-select-con")
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    handleSearch();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="col-md-6 col-12 mb-5 barom-app-serch position-relative">
      <div className="barom-serch ps-3 d-flex align-items-center">
        <div className="accnt-mangmnet-icons">
          <img src={SearchIcon} alt="Search Icon" className="same-icon-size" />
        </div>
        <input
          type="search"
          placeholder={placeholder}
          className="barom-serch-item"
          value={searchQuery}
          onChange={(e) => {
            if (e.target.value.trim() !== "" || e.target.value === "") {
              let value = ensureString({ value: e.target.value });
              handleInputChange(value);
            }
          }}
        />
        <div
          className="accnt-mangmnet-icons me-3 multi-select-con"
          onClick={handleDropdownToggle}>
          <img
            src={MultiIcon}
            alt="Multi Select Icon"
            className="same-icon-size"
          />
        </div>
      </div>
      {showDropdown && (
        <div className="custom-dropdown">
          <div className="dropdown-item p-3 acount-date">
            <div className="mb-3">
              <label htmlFor="selectField" className="account-status">
                Account Status
              </label>
              <select
                id="selectField"
                className="form-select custom-select"
                value={accountStatus}
                onChange={(e) => handleFilterChnge(e)}>
                <option value="">All</option>
                <option value="Active">Active</option>
                <option value="NotActive">Inactive</option>
                <option value="Suspended">Suspended</option>
                <option value="Pending">Pending Verification</option>
                <option value="Deleted">Deleted</option>
              </select>
            </div>
            <div className="mb-3 date-range-container">
              <div className="date-field">
                <label htmlFor="startDateField" className="account-status">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDateField"
                  className="form-control custom-date-input start-date"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e)}
                />
              </div>
              <div className="date-field">
                <label htmlFor="endDateField" className="account-status">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDateField"
                  className="form-control end-date"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar2;
