import React, { useEffect, useState } from 'react';
import './UpdateProfile.css';
import Axios from 'axios'; // Import Axios
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import UserIcon from '../../../components/CommonComp/Assests/carbon_user-avatar-filled-alt.svg';
import EmailIcn from '../../../components/CommonComp/Assests/dashicons_email-alt.svg';
import Button from '../../../components/CommonComp/Button/Button';
import ProfileImg from '../../../components/CommonComp/ProfileImg/ProfileImg';
import { fetchUsers, handleUpdateUser,handleDeleteUser } from '../../../Api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const UpdateProfile = () => {

  const token =useSelector((state)=>state.user.user.token);

  const navigate=useNavigate();
  const [user,setUser]=useState({});
  const { userId } = useParams();
  const {state} = useLocation();

  const [loader,setLoader]=useState(true);



const setData=()=>{
  setUser({
    firstName:state?.name.split(" ")[0],
    lastName:state?.name.split(" ")[1],
    email:state?.email,
    role:state?.role,
    uid:state?._id,
    profile:state?.pic
  })
  setLoader(false)
}

  useEffect(()=>{
  setData();
},[])
   
  

const handleSubmit =  async(e) => {
    e.preventDefault(); 
    if(!user.firstName|| !user.lastName){
      toast.error("First or Last Name should not be null")
      return
    }
    try{
     await handleUpdateUser({user,token:token})
     toast.success("Admin Details Updated")
    }
    catch(err){
      console.log("Err",err)
      toast.error(err.response.data.message)
    }
    
  }

  const handleDelete = async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete this Admin?");
      if (!isConfirmed) {
        
        return;
      }
      await handleDeleteUser({ id: user.uid, token: token });
      toast.success('Admin deleted successfully');
      navigate('/admin-management');
      
    } catch (err) {
      toast.error(err.response.data.message);
      console.error('Error deleting user:', err);
    }
  };
 
  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="AdminManagement" to="/admin-management" className='All-color-breakcurm'>&nbsp;Admin Management</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;{user.firstName||""} {user.lastName||""}</span>
  ];
 
if(!loader){
  return (
    
    <>
     
     <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 

      {/* <form onSubmit={handleSubmit}> */}
        <div className="w-100 me-5 update-accunt">
          <div className="row spcing-btween-grd2">
            <div className="col-md-2 text-center mb-3">
              <div className='wrapper4'>

                <ProfileImg user={user} setUser={setUser}/>
                
              </div>
            </div>

            <div className="col-md-9">
              <div className='d-flex justify-content-end'>
              <p className='delet-details mt-4 mt-md-0' onClick={()=>handleDelete()} style={{cursor:'pointer',display:'inline-block'}}>Delete account</p> 
              </div>
              <div className="row mb-1">
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">


                      <img src={UserIcon} alt="user-Icon" className='same-icon-size' />
                      
                    </div>
                    <input 
                      type="text" 
                      placeholder="First name" 
                      className='ps-4 first-name comn-inpt-property' 
                      value={user?.firstName} 
                      onChange={(e) => {
                        if (e.target.value.trim() !== "" || e.target.value === "") {
                        setUser({...user,firstName:e.target.value})}
                      }
                       }
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img src={UserIcon} alt="user-Icon" className='same-icon-size'/>
                    </div>
                    <input 
                      type="text" 
                      placeholder="Last name" 
                      className='ps-4 last-name comn-inpt-property' 
                      value={user?.lastName} 
                      onChange={(e) => {
                        if (e.target.value.trim() !== "" || e.target.value === "") {
                        setUser({...user,lastName:e.target.value})} 
                      }
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img src={EmailIcn} alt="Email-Icon" className='same-icon-size' />
                    </div>
                    <input 
                      type="email" 
                      disabled
                      placeholder="Email ID" 
                      className='ps-4 email-id comn-inpt-property'
                      value={user?.email} 
                      onChange={(e) => setUser({...user,email:e.target.value})}  
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                    <img src={UserIcon} alt="user-Icon" className='same-icon-size'/>
                    </div>
                    <input 
                      type="text" 
                      disabled
                      placeholder="Admin" 
                      className='ps-4 phone-no comn-inpt-property' 
                      value={user?.role===0?"Admin":""} 
                      onChange={(e) => setUser({...user,role:e.target.value})} 
                    />
                  </div>
                </div>
              </div>

                <div className='butn-adjust'>
                <Button title="Update Account" onClick={(e)=>handleSubmit(e)} type="submit"/>
              </div>
              </div>

             
            </div>
          </div>
        {/* </form> */}
    </>
  );
}
};

export default UpdateProfile;
