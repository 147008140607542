import React from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import "./UserPage.css";
import {
  ensureEmail,
  ensureNumber,
  ensureString,
} from "../../../utils/inputFormatter";

const UserPage = ({ formData, setFormData, handleSubmit }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;

    // value = ensureString({ value });

    if (name === "phoneNo") {
      value = ensureNumber({ value, maxLength: 10 });
    } else if (name === "zipCode") {
      value = ensureNumber({ value });
    } else if (name === "name") {
      value = ensureString({ value });
    } else if (name === "email") {
      value = ensureEmail({ value });
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dob: date ? date.format("DD-MM-YYYY") : "" });
  };

  return (
    <div>
      <div>
        <div>
          <div className="right-section">
            <input
              type="text"
              placeholder="Name"
              name="name"
              id="name"
              className="input-field"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              className="input-field"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Phone Number"
              name="phoneNo"
              className="input-field"
              value={formData.phoneNo}
              onChange={handleChange}
              pattern="[0-9]*"
            />
            <input
              type="text"
              placeholder="Based From Which City?"
              name="city"
              className="input-field"
              value={formData.city}
              onChange={handleChange}
            />

            {/* new added */}
            <input
              type="text"
              placeholder="Based From Which  State?"
              name="state"
              className="input-field"
              value={formData.state}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Zip Code"
              name="zipCode"
              className="input-field"
              value={formData.zipCode}
              onChange={handleChange}
              pattern="[0-9]*"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // value={formData.dob}
                onChange={handleDateChange}
                format="DD-MM-YYYY"
                label="Birthdate"
                className="dtes"
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "10px",
                  },
                }}
              />
            </LocalizationProvider>

            {/* <input
                type="date"
                placeholder="Birthdate,  DD-MM-YYYY"
                name="dob"
                className="input-field"
                value={formData.dob}
                onChange={handleChange}
                pattern="\d{1,2}-\d{1,2}-\d{4}"
              /> */}
            <button
              type="submit"
              className="crte-usr-btun mb-5"
              onClick={() => handleSubmit()}>
              Create New User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
