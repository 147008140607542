import React, { useEffect, useState, useRef } from 'react';
import './AdminMngement.css';
import axios from 'axios';
import UserIcon from '../../components/CommonComp/Assests/carbon_user-avatar-filled-alt.svg';
import CirclPlus from '../../components/CommonComp/Assests/ant-design_plus-circle-outlined.svg';
import EmailIcon from '../../components/CommonComp/Assests/dashicons_email-alt.svg';
import UserAvtar from '../../components/CommonComp/Assests/carbon_user-avatar.svg';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { fetchUsers, handleCreateUser, handleDeleteUser } from '../../Api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/CommonComp/Loader/Loader';
import CreateUserBtn from '../../components/CommonComp/CreateUserBtn/CreateUserBtn';
import Pagination from '../../components/CommonComp/Pagination/Pagination';

const AdminMngemnt = () => {
  const token = useSelector((state) => state.user.user.token);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [change, setChange] = useState(false);

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  const fetchAdmin = async () => {
    try {
      const response = await fetchUsers({ token: token, setError: setError, activePage });
      setUsers(response.history);
      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, [activePage, change]);

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

 

  const handleCreateUserLocal = async () => {
    if (!firstName || !lastName || !email) {
      toast.warning('Please fill  all the fields');
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please provide a valid email address.");
      return;
    }
    const newUser = { firstName, lastName, email, role };
    try {
      await handleCreateUser(newUser, setFirstName, setLastName, setEmail, setRole, setUsers, token);
      toast.success("New Admin added");
      closeModal();
    } catch (err) {
      // if (err && err.message === "User Already registered") {
        toast.error(err.message);
      // } else {
        // toast.error("Some error occurred");
      // }
      console.error('err', err);
    }
  };

  const handleDeleteUserClick = async (userId) => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete this Admin?");
      if (!isConfirmed) {
        return;
      }
      await handleDeleteUser({ id: userId, token: token });
      setChange(!change);
      toast.success('Admin deleted successfully');
    } catch (err) {
      toast.error("Some error occurred");
      console.error('Error deleting user:', err);
    }
  };

  const handleUpdate = (user) => {
    navigate(`/admin-management/update-profile`, { state: user });
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'hidden'; // Prevent scrolling on background
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'auto'; // Allow scrolling on background
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'auto'; // Reset scrolling on unmount or modal close
    };
  }, [isModalOpen]);


  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;Admin Management</span>
  ];


  return (
    <>
      <div className="Account-managment-desgin me-5">
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 
        <div className="modl-deisgn">
          <button type="button" className="btn create-user-buttom" onClick={openModal}>
            <img src={CirclPlus} alt="plus sign" className="plus-icon" />&nbsp;Create New Admin
          </button>

          <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
            <div className="modal-dialog modal-lg modal-dialog-centered" ref={modalRef}>
              <div className="modal-content">
                <div className="modal-header hed-desgin-modl">
                  <h2 className="modal-title new-admin-txt" id="staticBackdropLabel">Create New Admin</h2>
                  <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col-md-6 mb-3">
                      <div className="input-container1 ps-3 d-flex align-items-center overflow-auto">
                        <div className="accnt-mangmnet-icons">
                          <img src={UserIcon} alt="user-Icon" className="same-icon-size" />
                        </div>
                        <input type="text" placeholder="First name" className="ps-4 additional-field comn-inpt-property" value={firstName} onChange={(e) => {
                            if (e.target.value.trim() !== "" || e.target.value === "") {
                          setFirstName(e.target.value)}
                            }
                        }
                         required />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="input-container1 ps-3 d-flex align-items-center overflow-auto">
                        <div className="accnt-mangmnet-icons">
                          <img src={UserIcon} alt="user-Icon" className="same-icon-size" />
                        </div>
                        <input type="text" placeholder="Last name" className="ps-4 additional-field comn-inpt-property" value={lastName} onChange={(e) => 
                        {
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                        setLastName(e.target.value)}
                          }
                        }
                         required />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="input-container1 ps-3 d-flex align-items-center overflow-auto">
                        <div className="accnt-mangmnet-icons">
                          <img src={EmailIcon} alt="user-Icon" className="same-icon-size" />
                        </div>
                        <input type="email" placeholder="Email ID" className="ps-4 additional-field comn-inpt-property" value={email} onChange={(e) => setEmail(e.target.value)} 
                        required />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="input-container1 ps-3 d-flex align-items-center overflow-auto">
                        <div className="accnt-mangmnet-icons">
                          <img src={UserIcon} alt="user-Icon" className="same-icon-size" />
                        </div>
                        <input type="text" placeholder="Role Type: Admin" className="ps-4 additional-field comn-inpt-property" value={role} onChange={(e) => setRole(e.target.value)} disabled required />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5 d-flex justify-content-center">
                  <button type="button" className="btn create-usr-butn" onClick={handleCreateUserLocal}>Create New Account</button>
                </div>
              </div>
            </div>
          </div>

          <div className={`modal-backdrop ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }}></div>
        </div>
      </div>

      <div className="container dynmic-crd-design">
        <div className="row adminmanagmnet-cardss">
          {loading ? (
            <div><Loader /></div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            users?.map((user) => (
              <div className={`mb-4 user-design-crd`} key={user?._id}>
                <div className="card-body d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center" onClick={() => handleUpdate(user)}>
                    <img src={user?.pic ? `https://d28qcxi1juf339.cloudfront.net/${user?.pic}` : UserAvtar} alt="User-Image" className="admn-logos" style={{ borderRadius: '50%' }} />
                    <div className="user-info col ms-3">
                      <p className="card-text mb-1 name-txt">{user?.name.slice(0, 12)}</p>
                      <p className="card-text mb-1 eml-txt">{user?.email.slice(0, 12)+"..."}</p>
                      <p className="card-text mb-1 admn-txt">Role:{user?.role === 0 ? "Admin" : ""}</p>
                    </div>
                  </div>
                  <div className="dropdown mb-5 admin-drop-butn">
                    <button className="btn desing-new-btn ms-4" type="button" id={`dropdownMenuButton-${user?._id}`} data-bs-toggle="dropdown" aria-expanded="false">
                      <b>...</b>
                    </button>
                    <ul className="dropdown-menu  view-delet-admin" aria-labelledby={`dropdownMenuButton-${user?._id}`}>
                      <li><a className="dropdown-item" onClick={() => handleUpdate(user)}>View user account</a></li>
                      <li><a className="dropdown-item view-user-account" onClick={() => handleDeleteUserClick(user?._id)}>Delete user account</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          )}
          <Pagination total_page={totalpage} next={next} prev={prev} activePage={activePage} setActivePage={setActivePage} />
        </div>
      </div>
    </>
  );
};

export default AdminMngemnt;
