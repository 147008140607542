import React from 'react';
import './Button3.css';

const Button3 = ({ butnstyl, width,backgroundColor,color ,text = "Save Changes" ,handleedit,borderStyle,borderColor ,hadnleSubmit}) => {
  const allStyles = { ...butnstyl, width: width,color:color, backgroundColor:backgroundColor, border: borderColor  };


  return (
    <div style={allStyles} onClick={handleedit}>
      {text && <button type='submit' className='sve-chnges' style={allStyles}>{text}</button>}
    </div>
  );
}

export default Button3;
