// Card6.jsx

import React from 'react';
import './Card6.css';
import Pagination from '../Pagination/Pagination';


const Card6 = ({ data, data1, tableData, style, totalPage, next, prev, activePage, setActivePage }) => {
  
  return (
    <div style={style}> 
      <div className='fixed-size-div'>
        <p className='fixed-size-text'>{data}</p>
        <p className='fixed-size-text1'>{data1}</p>
      </div>
      
      <div className="table-container table-responsive"> 
        <table className="custom-table table-borderless tbl-design rounded-corners table-responsive"> 
          <thead style={{height:'59px'}}>
            <tr>
              {tableData.headers.map((header, index) => (
                <th key={index} className="text-center">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.length === 0 ? (
              <tr>
                <td colSpan={tableData.headers.length} className="text-center">No data available at this moment</td>
              </tr>
            ) : (
              tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={
                        cellIndex === 5 ? cell === 'Paid'  ? 'status-paid' : cell === 'Processed' ? 'status-processed'  : cell === 'Pending'    ? 'status-initialized'  : '' : ''
                      }
                    >
                      {cell ? cell : 'No data'}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      
      <Pagination
        total_page={totalPage} 
        next={next} 
        prev={prev} 
        activePage={activePage} 
        setActivePage={setActivePage} 
      />
    </div>
  );
}

export default Card6;
