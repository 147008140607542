import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./TextEditor.css";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Button1 from "../Button1/Button1";

const TextEditor = ({
  inputFieldVisible = true,
  divStyle,
  question,
  answer,
  onUpdateFaq,
  isEdit,
  onCreateFaq,
}) => {
  const [editedQuestion, setEditedQuestion] = useState(question);
  const [editedAnswer, setEditedAnswer] = useState(answer);

  // useEffect(() => {
  //     setEditedQuestion(question);
  // }, [question]);

  // useEffect(() => {
  //     setEditedAnswer(answer);
  // }, [answer]);

  const handleQuestionChange = (e) => {
    setEditedQuestion(e.target.value);
  };

  const handleAnswerChange = (content) => {
    setEditedAnswer(content);
  };

  const handleUpdateFaq = () => {
    onUpdateFaq(editedQuestion, editedAnswer);
  };
  const handleCreateFaq = () => {
    onCreateFaq(editedQuestion, editedAnswer);
    setEditedQuestion("");
    setEditedAnswer("");
  };
  // const cleanHtml = (html) => {
  //     return DOMPurify.sanitize(html);
  //   };

  return (
    <div>
      {inputFieldVisible && (
        <div className="input-field-txt">
          <input
            type="text"
            className="question"
            placeholder="Enter your Question"
            value={editedQuestion}
            onChange={handleQuestionChange}
          />
        </div>
      )}
      <div id="unique-editor" className="txt-editor">
        {/* <ReactQuill
                    theme="snow"
                    modules={{
                        toolbar: [
                            [{ size: ["small", false, "large", "huge"] }],
                            ["bold", "italic", "underline", "strike", "blockquote"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link", "image"],
                            [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                                { align: [] }
                            ],
                            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
                            [{ "font": [] }],
                        ]
                    }}
                    formats={[
                        "header", "height", "bold", "italic",
                        "underline", "strike", "blockquote",
                        "list", "color", "bullet", "indent",
                        "link", "image", "align", "size",
                    ]}
                    placeholder="write your content ...."
                    value={editedAnswer}
                    onChange={handleAnswerChange}
                    className='req-ss'
                    style={divStyle}
                /> */}
        <textarea
          type="text"
            rows={5}
          placeholder="write your content ...."
          value={editedAnswer}
          className="answer"
          onChange={(e) => handleAnswerChange(e.target.value)}
          style={divStyle}
        />
        <div className="foter-btn mb-5">
          {isEdit ? (
            <Button1 data="Update FAQ" onClick={handleUpdateFaq} />
          ) : (
            <Button1 data="Add New FAQ" onClick={handleCreateFaq} />
          )}
        </div>
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  inputFieldVisible: PropTypes.bool,
  divStyle: PropTypes.object,
  question: PropTypes.string,
  answer: PropTypes.string,
  onUpdateFaq: PropTypes.func,
  isEdit: PropTypes.bool,
  onCreateFaq: PropTypes.func,
};

export default TextEditor;
