import React, { useEffect, useState } from "react";
import "./TicketMang.css";
import ComnTick from "../../../../components/CommonComp/ComnTick/ComnTick";
import { HandleTicketMang } from "../../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../../components/CommonComp/Pagination/Pagination";
import { Link } from "react-router-dom";

const TicketMang = () => {
  // const token = useSelector((state) => state.user.user.token);

  // const [ticketData, setTicketData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // const [totalpage, setTotalPage] = useState();
  // const [prev, setPrev] = useState(false);
  // const [next, setNext] = useState(false);
  // const [activePage, setActivePage] = useState(1);

  // const fetchTicketData = async () => {
  //   try {
  //     const data = await HandleTicketMang({ token, activePage });

  //     setTicketData(data);
  //     setActivePage(data.page);
  //     setTotalPage(data.total_page);
  //     setNext(data.next);
  //     setPrev(data.prev);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(true);
  //     console.error("Error fetching user data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTicketData();
  // }, [prev, next, activePage]);
  const role=1;

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="Bartender"
      to="/dashboard-page/bartender-section"
      className="All-color-breakcurm">
      &nbsp;Bartender
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp; Ticket Management</span>,
  ];
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      {/* {loading ? (
        <p>
          <Loader />
        </p>
      ) : ( */}
        <>
          <ComnTick  role={role}/>
          {/* {console.log(ticketData.history)}
          {ticketData.history.length > 0 && (
            <Pagination
              total_page={totalpage}
              next={next}
              prev={prev}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          )} */}
        </>
      {/* )} */}
    </div>
  );
};

export default TicketMang;
