import React from 'react'
import './Card5.css';
import { Link } from 'react-router-dom';

const Card5 = ({ onClick, content, content1, to, style, content2 }) => {
  return (
    <div>
      {to ? (
        <Link to={to} style={{ cursor: 'pointer', textDecoration: 'none' }}>
          <div className="card-style1" style={style}>
            <div className="d-flex align-items-baseline">
              <p className='card-style-text1' style={{ color: "#F33" }}>{content}</p>
              {content2 && (
                <p className='card-style-text1 text-muted' style={{ fontSize: "18px" }}>
                  <sub>{content2}</sub>
                </p>
              )}
            </div>
            <p className='card-style-text2'>{content1}</p>
          </div>
        </Link>
      ) : (
        <div className="card-style1" style={style}>
          <div className="d-flex align-items-baseline">
            <p className='card-style-text1' style={{ color: "#F33" }}>{content}</p>
            {content2 && (
              <p className='card-style-text1 text-muted' style={{ fontSize: "18px" }}>
                <sub>{content2}</sub>
              </p>
            )}
          </div>
          <p className='card-style-text2'>{content1}</p>
        </div>
      )}
    </div>
  );
}

export default Card5;
