import React from 'react';
import './Card.css';

const Card = ({ img, content }) => {
  return (

    <div className="card commoncard">
      <div className="card-body d-flex flex-column align-items-center justify-content-center">
      {img && <img src={img} className="card-img-top crd-icons" alt="Card Image" />}
        <div className="text-center mt-1">{content}</div>
      </div>
    </div>
  );
};

export default Card;
