import React, { useEffect, useState } from "react";
import "./SearchBar1.css";
import SearchIcon from "../Assests/charm_search.svg";
import { ensureString } from "../../../utils/inputFormatter";

const SearchBar1 = ({ data, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleSearch = (searchQuery) => {
    onSearch(searchQuery);
  };

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  return (
    <div>
      <div className="col-md-6 mb-5">
        <div className="comsion ps-3 d-flex align-items-center">
          <div className="accnt-mangmnet-icons">
            <img src={SearchIcon} alt="user-Icon" className="same-icon-size" />
          </div>
          <input
            type="search"
            placeholder={data}
            value={searchQuery}
            className="comsion-serch"
            onChange={(e) => {
              if (e.target.value.trim() !== "" || e.target.value === "") {
                let value = ensureString({ value: e.target.value });

                handleInputChange(value);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar1;
