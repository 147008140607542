import React, { useEffect, useState } from "react";
import "./EstbReview.css";
import Table1 from "../../../../../../components/CommonComp/ReviewTable/Table1";
import Card5 from "../../../../../../components/CommonComp/Card5/Card5";
import { deleteReview, estbReview } from "../../../../../../Api";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../../../../components/CommonComp/Pagination/Pagination";
import { toast } from "react-toastify";

const EstbReview = () => {
  const token = useSelector((state) => state.user.user.token);
  const { id } = useParams();
  
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);

  const [totalpage, setTotalPage] = useState();
  const [estbNames, setEstbNames] = useState('');
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [estab, setEstab] = useState({
    uid: "",
    estbName: "",
    userName:'',
  });

  const fetchReviewData = async () => {
    try {
      const response = await estbReview({ eid: id, token: token, activePage });
      setEstab({ estbName: response.estabName, uid: response.uid, userName:response.userName });
      
      setReviewData(response.history);
      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);

      setLoading(false);
    } catch (error) {
      console.log("Error fetching review data:", error);
      setLoading(false);
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this Review?"
      );
      if (!isConfirmed) {
        return;
      }
      let type = 2;
      const response = await deleteReview({
        rid: id,
        token: token,
        type: type,
      });
      setChange(!change);
      toast.success("Review Deleted Successfully");
     
    } catch (error) {
      toast.warning("Some error occurred");
      console.log("Error deleting review:", error);
    }
  };

  useEffect(() => {
    fetchReviewData();
  }, [change, activePage]);

  const headers = ["Name", "Review", "Rating", "Date", "Action"];

  const cardStyle = {
    width: "300px",
    height: "126px",
    cursor: "default",
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="EstablishmentSection" to="/dashboard-page/Establishment-section" className='All-color-breakcurm'>&nbsp;Establishment</Link>,
    <Link key="EstablishmentDetails" to="/dashboard-page/Establishment-section/establishmnet-details" className='All-color-breakcurm'>&nbsp;Establishment Details</Link>,
    <Link key="EstablishmentProfile" to={`/estb/estbdetails/profile/${estab?.uid}`} className='All-color-breakcurm'>&nbsp;{estab?.userName||"User"}</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;{estab.estbName} Reviews</span>
  ];

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          

      </div>

      <div className="estb-profile-review">
        <Card5
          content={reviewData.length}
          content1="Total Review"
          style={cardStyle}
        />
      </div>

      <div className="revw-by-bartenders">
        Below is the list of reviews posted by Bartenders
      </div>

      <div className="estb-review-table">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Table1
              headers={headers}
              reviewData={reviewData}
              change={change}
              setChange={setChange}
              handleDeleteReview={handleDeleteReview}
            />
            <Pagination
              total_page={totalpage}
              next={next}
              prev={prev}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EstbReview;
