import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { BsJustify } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/userSlice";
import { toast } from "react-toastify";
import NotificationIcon from "../Assests/bell.png";
import bell1 from "../Assests/bell1.png";
import DropDownIcon from "../Assests/bxs_down-arrow.svg";
import ElipsIcon from "../Assests/Ellipse 63.svg";
import logoutIcon from "../Assests/logout.png";
import BellIcon from "../Assests/coloricon.svg";
import BellIcon1 from "../Assests/blackicon.svg";
import SearchIcon from '../Assests/charm_search.svg';
import { handleNotificationApi } from "../../../Api";
import { messaging } from "../../../fireBase";
import { onMessage } from "firebase/messaging";
import { setNotification } from "../../../redux/globalSlice";
import GlobalSearch from "./GlobalSearch";
import { componentsList } from "./ContentList";


function Header({ OpenSidebar, isMdOrAbove }) {
  const token = useSelector((state) => state?.user?.user?.token);
  const notificationState = useSelector(
    (state) => state?.global?.setNotification
  );

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [showNotifications, setShowNotifications] = useState(false);
  const [notifyData, setNotifyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isNotified, setIsNotified] = useState(false);
  const [query,setQuery]=useState('');
 

  const [filteredComponents, setFilteredComponents] = useState(componentsList);
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  
  const containerRef = useRef(null);
  
  // const handleSearch = (searchValue) => {
  //   setQuery(searchValue);
  
   
  //   const escapedSearchValue = searchValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  //   const regex = new RegExp(`\\b${escapedSearchValue}`, 'i'); 
  
  //   const filtered = componentsList.filter((component) =>
  //     regex.test(component.name)
  //   );
  
  //   setFilteredComponents(filtered);
  //   setSearchDropdownOpen(true);
  // };
  

  const handleSearch = (searchValue) => {
    const sanitizedSearchValue = searchValue.replace(/\s+/g, '');

    
    setQuery(sanitizedSearchValue);
    
    
    if (!sanitizedSearchValue.trim()) {
      setFilteredComponents([]);
      setSearchDropdownOpen(false);
      return;
    }
  
    const escapedSearchValue = sanitizedSearchValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regex = new RegExp(`\\b${escapedSearchValue}`, "i");
  
    const filtered = componentsList.map((category) => ({
      name: category.name,
      values: category.values.filter((item) =>
        Object.keys(item).some((key) => regex.test(key))
      ),
    })).filter((category) => category.values.length > 0);
  
    setFilteredComponents(filtered);
    setSearchDropdownOpen(true); // Open dropdown when filtering
  };

  

  const notification = async () => {
    try {
      const response = await handleNotificationApi(token);
      setNotifyData(response.history);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    notification();

  }, [isNotified]);

  const handleLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to logout?");
    if (!isConfirmed) {
      return;
    } else {
      dispatch(logout());
      toast.success("Admin Logout");
      navigate("/");
    }
  };

  useEffect(() => {
    setIsNotified(notificationState);
  }, [notificationState]);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    dispatch(setNotification(false));
  };
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowNotifications(false);
      setSearchDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closeSearchDropdown = () => {
    setSearchDropdownOpen(false);
  };

  return (
    <>
      <header className="header-design">
        <nav className="navbar ps-5">
          {!isMdOrAbove && (
            <BsJustify className="hamburger-icon" onClick={OpenSidebar} />
          )}

          <form className="form-inline">
            <div className="col-sm">
              <div className="d-flex justify-content-start align-item-center">
              <img src={SearchIcon} alt="user-Icon" className='nav-serch-icon' style={{position:'relative',left:'40px',top:'2px',width:"20px"}} />
                
              <input
                className="mr-sm-2 ps-5 serch-bar"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                aria-label="Search"
                onFocus={() => setSearchDropdownOpen(!!query)}  // Open dropdown if there's a query
              />
          </div>
              {searchDropdownOpen &&  query &&  (
                <GlobalSearch filteredComponents={filteredComponents}  onClose={closeSearchDropdown} />
              
              )}
             
            </div>
          </form>
          <div className="d-flex align-items-center right-side-icon-text">
           
            <div className="dropdown">
              {isNotified ? (
                <img
                  src={NotificationIcon}
                  className="dropdown-toggle me-2 me-sm-5"
                  type="button"
                  id="notifi"
                  onClick={toggleNotifications}
                  aria-expanded={showNotifications}
                  alt="notification-icon"
                />
              ) : (
                <img
                  src={bell1}
                  className="dropdown-toggle me-2 me-sm-5"
                  type="button"
                  id="notifi"
                  onClick={toggleNotifications}
                  aria-expanded={showNotifications}
                  alt="notification-icon"
                />
              )}

              {showNotifications && (
                <div className="notification-container" ref={containerRef}>
                  <div className="notification-header">
                    <h5>Notifications</h5>
                    <span
                      className="notification-close"
                      onClick={toggleNotifications}
                    >
                      &times;
                    </span>
                  </div>
                  {notifyData.length > 0 ? ( 
                  <ul className="notification-list1">
                    {notifyData.map((notification) => (
                      
                      <li key={notification.id} className="notification-item">
                        <img
                          src={BellIcon1}
                          alt="icon"
                          className="notification-icon"
                        />
                        <div className="notification-content">
                          <div className="notification-info">
                            <span className="notification-name">
                              {notification.title}
                            </span>
                            <span className="notification-time">
                              {notification.Created}
                            </span>
                          </div>
                          <div className="notification-text">
                            {notification.body}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  ):(
                <div className="no-notifications mt-5 text-center">
                       Notifications not available at this moment
                    </div>
                  )
                }
                </div>
              )}
            </div>

            <img
              src={
                user?.pic
                  ? `https://d28qcxi1juf339.cloudfront.net/${user?.pic}`
                  : ElipsIcon
              }
              alt="User-Image"
              className="elips-icon"
            />
            {/* <img src={ElipsIcon} alt="elips icon" className="elips-icon"/> */}
            <div className="dropdown" style={{ cursor: "pointer" }}>
              <div
                className="d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownMenuButton"
              >
                <button className="btn kate-deo " type="button">
                  <b className="">{user?.name}</b>
                  <p className="m-0">Admin</p>
                </button>
                <img
                  src={DropDownIcon}
                  alt="drop-down-icon"
                  className="icon me-3"
                />
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start logoutDesign"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <a className="dropdown-item" onClick={handleLogout}>
                    <img src={logoutIcon} alt="logout-icon" /> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
