import React, { useEffect, useState } from "react";
import { GetTemplates } from "../../../Api";
import { useSelector } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import { FaMinusCircle } from "react-icons/fa";

function NotificationTemplate({
  handleModalOpen,
  tempchange,
  handleTemplateClick,
  activeTemplate,
  handleNewTemplateCreate,
  handleDeleteTemplate,
  setCreateModalOpen,
  createTemplate,
  setisTemplate,
  EditTemplateClick,
}) {
  const token = useSelector((state) => state?.user?.user?.token);

  const [templates, setTemplates] = useState([]);

  const FetchTemplates = () => {
    GetTemplates({ token })
      .then((res) => {
        setTemplates(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    FetchTemplates();
  }, [tempchange]);

  return (
    <div
      className="col-6 col-md-4 p-2 order-1 order-md-2 temp no-scrollbar"
      style={{ height: "500px", overflowY: "auto" }}>
      <style>
        {`
        .no-scrollbar {
  overflow: auto; /* Keep scroll functionality */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}  
        `}
      </style>
      <div
        style={{
          border: "none",
          outline: "none",
          width: "100%",
          height: "auto",
          borderRadius: "10px",
          backgroundColor: "#FFF",
          textAlign: "center",
          fontFamily: "Maven Pro",
          fontWeight: "700",
          fontSize: "25px",
          marginBottom: "5px",
        }}>
        Templates
      </div>
      <div className="templates-list">
        {templates.map((template) => (
          <div
            key={template._id}
            className="template-item  mb-2  mt-2 border rounded"
            style={{
              cursor: "pointer",
              position: "relative",
              backgroundColor: `${
                activeTemplate == template._id ? "#A0A0A0" : "white"
              }`,
              color: `${activeTemplate == template._id ? "white" : "black"}`,
            }}
            onClick={() => handleTemplateClick(template)}>
            <h5>{template.title}</h5>
            <p>{template.body}</p>
            <div
              onClick={() => handleDeleteTemplate(template._id)}
              style={{
                position: "absolute",
                right: 0,
                zIndex: 2,
                top: -10,
                backgroundColor: "#FF3333",
                width: "25px",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50%",
                height: "25px",
                border: "none",
                color: "white",
              }}>
              <p>X</p>
            </div>
            {activeTemplate == template._id ? (
              <div style={{ display: "flex", gap: 4 }}>
                <button
                  onClick={() => {
                    setisTemplate(true);
                    handleModalOpen(template._id);
                  }}
                  style={{
                    backgroundColor: "#FF3333",
                    color: "white",
                    border: 0,
                    padding: "8px",
                    width: "30%",
                    borderRadius: "10px",
                  }}>
                  Send
                </button>
                <button
                  onClick={() => EditTemplateClick(template)}
                  style={{
                    backgroundColor: "#FF3333",
                    color: "white",
                    border: 0,
                    padding: "8px",
                    width: "30%",
                    borderRadius: "10px",
                  }}>
                  Edit
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
      <p onClick={handleNewTemplateCreate} style={{ textAlign: "center" }}>
        {!createTemplate ? (
          <IoIosAddCircle
            style={{ width: "30px", height: "30px", color: "red" }}
          />
        ) : (
          <FaMinusCircle
            style={{ width: "25px", height: "25px", color: "red" }}
          />
        )}
      </p>
    </div>
  );
}

export default NotificationTemplate;
