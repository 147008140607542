import React, { useState } from "react";
import "./Payment.css";
import { useLocation, useParams } from "react-router-dom";
import Button from "../../../../../components/CommonComp/Button/Button";
import OTPInput from "otp-input-react";
import Sucess from "../../../../../components/CommonComp/Assests/sucess.png";

const Payment = () => {
  const { id } = useParams();
  const location = useLocation();
  const { bartender } = location.state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] =
    useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleRequest = () => {
    // Logic for sending OTP request
  };

  const handlePaymentSubmit = () => {
    // Logic for submitting payment and showing success modal
    setIsModalOpen(false); // Close OTP verification modal
    setIsPaymentSuccessModalOpen(true); // Open payment success modal
  };

  return (
    <>
      <div className="algn-top-bar">
        <div className="brd-crum">
          Home &gt; Bartender &gt; Bartender Details &gt;<b>Make Payment</b>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-8">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                boxShadow: "0px 4px 23px 0px rgba(0, 0, 0, 0.08)",
              }}>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table text-align-center">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Earnings</th>
                      <th>Payment Status</th>
                      <th>Joined Date</th>
                    </tr>
                    <tr>
                      <td className="text-center text-muted">{id}</td>
                      <td className="text-center text-muted">
                        {bartender.name}
                      </td>
                      <td className="text-center text-muted">
                        {bartender.earnings}
                      </td>
                      <td className="text-center text-muted">Pending</td>
                      <td className="text-center text-muted">
                        {new Date(bartender.joinedDate).toLocaleDateString()}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="paybutton mt-5" style={{ display: "inline" }}>
        <Button
          onClick={toggleModal}
          title={`Pay $${bartender.earnings}.00`}
          style={{ display: "inline" }}
        />
      </div>

      {/* OTP Verification Modal */}
      {isModalOpen && (
        <div
          className="modal d-flex align-items-center justify-content-center"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div
            className="modal-dialog"
            role="document"
            style={{ width: "891px", height: "530px" }}>
            <div className="modal-content">
              <div className="text-end me-3 mt-2">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleModal}></button>
              </div>
              <div className="modal-body" style={{ borderRadius: "20px" }}>
                <h5 className="modal-title text-center">OTP Verification</h5>
                <p className="text-center mt-4">
                  Code is sent to abc@gnail.com
                </p>
                <div
                  className="mt-5"
                  style={{ display: "flex", justifyContent: "center" }}>
                  <OTPInput autoFocus OTPLength={4} otpType="number" />
                </div>
                <p className="text-center mt-5">Didn’t receive any code?</p>
                <p className="text-center mt-4" onClick={handleRequest}>
                  Request new code again.
                </p>
                <div
                  className="mt-5"
                  style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handlePaymentSubmit} title="Submit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Payment Success Modal */}
      {isPaymentSuccessModalOpen && (
        <div
          className="modal d-flex align-items-center justify-content-center"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div
            className="modal-dialog"
            role="document"
            style={{ width: "891px", height: "530px" }}>
            <div className="modal-content">
              <div className="text-end me-3 mt-2">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsPaymentSuccessModalOpen(false)}></button>
              </div>
              <div className="modal-body" style={{ borderRadius: "20px" }}>
                <h5 className="modal-title text-center">
                  <img src={Sucess} alt="Payment sucess logo" />
                </h5>
                <p
                  className="text-center mt-5"
                  style={{
                    color: "#00992B",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "160%",
                  }}>
                  Payment Success
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Payment;
