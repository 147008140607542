import React, { useEffect, useState } from "react";
import "./AccMangmnt.css";
import UserIcon from "../../components//CommonComp/Assests/carbon_user-avatar-filled-alt.svg";
import EmailIcn from "../../components/CommonComp/Assests/dashicons_email-alt.svg";
import PhoneIcon from "../../components/CommonComp/Assests/carbon_phone-voice.svg";
import LockIcon from "../../components/CommonComp/Assests/ant-design_lock-filled.svg";
import Button from "../../components/CommonComp/Button/Button";
import ProfileImg from "../../components/CommonComp/ProfileImg/ProfileImg";
import Eyeclose from "../../components/CommonComp/Assests/eye-close.svg";
import EyeOpen from "../../components/CommonComp/Assests/eye.svg";
import { useDispatch, useSelector } from "react-redux";
import { handleDeleteUser, updateAccount, updatePassword } from "../../Api";
import { toast } from "react-toastify";
import { updateUser } from "../../redux/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { ensureNumber, ensureString } from "../../utils/inputFormatter";

const AccMangmnt = () => {
  const token = useSelector((state) => state.user.user.token);
  const users = useSelector((state) => state.user.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [confirmPas, setConfirmPass] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [allPass, setAllPass] = useState({
    currentPassword: "",
    newPassword: "",
    cPassword: "",
  });
  const [errormsg, setErrorMsg] = useState({});

  const checkInput = ["firstName", "lastName", "email", "phoneNo"];

  const setData = () => {
    setUser({
      firstName: users.name.split(" ")[0],
      lastName: users.name.split(" ")[1],
      email: users?.email,
      profile: users?.pic,
      phoneNo: users?.phoneNo,
      bio: users?.bio || "",
    });
    setLoading(false);
  };

  useEffect(() => {
    setData();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const errors = checkInput.reduce((acc, field) => {
        if (!user[field] || user[field] === "") {
          acc[field] = `${field} is required`;
        }
        return acc;
      }, {});
      if (Object.keys(errors).length > 0) {
        toast.error("Mandatory Fields Empty");
        setErrorMsg(errors);
      } else {
        setErrorMsg({});
        const confirm = window.confirm(
          "Are you sure you want to update your details ?"
        );
        if (!confirm) {
          return;
        }
        const newUser = await updateAccount({ user, token: token });
        dispatch(updateUser(newUser?.data));

        toast.success(newUser?.message);
      }
    } catch (err) {
      console.log("Err", err);
      toast.error("Some error");
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, cPassword } = allPass;
    if (!currentPassword || !newPassword || !cPassword) {
      toast.warning("Please fill in all fields");
      return;
    }
    if (newPassword !== cPassword) {
      toast.error("New  and confirm password do not match");
      return;
    }
    if (
      newPassword.length < 8 ||
      cPassword.length < 8 ||
      currentPassword.length < 8
    ) {
      toast.error("Password should be at least 8 characters long");
      return;
    }

    try {
      const confirm = window.confirm(
        "Are you sure you want to update your password ?"
      );
      if (!confirm) {
        return;
      }
      await updatePassword({ allPass, token: token });

      toast.success("Password updated successfully");
      setAllPass({
        currentPassword: "",
        newPassword: "",
        cPassword: "",
      });
    } catch (err) {
      console.log("Error updating password:", err);
      toast.error(err.response.data.message);
    }
  };

  const handleDeleteAdminSelfAccount = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to delet Your Account?"
      );
      if (!isConfirmed) {
        return;
      }
      await handleDeleteUser({ id: id, token: token });

      setTimeout(() => {
        navigate("/");
      }, 2000);
      toast.success("Your Account has been deleted sucessfully");
    } catch (err) {
      toast.error("Some error occured");
      console.error("Error deleting user:", err);
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNewPass = () => {
    setNewPass(!newPass);
  };
  const handleConfirmPass = () => {
    setConfirmPass(!confirmPas);
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Account Management</span>,
  ];

  if (!loading) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>
        </div>

        <div className="acnt-mangemnet1 mt-5">
          <div className="row text-center account-mangmnet-container-whole gap-5 me-2">
            <div className="col-md-2 col-sm-12 text-center mt-5">
              <ProfileImg user={user} setUser={setUser} />
            </div>
            <div className="col-md-9 col-sm-12">
              <p className="your-details mt-5 mt-md-0 text-left">
                Your details
              </p>

              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div
                    className={`input-container1 ps-3 d-flex align-items-center ${
                      errormsg?.firstName ? "border-danger" : ""
                    }`}>
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="user-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="First name"
                      className="first-name comn-inpt-property-account "
                      value={user.firstName}
                      onChange={(e) => {
                        if (errormsg?.firstName) {
                          delete errormsg["firstName"];
                        }

                     
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                          
                        
                            setUser({ ...user, firstName:e.target.value });
                      }
                    



                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div
                    className={`input-container1 ps-3 d-flex align-items-center ${
                      errormsg?.lastName ? "border-danger" : ""
                    }`}>
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="user-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Last name"
                      className="last-name comn-inpt-property-account"
                      value={user.lastName}
                      onChange={(e) => {
                        if (errormsg?.lastName) {
                          delete errormsg["lastName"];
                        }

                        if (e.target.value.trim() !== "" || e.target.value === "") {
                         
                      
                          setUser({ ...user, lastName: e.target.value });
                    }
                       
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={EmailIcn}
                        alt="Email-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="email"
                      placeholder="Email ID"
                      className="email-id comn-inpt-property-account"
                      disabled
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div
                    className={`input-container1 ps-3 d-flex align-items-center ${
                      errormsg?.phoneNo ? "border-danger" : ""
                    }`}>
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={PhoneIcon}
                        alt="Phone-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      className="phone-no comn-inpt-property-account"
                      value={user.phoneNo}
                      onChange={(e) => {
                        if (errormsg?.phoneNo) {
                          delete errormsg["phoneNo"];
                        }
                        const value = ensureNumber({
                          value: e.target.value,
                          maxLength: 10,
                        });
                        setUser({ ...user, phoneNo: value });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="input-container1 ps-3 pt-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="textarea-Icon"
                        className="text-area-icon same-icon-size"
                      />
                    </div>
                    <textarea
                      placeholder="Your Bio"
                      className="your-bio comn-inpt-property-account text-area-width"
                      value={user?.bio}
                      onChange={(e) =>{
                        
                        if (e.target.value.trim() !== "" || e.target.value === "") {
                          setUser({ ...user, bio: e.target.value })
                        }
                      }
                    }
                    />
                  </div>
                </div>
              </div>

              <div className="butn-adjust">
                <Button
                  title="Save your Profile"
                  onClick={(e) => handleUpdate(e)}
                  type="submit"
                />
              </div>

              {/* password */}
              <div className="row mb-4">
                <div className="col-md-12 chnge-password">
                  <p>Change Password</p>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center icons-open-close">
                    <div className="lock-img-input">
                      <div className="accnt-mangmnet-icons">
                        <img
                          src={LockIcon}
                          alt="Lock-Icon"
                          className="same-icon-size"
                        />
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Old Password"
                        className="old-password comn-inpt-property-account"
                        value={allPass.currentPassword}
                        onChange={(e) =>
                                        {
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                          setAllPass({
                            ...allPass,
                            currentPassword: e.target.value,
                          })
                          }
                        }
                      }
                      />
                    </div>
                    <div className="me-4">
                      {showPassword ? (
                        <img
                          src={EyeOpen}
                          alt="Hide-Password-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handlePasswordVisibility}
                        />
                      ) : (
                        <img
                          src={Eyeclose}
                          alt="Show-Password-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handlePasswordVisibility}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center icons-open-close">
                    <div className="lock-img-input">
                      <div className="accnt-mangmnet-icons">
                        <img
                          src={LockIcon}
                          alt="Lock-Icon"
                          className="same-icon-size"
                        />
                      </div>
                      <input
                        type={newPass ? "text" : "password"}
                        placeholder="Enter New Password"
                        className="current-password comn-inpt-property-account"
                        value={allPass.newPassword}
                        onChange={(e) =>
                        {
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                          setAllPass({
                            ...allPass,
                            newPassword: e.target.value,
                          })
                        }
                        }
                      }
                      />
                    </div>
                    <div className="me-4">
                      {newPass ? (
                        <img
                          src={EyeOpen}
                          alt="Hide-Password-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleNewPass}
                        />
                      ) : (
                        <img
                          src={Eyeclose}
                          alt="Show-Password-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleNewPass}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center icons-open-close">
                    <div className="lock-img-input">
                      <div className="accnt-mangmnet-icons">
                        <img
                          src={LockIcon}
                          alt="Lock-Icon"
                          className="same-icon-size"
                        />
                      </div>
                      <input
                        type={confirmPas ? "text" : "password"}
                        placeholder="Re-Enter New Password"
                        className="new-password comn-inpt-property-account"
                        value={allPass.cPassword}
                        onChange={(e) =>{
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                        
                          setAllPass({ ...allPass, cPassword: e.target.value })
                        }
                      }
                      }
                      />
                    </div>
                    <div className="me-4">
                      {confirmPas ? (
                        <img
                          src={EyeOpen}
                          alt="Hide-Password-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleConfirmPass}
                        />
                      ) : (
                        <img
                          src={Eyeclose}
                          alt="Show-Password-icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleConfirmPass}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="butn-adjust">
                <Button
                  title="Change password"
                  onClick={(e) => handlePasswordUpdate(e)}
                  type="submit"
                />
              </div>

              <hr className="line-color" />

              <div
                className="delet-account"
                onClick={() => handleDeleteAdminSelfAccount(users?._id)}>
                Delete Account?
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AccMangmnt;
