import React, { useEffect, useState } from 'react'
import './PushNotification.css';
import upArrow from  '../../../components/CommonComp/Assests/uparrow.svg'
import downArrow from '../../../components/CommonComp/Assests/downarrow.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { list, getAntStyleList } from './timezones';
import dayjs from 'dayjs';
import customParseFormate from 'dayjs/plugin/customParseFormat';
import { toast } from 'react-toastify';
dayjs.extend(customParseFormate)
const SchedulePagesModal = ({setTimeZone,setNotifyDate,setNotifyTime,setSent,setActiveId,notifyDate,setChange}) => {

  // console.log("scedule modal 1234",notificationId);

    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00')
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [childTimeZone, setChildTimeZone] = useState('')



    const handleSend = () => {
      if(selectedDate && childTimeZone && (hours || minutes)){
        setSent(true);
        setNotifyDate(selectedDate);
        setTimeZone(childTimeZone)
        setNotifyTime(`${hours}:${minutes}`)
        // setActiveId(0);
        
        toast.success("Notification Scheduled")
      }
      else{
        toast.warning("Please select date and timezone");
      }
    }

      const handleDateChange = (date) => {    
      let setDate=date.$d;
      setDate = dayjs(setDate)
      
      setSelectedDate(dayjs(setDate).format('YYYY-MM-DD'));
    }

    // const handleTimes=()=>{
   
    // }
    const handleHoursChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) { 
        const parsedValue = parseInt(value, 10) || 0;
        if (parsedValue >= 0 && parsedValue <= 23) {
          setHours(parsedValue);
        }
      }
    };
    
    const handleMinutesChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) { 
        const parsedValue = parseInt(value, 10) || 0;
        if (parsedValue >= 0 && parsedValue <= 59) {
          setMinutes(parsedValue);
        }
      }
    };

    
    const incrementHours = () => {
      setHours(prev => (prev + 1) % 24);
    };
  
    const decrementHours = () => {
      setHours(prev => (prev - 1 + 24) % 24);
    };
  
    const incrementMinutes = () => {
      setMinutes(prev => (prev + 1) % 60);
    };
  
    const decrementMinutes = () => {
      setMinutes(prev => (prev - 1 + 60) % 60);
    };
  
    const timezoneOptions = list.map((timezone, index) => (
      <option key={index} value={timezone}>
        {timezone}
      </option>
    ));

    const handleZone=(e)=>{
      setChildTimeZone(e.target.value)
    }

    
  return (
    <div>
    {/* <div className="modal-content allmodalborder"> */}
      <div className="date-time-zone-contianer mt-2">
       <div className='ms-3 mt-2  dte-time'>Select Date and Time</div>
        <select name="timezone" id="timezone" className='timezones me-2' onClick={(e)=>handleZone(e)}>
           <option value="">Select time zone</option>
            {timezoneOptions}
          </select>
      </div>
     
      <div className="custom-modal-body-sm">
        <div className="row">
          <div className="col-md-3 mt-4">
            <p className="text-center" style={{fontSize:'12px'}}>{selectedDate}&nbsp;&nbsp;{hours}:{minutes}</p>

            <div class="time-container">
              <div class="time-input">
                <img src={upArrow} alt='upArrow' class='up-arrow mb-3' onClick={()=>incrementHours()}/>

                <input type='text' max={2} class='hrs' value={hours.toString().padStart(2,'0')}   onChange={handleHoursChange}/>

                <img src={downArrow} alt='downArrow' class='down-arrow mt-3' onClick={()=>decrementHours()}/>
              </div>

              <span class="colon">:</span>

              <div class="time-input">
                <img src={upArrow} alt='upArrow' class='up-arrow mb-3' onClick={()=>incrementMinutes()}/>

                <input type='text' max={2} class='minute' value={minutes.toString().padStart(2,'0')} onChange={handleMinutesChange}/>

                <img src={downArrow} alt='downArrow' class='down-arrow mt-3' onClick={()=>decrementMinutes()}/>
                
              </div>
          </div>

            <div className="text-center mt-3">
              {/* <button className="btn" style={{ width: '130px', height: '48px', borderRadius: '100px',backgroundColor: "#F33", color: "#FFF",border:"none" }} onClick={()=>handleTimes()}>Select</button> */}
            </div>
          </div>
          <div className="col-md-6 mt-3 mt-md-0">
            <div className="text-center custom-calendar">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar 
                      // value={selectedDate}
                      disablePast
                      
                      onChange={handleDateChange} 
                      className='datess-clndr'
                    />
             </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="btn custom-button-now mb-4" data-bs-dismiss="modal" onClick={handleSend}>Send</button>
      </div>
    </div>
  // </div>
  )
}

export default SchedulePagesModal
