import React, { useState } from "react";
import "./CreateUserPage.css";
import axios from "axios"; // Import axios for making HTTP requests
import UserPage from "../../../../../components/CommonComp/UserPage/UserPage";
import ProfileImg from "../../../../../components/CommonComp/ProfileImg/ProfileImg";
import { createBartenderDetails } from "../../../../../Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";

const CreateUserPage = () => {
  const token = useSelector((state) => state.user.user.token);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    city: "",
    state: "",
    zipCode: "",
    dob: "",
    role: 1,
    profile: "",
  });

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
 

  const handleSubmit = async () => {
    // if (
    //   !formData.name.trim() ||
    //   !formData.email.trim() ||
    //   !formData.phoneNo.trim() ||
    //   !formData.city.trim() ||
    //   !formData.state.trim() ||
    //   !formData.zipCode.trim() ||
    //   formData.role != 1 ||
    //   formData.role != 2 ||
    //   !formData.dob.trim()
    // ) {
    //   toast.error("All Fields Madatory!");
    //   return;
    // }
    if (
      formData.name &&
      formData.email &&
      formData.phoneNo &&
      formData.city &&
      formData.state &&
      formData.zipCode &&
      formData.role &&
      formData.dob
    ) {
      if (!validateEmail(formData.email)) {
        toast.error("Please provide a valid email address.");
        return;
      }
      

      try {
        const response = await createBartenderDetails({
          token,
          userData: formData,
        });

        setFormData({
          name: "",
          email: "",
          phoneNo: "",
          city: "",
          state: "",
          zipCode: "",
          dob: "",
          // role: 1,
          profile: "",
        });
        toast.success(response.message);
        navigate("/dashboard-page/bartender-section/bartender-details");
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      toast.warning("All fields are required");
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="Bartender"
      to="/dashboard-page/bartender-section"
      className="All-color-breakcurm">
      &nbsp;Bartender
    </Link>,
    <Link
      key="BartenderDetails"
      to="/dashboard-page/bartender-section/bartender-details"
      className="All-color-breakcurm">
      &nbsp;Bartender Details
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp; Create User</span>,
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      <div className="unique-grid row justify-content-center text-center">
        <ProfileImg user={formData} setUser={setFormData} />
        <UserPage
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CreateUserPage;
