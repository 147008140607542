import React, { useEffect, useState } from "react";
import Card5 from "../../../../../components/CommonComp/Card5/Card5";
import "./BartenderProfile.css";
import CreateUserBtn from "../../../../../components/CommonComp/CreateUserBtn/CreateUserBtn";
import Button from "../../../../../components/CommonComp/Button/Button";
import LockIcon from "../../../../../components/CommonComp/Assests/ant-design_lock-filled.svg";
import UserIcon from "../../../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import EmailIcn from "../../../../../components/CommonComp/Assests/dashicons_email-alt.svg";
import PhoneIcon from "../../../../../components/CommonComp/Assests/carbon_phone-voice.svg";
import YearOfexpIcon from "../../../../../components/CommonComp/Assests/mdi_magic.svg";
import Usr2Icn from "../../../../../components/CommonComp/Assests/fa-solid_user-tie.svg";
import pdfIcon from "../../../../../components/CommonComp/Assests/pdf-logo 1.png";
import EditIcon from "../../../../../components/CommonComp/Assests/material-symbols-light_edit-square-outline.svg";
import Ed from "../../../../../components/CommonComp/Assests/fluent_edit-48-regular.svg";
import Elips4Icon from "../../../../../components/CommonComp/Assests/Ellipse 4.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileImg from "../../../../../components/CommonComp/ProfileImg/ProfileImg";
import UserAvtar from "../../../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import {
  fetchParticularDetails,
  handleBlock,
  handleDeletBartenderUser,
  handleResumeUse,
  handleResumeUser,
  handleSuspendUser,
} from "../../../../../Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../../../components/CommonComp/Loader/Loader";
import { Box, Modal } from "@mui/material";

const BartenderProfile = ({
  showMoreOptionButton = true,
  showSuspiciousButton = true,
  showBreadcrumb = true,
  showReferralBox = true,
}) => {
  const token = useSelector((state) => state.user.user.token);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [yearOfExperience, setYearOfExperience] = useState("");
  const [designation, setDesignation] = useState("");
  const [bio, setBio] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [profile, setProfileImge] = useState();
  const [disable, setDisable] = useState(true);
  const [bartenderDetails, setBartenderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const id = useParams();

  const uid = id.id;

  let navigate = useNavigate();

  const fetchData = async () => {
    try {
      const data = await fetchParticularDetails({ token, uid });

      if (data[0]) {
        setBartenderDetails(data[0]);

        if (data[0]?.name) {
          const nameParts = data[0]?.name.split(" ");
          setFirstName(nameParts[0] || "");
          // setLastName(nameParts[1] || "");
          setLastName(nameParts.slice(1).join(" ") || "");
        } else {
          setFirstName("");
          setLastName("");
        }
        setLoading(false);
        setPhoneNumber(data[0]?.phoneNo || "");
        setEmail(data[0]?.email || "");
        setYearOfExperience(data[0]?.BartenderDetails?.yearsOfExperience || "");
        setBio(data[0]?.BartenderDetails?.bio || "");
        setDesignation(data[0]?.BartenderDetails?.designation || "");
        setWebsiteLink(data[0]?.BartenderDetails?.instagramlink || "");
        setLinkedinLink(data[0]?.BartenderDetails?.linkedinlink || "");
        setProfileImge(data[0]?.pic);
      } else {
        console.log("No data found for this user");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setLoading(false);
    };
  }, []);

  const handleTotalEarning = () => {
    navigate("/bartender/bartendrDetails/user/earnings");
  };

  //   const handleDeleteUser = async (uid) => {
  //     try {
  //         const isConfirmed = window.confirm("Are you sure you want to delete this bartender?");
  //       if (!isConfirmed) {
  //         console.log("bartender deletion cancelled.");
  //         return;
  //       }
  //         await handleDeletBartenderUser({ uid, token });

  //         setBartenderDetails({});
  //         navigate("/dashboard-page/bartender-section/bartender-details")
  //         console.log("User deleted successfully");
  //         toast.success("Bartender Deleted successfully");
  //     } catch (error) {
  //         console.error(error.message);
  //     }
  // }

  // suspend or block same
  const handleSuspendbar = async (uid) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to suspend this Bartender?"
      );
      if (!isConfirmed) {
        return;
      }
     const response= await handleSuspendUser({ uid, token });
      fetchData();
      toast.success(response.message);
    } catch (error) {
      toast.error("Some Error occurred");
      console.error("Error suspending bartender:", error);
    }
  };

  // resume
  const handleResume = async (uid) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to Resume this Bartender Account?"
      );
      if (!isConfirmed) {
        return;
      }
    const response=  await handleResumeUser({ uid, token });
      fetchData();
      toast.success(response.message);
    } catch (err) {
      toast.error("Some Error Occured");
      console.error("Error resuming user:", err);
    }
  };

  // suspecious or suspend or block same
  const handleSuspecious = async (uid) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to Block this Bartender?"
      );
      if (!isConfirmed) {
        return;
      }
      await handleBlock({ uid, token });
      toast.success("Bartender Blocked successfully");
    } catch (error) {
      toast.error("Some Error occurred");
    }
  };

  // view resume pdf
  // const handleViewResume = () => {
  //   if (bartenderDetails?.BartenderDetails?.resume) {
  //     window.open(
  //       `https://d28qcxi1juf339.cloudfront.net/${bartenderDetails?.BartenderDetails?.resume.resumeKey}`,
  //       "_blank"
  //     );
  //   } else {
  //     toast.error("Resume not avilable");
  //   }
  // };
  
  const [resumeUrl, setResumeUrl] = useState(null);
  const [showResumeModal, setShowResumeModal] = useState(false);

  const handleViewResume = () => {
    if (bartenderDetails?.BartenderDetails?.resume) {
      const resumeUrl =   `https://d28qcxi1juf339.cloudfront.net/${bartenderDetails?.BartenderDetails?.resume.resumeKey}`
      setResumeUrl(resumeUrl);
      setShowResumeModal(true);
    } else {
      toast.error("Resume not available");
    }
  };

  //view cetification img

  const handleViewCertification = (certification) => {
    if (certification) {
      window.open(
        `https://d28qcxi1juf339.cloudfront.net/${certification?.certificateKey}`,
        "_blank"
      );
    } else {
      toast.error("Certification not avilable");
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="Bartender"
      to="/dashboard-page/bartender-section"
      className="All-color-breakcurm">
      &nbsp;Bartender
    </Link>,
    <Link
      key="BartenderDetails"
      to="/dashboard-page/bartender-section/bartender-details"
      className="All-color-breakcurm">
      &nbsp;Bartender Details
    </Link>,
    <span style={{ fontWeight: "bolder" }}>
      &nbsp;
      {firstName || lastName
        ? `${firstName || ""} ${lastName || ""}`.trim()
        : "Name not available"}
    </span>,
  ];

  if (!loading) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          {showBreadcrumb && (
            <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
              {breadcrumbItems.map((item, index) => (
                <div className="all-breakcrum" key={index}>
                  {item}
                  {index < breadcrumbItems.length - 1 && " > "}
                </div>
              ))}
            </div>
          )}

          {showMoreOptionButton && (
            <div className="btn-group">
              <button
                type="button"
                className="btn dropdown-toggle more-option-btn"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                More Option
              </button>
              <ul className="dropdown-menu drp-dwn-border">
                {bartenderDetails.account_status === 3 && (
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleResume(uid)}>
                      Resume User
                    </a>
                  </li>
                )}
                {(bartenderDetails.account_status === 1 ||
                  bartenderDetails.account_status === 5 ||
                  bartenderDetails.account_status === 0) && (
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleSuspendbar(uid)}>
                      Suspend User
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        <div className="wraperr">
          <Card5
           content={`$
            ${bartenderDetails?.totalEarningsAmount
              ? parseFloat(bartenderDetails.totalEarningsAmount).toFixed(2)
              : "0"}
          `}
            content1="Total Earnings >"
            to={`/bartender/bartenderDetails/User/earnings/${uid}`}
          />
          <Card5
            content={bartenderDetails.overallRating || "0"}
            content1="Total Review >"
            content2={bartenderDetails.totalReviews + " reviews" || "0"}
            to={`/bartender/bartenderDetails/User/review/${uid}`}
          />
          <Card5
            content={bartenderDetails.totalGigs || "0"}
            content1="Total Gigs >"
            to={`/bartender/bartenderDetails/User/gigs/${uid}`}
          />

          {showReferralBox && (
            <Card5
              content={bartenderDetails.totalRefferal || "0"}
              content1="Referrals >"
              to={`/bartender/bartenderDetails/User/referal/${uid}`}
            />
          )}
        </div>

        <div className="acnt-mangemnet">
          <div className="row spcing-btween-grd mt-5">
            <div className="col-md-2 text-center mb-3">
              <div className="name-img-cont">
                <div className="profile-imges">
                  <img
                    src={
                      bartenderDetails?.pic
                        ? `https://d28qcxi1juf339.cloudfront.net/${bartenderDetails?.pic}`
                        : UserAvtar
                    }
                    alt="User-Image"
                    className="bar-det-img"
                  />
                </div>
                <div className="elips-text">{`${firstName} ${lastName}`}</div>
              </div>
            </div>
            <div className="col-md-9 pb-4">
              <div className="height-design-line"></div>
              <div className="edit-yourname">
                <p className="your-details mt-4 mt-md-0">Basic details</p>
              </div>

              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="user-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="First name"
                      className="ps-4 first-name comn-inpt-property"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="user-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Last name"
                      className="ps-4 last-name comn-inpt-property"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      disabled={disable}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={PhoneIcon}
                        alt="Phone-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      className="ps-4 phone-no comn-inpt-property"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={EmailIcn}
                        alt="Email-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="email"
                      placeholder="Email ID"
                      className="ps-4 email-id comn-inpt-property"
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={YearOfexpIcon}
                        alt="Phone-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Year of Experience"
                      className="ps-4 phone-no comn-inpt-property"
                      value={yearOfExperience}
                      onChange={(e) => setYearOfExperience(e.target.value)}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="input-container1 ps-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={Usr2Icn}
                        alt="Email-Icon"
                        className="same-icon-size"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Role"
                      className="ps-4 email-id comn-inpt-property"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      disabled={disable}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="input-container1 ps-3 pt-3 d-flex align-items-center">
                    <div className="accnt-mangmnet-icons">
                      <img
                        src={UserIcon}
                        alt="textarea-Icon"
                        className="text-area-icon same-icon-size"
                      />
                    </div>
                    <textarea
                      placeholder="Your Bio"
                      className="ps-4 your-bio-bartender comn-inpt-property-bio"
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                      disabled={disable}
                      style={{ border: "none", backgroundColor: "#FFF" }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="website-text-designs mb-3 d-flex align-items-center">
                    <label htmlFor="websiteLink">Website Link</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Website"
                    id="websiteLink"
                    className="ps-4 current-password comn-inpt-property website-link"
                    value={websiteLink}
                    onChange={(e) => setWebsiteLink(e.target.value)}
                    disabled={disable}
                  />
                </div>
                <div className="col-md-6">
                  <div className="website-text-designs mb-3 d-flex align-items-center">
                    <label htmlFor="linkedinLink">LinkedIn Link</label>
                  </div>
                  <input
                    type="text"
                    placeholder="LinkedIn"
                    id="linkedinLink"
                    className="ps-4 new-password comn-inpt-property website-link"
                    value={linkedinLink}
                    onChange={(e) => setLinkedinLink(e.target.value)}
                    disabled={disable}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-12 documnets">
                  <p>Document</p>
                </div>
              </div>
              {/* <div className="row mb-4"> */}
              <div className="box-wrapper">
                <div className="box-container">
                  {bartenderDetails?.BartenderDetails?.resume ? (
                    <div className="box" onClick={() => handleViewResume()}>
                      <div className="reumeicon">
                        {bartenderDetails?.BartenderDetails?.resume
                          ?.fileType === "pdf" ? (
                          <img
                            src={pdfIcon}
                            style={{ width: "40px" }}
                            alt="Resume PDF"
                          />
                        ) : (
                          <img
                            src={`https://d28qcxi1juf339.cloudfront.net/${bartenderDetails?.BartenderDetails?.resume?.resumeKey}`}
                            alt="Resume"
                            style={{ width: "30px" }}
                          />
                        )}
                      </div>
                      <div className="resumenmae">
                        <p>
                          {(
                            "Resume:" +
                              " " +
                              bartenderDetails?.BartenderDetails?.resume
                                ?.resumenName || "No Resume Available"
                          ).slice(0, 25 - 3) + " ..."}
                        </p>
                        <p>
                          {(
                            bartenderDetails?.BartenderDetails?.resume?.size /
                            10000
                          ).toFixed(2) + " kb" || "No data"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p>Resume Not Available</p>
                  )}

                  {bartenderDetails?.BartenderDetails?.certifications?.length >
                  0 ? (
                    bartenderDetails?.BartenderDetails?.certifications.map(
                      (certification, index) => (
                        <div
                          className="box"
                          key={index}
                          onClick={() =>
                            handleViewCertification(certification)
                          }>
                          <div className="reumeicon">
                            {certification?.fileType === "pdf" ? (
                              <img
                                src={pdfIcon}
                                style={{ width: "40px" }}
                                alt="Certification PDF"
                              />
                            ) : (
                              <img
                                src={`https://d28qcxi1juf339.cloudfront.net/${certification?.certificateKey}`}
                                alt="Certification"
                                style={{ width: "30px" }}
                              />
                            )}
                          </div>
                          <div className="resumenmae">
                            <p>
                              {(
                                "Certificates:" +
                                  " " +
                                  certification?.certificateName ||
                                "No Certifications Available"
                              ).slice(0, 27 - 3) + " ..."}
                            </p>
                            <p>{(certification?.size / 10000).toFixed(2)} kb</p>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <p className="ms-lg-5">Certifications Not Available</p>
                  )}
                </div>
              </div>
              {/* </div> */}

              {bartenderDetails.account_status !== 3 && showSuspiciousButton && (
                <div className="butn-adjust suspecious mb-3">
                  <Button
                    title1="Suspicious Account"
                    onClick={() => handleSuspendbar(uid)}
                  />
                </div>
              )}
              {bartenderDetails.account_status === 3 && showSuspiciousButton && (
                <div className="butn-adjust suspecious mb-3">
                  <Button
                    title1="Resume Account"
                    onClick={() => handleResume(uid)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>


        <Modal
  open={showResumeModal}
  onClose={() => setShowResumeModal(false)}
  aria-labelledby="resume-modal-title"
  aria-describedby="resume-modal-description"
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  <Box
    sx={{
      width: '80%',
      maxWidth: '700px',
      height: '55vh',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: 24,
      outline: 'none',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }}
  >
    <img
      src={resumeUrl}
      alt="Resume"
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        display: 'block',
        margin: '0 auto',
      }}
    />
  </Box>
  
</Modal>


      </>

      
    );
  }
};
// }

export default BartenderProfile;
