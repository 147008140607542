import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPrivacyPolicy,uptePrivacy } from '../../../Api'; 
import Reactquil from '../../../components/CommonComp/Reactquill/Reactquil';
import Button from '../../../components/CommonComp/Button/Button';
import Loader from '../../../components/CommonComp/Loader/Loader';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Privacy = () => {
  const token = useSelector((state) => state.user.user.token);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [loading, setLoading] = useState(true);

  const updatePrivacy = async () => {
    try {
      const response = await uptePrivacy({ data: privacyPolicy, token });
      toast.success("Privacy Updated")
    } catch (err) {
      toast.warn("Some error occured")
      console.log(err);
     
    }
  };

  const getPrivacy = async () => {
    try {
      const response = await getPrivacyPolicy({ token });
      setPrivacyPolicy(response.PrivacyPolicy);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPrivacy();
  }, []);

  const handlePrivacyContentChange = (content) => {
    setPrivacyPolicy(content);

  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="contentPrivacyPolicy" to="/content-management" className='All-color-breakcurm'>&nbsp;Content Management</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;Privacy Policy</span>
  ];
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
            </div>

      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: '40px' }}>
          {/* <TextEditor divStyle={{ width: '1069px', height: '520px', backgroundColor:'white' }}  inputFieldVisible={false}/> */}
          <Reactquil
            initialContent={privacyPolicy}
            onContentChange={handlePrivacyContentChange}
          />
        </div>
      )}
      <div className='about-btn'>
        <Button title='Save Changes' onClick={updatePrivacy} />
      </div>
    </div>
  );
};

export default Privacy;
