import React from "react";
import "./Verify.css";
import { useState } from "react";
import { useLocation, useNavigate,} from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import { handleOtp, resendOtpApi } from "../../Api";
import { toast } from "react-toastify";
import BaroomLogo from '../../components/CommonComp/Assests/Barooom 1.png';
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import NotAuthenticatedLayout from "../layouts/NotAuthenticatedLayout";
import { login } from "../../redux/userSlice";

const Verify = () => {

  // const user=useSelector((state)=>state.user.user);

    const location=useLocation();
  const dispatch=useDispatch();
    const email=location.state.email;
    const id=location.state.id;
    const keepLogged=location.state.keepLogged



  const [OTP, setOTP] = useState(NaN);
  // const [id,setId]=useState(null);

    

  const navigate = useNavigate();

  const handleVerifyandLogin = async() => {
    try{
      let type=1;
    const response= await handleOtp({otp:OTP, userId:id,type:type})
     
      
      setTimeout(() => {
        dispatch(login({...response.data.user,token:response.data.token,keepLogged:keepLogged}))
        navigate('/dashboard-page')
      }, 2000);
     

      toast.success("OTP Verified redirecting in 2 sec")
    }
    catch(err){
      toast.error("Invalid OTP")
      console.log("error",err);
    }
  };

  const handleResetOTP = async () => {
    try {
    
      let type=1;
      if (id) {
     
        const response = await resendOtpApi({ refId: id ,type:type});
        
        toast.success("OTP resent successfully");
      } else {
        toast.error("Failed to resend OTP. ID not found.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error.message);
      toast.error("Failed to resend OTP. Please try again.");
    }
  };

  return (

    // <AuthenticatedLayout>
     

    <NotAuthenticatedLayout>
        <div className="d-flex align-items-center justify-content-center min-vh-100 bckimg">
        <div className="d-flex flex-column align-items-center  px-5  pb-3 bg-white border border-5 border-warning rounded-3 custom-container-verify-page">
          <img src={BaroomLogo} alt="baroom-logo" className="custom-logo-verify-page" />
          
          <div  className="mt-3 code-verifications-text">Code Verification</div>
         
          <div className="code-to-Email">
                Code is sent to <b>{email}</b>
              </div>
          
          <div className="d-flex gap-3 justify-content-between mt-5 text-dark custom-otp-input-verify">
          <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false}  />
          </div>
          
          <div className="mt-4 text-center w-100 request-new-code-container">
          <div className="containerss">
                <div className="otp-and-newcode">Didn't receive any code?</div>
              
                <div className="new-code mt-2"  style={{cursor:'pointer',color:'#f33'}} onClick={()=>handleResetOTP()}>
                  <b>Request new code again.</b>
                </div>
              </div>
          </div>
      
          <button className="button w-100" onClick={()=>handleVerifyandLogin()}>
                 Verify & Login
               </button>
        </div>
      </div>

        



         </NotAuthenticatedLayout> 
  );
};

export default Verify;
