import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './GlobalSearch.css';

const GlobalSearch = ({ filteredComponents,onClose }) => {
  const navigate=useNavigate();
  const containerRef = useRef(null);

  const handleNavigate=(link)=>{
    navigate(link)
    onClose()
  }
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onClose();
    }
  };

  // Handle click outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);


  return (
   <div className="global-search-container" ref={containerRef}>
      <div className="global-search-suggestions">
        {filteredComponents.length > 0 ? (
          filteredComponents.map((item, index) =>
            item.values.map((dta) =>
              Object.keys(dta).map((key) => (
                <div
                  key={key}
                  className="suggestion-item"
                  onClick={() => handleNavigate(dta[key])}
                >
                  {key}
                </div>
              ))
            )
          )
        ) : (
          <div className="no-results text-center">No results found</div>
        )}
      </div>
    </div>
  );
};

export default GlobalSearch;
