import React, { useEffect, useState } from "react";
import Card2 from "../../components/CommonComp/Card2/Card2";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import { getCommission, updateCommission, updateReferral } from "../../Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Charge = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Charge Management</span>,
  ];

  const [commModal, setCommModal] = useState(false);
  const [refModal, setRefModal] = useState(false);
  const [commPerc, setCommPerc] = useState(0);
  const [refPrice, setRefPrice] = useState([]);

  const CommissionApiCall = async () => {
    try {
      const data = await getCommission({ token });
      setCommPerc(data.commission);
      const agaPrep = data.refer.map((ref) => ({
        refersNo: ref.refersNo,
        amount: ref.amount,
      }));
      setRefPrice(agaPrep);
      //   const prepData = data.refer.map((ref) => ref.amount);
      //   setRefPrice(agaPrep);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommissionUpdate = async () => {
    try {
      await updateCommission({ commission: commPerc, token });
      setCommModal(false);
      toast.success("Commission Percentage Updated");
    } catch (error) {
      console.log(error);
      toast.success(error.message);
    }
  };

  const handleReferralUpdate = async () => {
    try {
      await updateReferral({ refer: refPrice, token });
      setRefModal(false);
      toast.success("Referral Prices Updated");
    } catch (error) {
      console.log(error);
      toast.success(error.message);
    }
  };

  useEffect(() => {
    if (!commModal) {
      return;
    } else {
      // Api call
      CommissionApiCall();
    }
  }, [commModal]);

  useEffect(() => {
    if (!refModal) {
      setRefPrice([]);
      return;
    } else {
      // Api call
      CommissionApiCall();
    }
  }, [refModal]);

  return (
    <div>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={commModal}
        onClose={() => setCommModal(false)}>
        <>
          <div
            style={{
              width: "90%",
              maxWidth: "500px",
              height: "auto",
              background: "white",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "50px 25px",
            }}>
            <input
              value={commPerc}
              className="input-field"
              onChange={(e) =>
                setCommPerc(e.target.value.replace(/[a-zA-Z\W_]+/g, ""))
              }
            />
            <button
              className="crte-usr-btun"
              style={{
                padding: 20,
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                height: 63,
              }}
              onClick={handleCommissionUpdate}>
              Update Commission Percentage
            </button>
          </div>
        </>
      </Modal>

      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={refModal}
        onClose={() => setRefModal(false)}>
        <>
          <div
            style={{
              width: "90%",
              maxWidth: "500px",
              height: "auto",
              background: "white",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "50px 25px",
            }}>
            {refPrice.map((elem, ind) => (
              <div
                key={`RefDiv${ind}`}
                style={{
                  gap: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <div>
                  <label htmlFor={`${ind}ref`} style={{ marginRight: 10 }}>
                    Upto
                  </label>
                  <input
                    className="input-field"
                    style={{
                      width: "80px",
                    }}
                    id={`${ind}ref`}
                    value={elem.refersNo}
                    onChange={(e) => {
                      const x = [...refPrice];
                      x[ind].refersNo = e.target.value.replace(
                        /[a-zA-Z\W_]+/g,
                        ""
                      );
                      setRefPrice(x);
                    }}
                  />
                </div>

                <div>
                  <label htmlFor={`${ind}refamt`} style={{ marginRight: 10 }}>
                    Amount
                  </label>
                  <input
                    type="text"
                    id={`${ind}refamt`}
                    style={{
                      width: "80px",
                    }}
                    value={elem.amount}
                    className="input-field"
                    onChange={(e) => {
                      const x = [...refPrice];
                      x[ind].amount = e.target.value.replace(
                        /[a-zA-Z\W_]+/g,
                        ""
                      );
                      setRefPrice(x);
                    }}
                  />
                </div>
              </div>
            ))}
            <button
              style={{
                borderRadius: "50%",
                color: "#f33",
                fontSize: "40px",
                lineHeight: "30px",
                height: "50px",
                width: "50px",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                overflow: "hidden",
                background: "white",
                border: "2px solid #f33",
                margin: "40px 0 10px 0",
              }}
              onClick={() => {
                setRefPrice((prev) => [...prev, { refersNo: 0, amount: 0 }]);
              }}>
              <p style={{ transform: "translateY(12%)" }}>+</p>
            </button>
            <button
              className="crte-usr-btun"
              style={{
                padding: 20,
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                height: 63,
              }}
              onClick={handleReferralUpdate}>
              Update Refferal Prices
            </button>
          </div>
        </>
      </Modal>

      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      <div
        className="row card-row content-mang d-flex"
        style={{ maxWidth: "950px", marginTop: "40px" }}>
        <div className="col-lg-4  col-md-4 mb-3 mb-md-0">
          <div className="nav-link" onClick={() => setCommModal(true)}>
            <Card2 content="Commission" />
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="nav-link" onClick={() => setRefModal(true)}>
            <Card2 content="Referral" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charge;
