import React, { useState } from 'react';
import './ManagePlan.css';
import SearchBar2 from '../../../../../components/CommonComp/SearchBar2/SearchBar2';
import ReportTable from '../../../../../components/CommonComp/ReportTable/ReportTable';
import EyeLightIcon from '../../../../../components/CommonComp/Assests/iconamoon_eye-light.svg';
import DeleteIcon from '../../../../../components/CommonComp/Assests/fluent_delete-16-regular.svg';
import EditIcon from '../../../../../components/CommonComp/Assests/fluent_edit-16-regular.svg';
import { useNavigate } from 'react-router-dom';

const ManagePlan = () => {

  let navigate= useNavigate();
 
    const [plans, setPlans] = useState([
        {
            ID: 1,
            name: "Free",
            price: 50.00,
            status: "Active",
            discountPrice: 50.00,
            features: "Lorem ipsum dolor sit amet consectetur. Ut facilisi pretium non fermentum senectus id adipiscing quam. Mattis dolor ut ut cum. Eget congue vitae sed nisl purus. Dis penatibus porta blandit id. Orci ut cursus tempor convallis integer ut donec. Molestie aliquet leo sit orci sed pellentesque."
        },
        {
            ID: 2,
            name: "Premium",
            price: 100,
            status: "Active",
            discountPrice: 30.00,
            features: "lorem example"
        },
      
    ]);


    const handleAddPlan=()=>{
       navigate('/dashboard/establishment/subscription/manageyourplans/editSuscription')

    }

    const handleEdit = (plan) => {
        
        // console.log("Edit clicked for plan ID:", id);
        
        navigate(
            '/dashboard/establishment/subscription/manageyourplans/editSuscription',
            {state: { planData: plan }}
        )
        console.log(plan)

    };

    const handleDelete = (id) => {
      
        setPlans(plans.filter(plan => plan.ID !== id));
        
    };

    const hadnleView=(plan)=>{
      // alert("view is cliked")
      navigate(
        '/dashboard/establishment/subscription/manageyourplans/viewsuscription',
        {state: { planData: plan }}
        
    );
}

    const tableData = {
        headers: ["ID", "Plan Name", "Price", "Status", "Action"],
        rows: plans.map(plan => ([
            plan.ID,
            plan.name,
            '$'+plan.price+'/mo',
            plan.status,
            <div className="icons-edit-dlt">
             <div className='view-icon' onClick={() => hadnleView(plan)}><img src={EyeLightIcon} alt="View" style={{ height: '30px' ,cursor:'pointer'}}  /></div> 
             <div className='view-icon'onClick={() => handleEdit(plan)} ><img src={EditIcon} alt="Edit" style={{ height: '30px',cursor:'pointer' }}  /></div>
             <div className='view-icon' onClick={() => handleDelete(plan.ID)}><img src={DeleteIcon} alt="Delete" style={{ height: '30px',cursor:'pointer' }}  /></div>
            </div>
        ]))
    };

    return (
        <div>
            <div className="algn-top-bar">
                <div className="brd-crum">
                    Home &gt; Establishment &gt; Subscription &gt; <b>Manage Your Plans</b>
                </div>
            </div>

            <div className='searchbar-btnwrapper'>
                <SearchBar2 placeholder='Search Plan' />
                <button type='submit' className='add-subscription' onClick={handleAddPlan}>+&nbsp;Add Plan</button>
            </div>

            <div className='manageplans'>
                { plans.length > 0 ? (
                    <ReportTable tableData={tableData} />
                ) : (
                    <p>No plans available</p>
                )}
            </div>
        </div>
    );
}

export default ManagePlan;
