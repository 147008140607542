import React from 'react'
import './Card4.css'

import UsrIc from '../Assests/carbon_user-certification.svg';
import MonthIcon from '../Assests/iwwa_month.svg';
import ChkIcon from '../Assests/fontisto_checkbox-active.svg';

const Card4 = ({data2,data3,data4}) => {
  return (
   
    <div className="custom-card-section">
     <div className="responsive-card">
        <div className="custom-card-content">
            <img src={UsrIc} alt="User Icon" />
            <div className="custom-card-text">
                <p>{data2}</p>
                <p>Total User</p>
            </div>
        </div>
    </div>
    <div className="responsive-card">
        <div className="custom-card-content">
            <img src={MonthIcon} alt="Month Icon" />
            <div className="custom-card-text">
                <p>{data3}</p>
                <p>Total Users This Month</p>
            </div>
        </div>
    </div>
    <div className="responsive-card">
        <div className="custom-card-content">
            <img src={ChkIcon} alt="Check Icon" />
            <div className="custom-card-text">
                <p>{data4}</p>
                <p>Active Users Currently on App</p>
            </div>
        </div>
    </div>
</div>
    
  )
}

export default Card4
