import React, { useEffect, useState } from "react";
import "./PushNotification.css";
import test from "../../../components/CommonComp/Assests/sucess.png";
import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import { createGroup, getAllUser, getfechUser } from "../../../Api";
import { useSelector } from "react-redux";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import Loader from "../../../components/CommonComp/Loader/Loader";
import UserAvtar from "../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import { toast } from "react-toastify";
import { ensureString } from "../../../utils/inputFormatter";

const CreateNewGroupModal = ({ setActiveId }) => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [Users, setUsers] = useState([]);
  const [getFetchedUser, setFetchedUser] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [selectImg, setSelectImg] = useState(null);

  const handleChangeImg = (e) => {
    setSelectImg(e.target.files[0]);
  };

  const handleCheck = (id) => {
    // setUsers([...Users, id]);
    setUsers((prevUsers) =>
      prevUsers.includes(id)
        ? prevUsers.filter((userId) => userId !== id)
        : [...prevUsers, id]
    );
  };

  // creting group with gropuname,gropuimg,users

  const newGrop = async () => {
    if (!groupName || !selectImg) {
      toast.error("Group name and image both are required!");
      return;
    }
    if (Users.length <= 0 || !Users) {
      toast.error("Users List Empty, Select Users.");
      return;
    }
    try {
      const resp = await createGroup({ token, Users, groupName, selectImg });
      toast.success("Group created successfully!");
      setActiveId(3);
    } catch (error) {
      toast.error("Error creating group");
      console.log("error", error);
    }
  };

  const getAllUserToAddInGroup = async () => {
    try {
      const resp = await getfechUser({ token, activePage, searchQuery });
      setFetchedUser(resp.history);
      setActivePage(resp.page);
      setTotalPage(resp.total_page);
      setNext(resp.next);
      setPrev(resp.prev);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllUserToAddInGroup();
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    const timeout = setTimeout(getAllUserToAddInGroup, 500);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  return (
    <div style={{ width: "100%", marginTop: "-25px" }}>
      <div
        className="ps-2 w-25"
        style={{
          fontWeight: "700",
          fontSize: "24px",
          marginTop: "-62px",
          display: "inline",
        }}>
        Create Group
      </div>

      <div className="ms-2 mt-2 fs-4">
        <div className="search-container">
          <img src={SearchIcon} alt="search-icon" />
          <input
            type="search"
            className="specificUserSeacrh"
            placeholder="Search Users"
            value={searchQuery}
            onChange={(e) => {
              if (e.target.value.trim() !== "" || e.target.value === "") {
                let value = ensureString({ value: e.target.value });
                setSearchQuery(value);
              }
            }
          }
          />
        </div>

        <div className="input-group mt-3">
          <input
            type="text"
            className="form-control group-name-input"
            placeholder="Type Group name"
            value={groupName}
            // onChange={(e) => setGroupName(e.target.value)}
            onChange={(e) => {
              if (e.target.value.trim() !== "" || e.target.value === "") {
            let value = ensureString({ value: e.target.value });
         
              setGroupName(value);
            
          }
          }}
          />
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            className="form-control group-img-file"
            placeholder="Upload your group img"
            onChange={(e) => handleChangeImg(e)}
          />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {getFetchedUser.length === 0 ? (
              <p className="text-center">No users found.</p>
            ) : (
              <div className="form-check user-select-item mt-4">
                {getFetchedUser.map((item, indx) => (
                  <div className="select-user-forgroup" key={item._id}>
                    <input
                      className="form-check-input chkDesing"
                      type="checkbox"
                      checked={Users.indexOf(item._id) !== -1 ? true : false}
                      onChange={() => handleCheck(item._id)}
                      id={`user${indx}`}
                    />
                    <label
                      className="form-check-label d-flex align-items-center"
                      htmlFor={`user${indx}`}>
                      <div className="d-flex gap-1 ms-2">
                        <img
                          src={
                            item?.pic
                              ? `https://d28qcxi1juf339.cloudfront.net/${item?.pic}`
                              : UserAvtar
                          }
                          alt="User"
                          className="user-img"
                        />
                        <div className="user-details">
                          <div className="user-name">
                            {item?.name || "Name not avilable"}
                          </div>
                          <div className="user-email">
                            {item?.email || "Email not avilable"}
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <Pagination
        total_page={totalpage}
        next={next}
        prev={prev}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      <div className="d-flex justify-content-center">
        <button className="custom-button-now mb-4" onClick={() => newGrop()}>
          Create Group
        </button>
      </div>
    </div>
  );
};

export default CreateNewGroupModal;
