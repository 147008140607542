import React from "react";
import "./Header1.css";
import ElipsIcon from "../Assests/Ellipse 63.svg";
import { useSelector } from "react-redux";
const Header1 = () => {

  const user=useSelector((state)=>state.user.user);
  

  return (
    <div>
      <div className="header-design1">
        <hr className="hr-line my-3" />

        <div className="elips-wrap-contanr">
          <div className="row">
            <div className="col">
              <div className="welcome-text ps-5 d-none d-sm-block text-center text-md-start">
                <div className="cntr-item">
                <img src={user?.pic ? `https://d28qcxi1juf339.cloudfront.net/${user?.pic}` : ElipsIcon} alt="Admin-Image" className="elips-icon-2"/>
                  <span className="ms-4">
                    Welcome <b style={{color:"#f33"}}>{user?.name}</b>
                  </span>
                  <div className="welcome-kate"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header1;
