import React, { useEffect, useState } from "react";
import "./EstabProfile.css";
import CreateUserBtn from "../../../../../components/CommonComp/CreateUserBtn/CreateUserBtn";
import ProfileImg from "../../../../../components/CommonComp/ProfileImg/ProfileImg";
import EmailIcon from "../../../../../components/CommonComp/Assests/dashicons_email-alt.svg";
import EditIcon from "../../../../../components/CommonComp/Assests/material-symbols-light_edit-square-outline.svg";
import PhoneIcon from "../../../../../components/CommonComp/Assests/carbon_phone-voice.svg";
import UserIcon from "../../../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import RectAngleIcon from "../../../../../components/CommonComp/Assests/Rectangle 48.svg";
import Card5 from "../../../../../components/CommonComp/Card5/Card5";
import Button from "../../../../../components/CommonComp/Button/Button";
import ArrowIcon from "../../../../../components/CommonComp/Assests/solar_arrow-up-broken.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchDetailsEstb,
  fetchParticularEstabDetails,
  fetchUserEstb,
  handleResumeUser,
  handleSuspendUser,
} from "../../../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/CommonComp/Loader/Loader";
import { toast } from "react-toastify";

const EstabProfile = () => {
  const token = useSelector((state) => state.user.user.token);
  const particularID = useParams();
  const id = particularID.id;
  const navigate = useNavigate();

  const [activeEstablishment, setActiveEstablishment] = useState(null);
  const [data, setData] = useState([]);
  const [EstabDetails, setEstabDetails] = useState([]);
  const [EstblishmentData, setEstblishmentData] = useState({});
  const [loading, setLoading] = useState(true);

  const { name } = data;

  let firstName = "";
  let lastName = "";

  if (name) {
    const nameParts = name.trim().split(/\s+/); // Using regex to split by any whitespace
    firstName = nameParts[0] || "";
    lastName = nameParts.slice(1).join(" ") || "";
  }
  // Fetch user  details
  const fetchEstbUserDetails = async () => {
    try {
      const response = await fetchUserEstb({ uid: id, token });
      const userData = response[0];

      setData(userData);
      setEstabDetails(userData?.EstabDetails);

      if (userData?.EstabDetails.length > 0) {
        // setActiveEstablishment(userData?.EstabDetails);
        setActiveEstablishment(userData?.EstabDetails[0]); // Updated this line
        // fetchEstbalishmentDetails(userData?.EstabDetails._id);
        fetchEstbalishmentDetails(userData?.EstabDetails[0]._id);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  const handleReferl = (id) => {
    navigate("/dashboard/establishment/establishmentDetails/User/referal", {
      state: { referId: id, userName: firstName },
    });
  };

  // Fetch  establishment details
  const fetchEstbalishmentDetails = async (id) => {
    try {
      setLoading(true);
      const response = await fetchDetailsEstb({ eid: id, token });
      setEstblishmentData(response);
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstbUserDetails();
  }, []);

  // Handle establishment click
  const handleEstablishmentClick = (establishment) => {
    setActiveEstablishment(establishment);
    fetchEstbalishmentDetails(establishment._id);
  };

  const handleSuspendbar = async (uid) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to suspend this Establishment?"
      );
      if (!isConfirmed) {
        return;
      }
     const response= await handleSuspendUser({ uid, token });
      fetchEstbUserDetails();
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
      console.error("Error suspending Establishment:", error);
    }
  };
  const handleResume = async (uid) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to Resume this Establishment Account?"
      );
      if (!isConfirmed) {
        return;
      }
    const response = await handleResumeUser({ uid, token });
      fetchEstbUserDetails();
      toast.success(response.message);
    } catch (err) {
      toast.error(err.message);
      console.error("Error resuming user:", err);
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="EstablishmentSection"
      to="/dashboard-page/Establishment-section"
      className="All-color-breakcurm">
      &nbsp;Establishment
    </Link>,
    <Link
      key="EstablishmentDetails"
      to="/dashboard-page/Establishment-section/establishmnet-details"
      className="All-color-breakcurm">
      &nbsp;Establishment Details
    </Link>,
    <span style={{ fontWeight: "bolder" }}>
      &nbsp; {firstName || "User"} {lastName}
    </span>,
  ];

  return (
    <div className="containersss">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      <div class="estb-profile">
        <div class="row justify-content-start text-center">
          <div class="col-12  col-md-2 d-flex flex-column align-items-center mb-3">
            <img
              src={
                data?.pic
                  ? `https://d28qcxi1juf339.cloudfront.net/${data?.pic}`
                  : UserIcon
              }
              alt="User-Image"
              class="estb-det-img"
            />
            <div class="w-75">
              <p class="mt-2 estb-profile-name">{data?.name || ""}</p>
            </div>
            {/* referl */}

            <div className="arow-referl mt-1">
              <button
                className="referal-button"
                onClick={() => handleReferl(data._id)}>
                Referrals: {data.TotalReferrals}
                <img src={ArrowIcon} alt="arrow-icon" className="arrow-icon" />
              </button>
              {/* <Card5 content={data.TotalReferrals} content1='Referrals >' to='/dashboard/establishment/establishmentDetails/User/referal' /> */}
            </div>
          </div>

          <div className="col-11 col-xl-8 col-md-10 gx-5">
            <div className="edit-yourname mb-3">
              <p className="your-details mt-4 mt-md-0">Basic details</p>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <div className="input-container1 ps-3 d-flex align-items-center">
                  <div className="accnt-mangmnet-icons">
                    <img src={UserIcon} alt="User" className="same-icon-size" />
                  </div>
                  <input
                    type="text"
                    placeholder="First name"
                    className="ps-4 first-name comn-inpt-property input-width"
                    value={firstName || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <div className="input-container1 ps-3 d-flex align-items-center">
                  <div className="accnt-mangmnet-icons">
                    <img src={UserIcon} alt="User" className="same-icon-size" />
                  </div>
                  <input
                    type="text"
                    placeholder="Last name"
                    value={lastName || ""}
                    className="ps-4 last-name comn-inpt-property input-width"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <div className="input-container1 ps-3 d-flex align-items-center">
                  <div className="accnt-mangmnet-icons">
                    <img
                      src={PhoneIcon}
                      alt="Phone"
                      className="same-icon-size"
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    value={data.phoneNo || ""}
                    className="ps-4 phone-no comn-inpt-property input-width"
                    disabled
                  />
                </div>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <div className="input-container1 ps-3 d-flex align-items-center">
                  <div className="accnt-mangmnet-icons">
                    <img
                      src={EmailIcon}
                      alt="Email"
                      className="same-icon-size"
                    />
                  </div>
                  <input
                    type="email"
                    placeholder="Email ID"
                    value={data.email || ""}
                    className="ps-4 email-id comn-inpt-property input-width"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-8 col-xl-2 col-md-9 d-flex justify-content-center justify-content-md-start estb-more-option-btn">
            <div className="btn-group btn-option-estb">
              <button
                type="button"
                className="btn dropdown-toggle more-option-btn-estb"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                More Option
              </button>
              <ul className="dropdown-menu drp-dwn-border">
                {data.account_status === 3 && (
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleResume(data._id)}>
                      Resume User
                    </a>
                  </li>
                )}

                {(data.account_status === 1 ||
                  data.account_status === 5 ||
                  data.account_status === 0) && (
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleSuspendbar(data._id)}>
                      Suspend User
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="simple-line mt-3"></div>

      <div className="estb-all-btn">
        {EstabDetails.map((establishment, index) => (
          <>
            <button
              key={index}
              type="button"
              className={`estb1-btn ${
                establishment === activeEstablishment ? "active" : ""
              }`}
              onClick={() => handleEstablishmentClick(establishment)}>
              {establishment?.establishmentName || "No data"}
            </button>
          </>
        ))}
      </div>

      {loading ? (
        <Loader />
      ) : activeEstablishment && EstblishmentData ? (
        <div>
          <div class="card mt-5 img-container estblishmnet-imges-design">
            {activeEstablishment &&
            EstblishmentData?.pic &&
            EstblishmentData.pic.length > 0 ? (
              <img
                src={`https://d28qcxi1juf339.cloudfront.net/${EstblishmentData?.pic[0]}`}
                alt="Establishment-Image"
                style={{ height: "250px", borderRadius: "10px" }}
              />
            ) : (
              <img src={RectAngleIcon} alt="recthnge img" />
            )}
          </div>
          {/* 
            <div className='rectngle-img'>
            
            </div> */}

          <div className="estb-crds">
            <Card5
              content={EstblishmentData?.overallRating || 0}
              content1="Total Review >"
              content2={`${EstblishmentData.totalReviews || 0} reviews`}
              to={`/dashboard/establishment/establishmentDetails/User/establishmentreview/${activeEstablishment._id}`}
            />
            <Card5
              content={EstblishmentData?.totalHired || 0}
              content1="Candidates Hired>"
              to={`/dashboard/establishment/establishmentDetails/User/establishmentcandidatehired/${activeEstablishment._id}`}
            />
          </div>

          <div className="estb-warpper">
            <div className="estb-txt1">
              <p>
                Establishment Name-{" "}
                {EstblishmentData?.establishmentName || "N/A"}
              </p>
              <p>
                {EstblishmentData.location
                  ? `${EstblishmentData?.location?.streetName}, ${EstblishmentData?.location?.city}, ${EstblishmentData?.location?.state}, ${EstblishmentData?.location?.postalCode}`
                  : "Location not available"}
              </p>
            </div>
            <div className="estb-txt">
              <p>About the Establishment</p>
              <p>{EstblishmentData?.discription || "N/A"}</p>
            </div>
            <div className="estb-txt">
              <p>Type of cuisine/service</p>
              <p>{EstblishmentData?.serviceType || "N/A"}</p>
            </div>
            <div className="estb-txt">
              <p>Dress Code (Optional)</p>
              <p>{EstblishmentData?.dressCode || "N/A"}</p>
            </div>
            <div className="estb-txt">
              <p>POS Software (Optional)</p>
              <p>{EstblishmentData?.posSystem || "N/A"}</p>
            </div>
            <div className="estb-txt">
              <p>Customer flow</p>
              <p>{EstblishmentData?.customerFlow || "N/A"}</p>
            </div>
            <div className="estb-txt">
              <p>Number of years in business</p>
              <p>{EstblishmentData?.yearsOfExperience || "N/A"}</p>
            </div>
            <div className="estb-txt">
              <p>Establishment website link</p>
              <p>{EstblishmentData?.websiteLink || "N/A"}</p>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center fs-4">Establishment Details not Available</p>
      )}
             
             {data.account_status !=3 && (
                   <div className="estb-profile-btn mb-4">
                    <Button
                      title1="Suspicious Account"
                      onClick={() => handleSuspendbar(data._id)}
                    />
                </div>
             )}

{data.account_status === 3 && (
                <div className="estb-profile-btn mb-4">
                    <Button
                      title1="Resume Account"
                      onClick={() => handleResume(data._id)}
                    />
                </div>
)}
      </div>
  );
};

export default EstabProfile;
