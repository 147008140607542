// withMainLayout.js
import React from 'react';
import Main from './components/CommonComp/Main/Main';

const withMainLayout = (Component) => {
  return (props) => (
    <Main>
      <Component {...props} />
    </Main>
  );
};

export default withMainLayout;
