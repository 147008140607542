import React, { useState, useEffect } from 'react';
import SearchBar1 from '../../../../components/CommonComp/SearchBar1/SearchBar1';
import ReportTable from '../../../../components/CommonComp/ReportTable/ReportTable';
import { reportbar } from '../../../../Api';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/CommonComp/Loader/Loader';
import Pagination from '../../../../components/CommonComp/Pagination/Pagination';
import { Link } from 'react-router-dom';


const Report = () => {
  const token = useSelector((state) => state.user.user.token);

  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [myTimeout, setMyTimeout] = useState();

  const fetchBarReportData = async () => {
    try {
      const response = await reportbar({ searchQuery,activePage, role: 1, token});
      setReportData(response?.history);
      setActivePage(response?.page);
      setTotalPage(response?.total_page);
      setNext(response?.next);
      setPrev(response?.prev);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching report data:', error);
    }
  };


  useEffect(() => {
    fetchBarReportData();
  }, [activePage]);

    useEffect(()=>{
    clearTimeout(myTimeout)

    setMyTimeout(setTimeout(fetchBarReportData, 500))

    },[searchQuery])



  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery); 
    setActivePage(1);
  }
  
  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="Bartender" to="/dashboard-page/bartender-section" className='All-color-breakcurm'>&nbsp;Bartender</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;
    Reports
  </span>
  ];

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 
      <div className='comn-serc' style={{ marginTop: "77px" }}>
        <SearchBar1 data="Search by Name" onSearch={handleSearch} />
      </div>
      <div className='comnt-tb' style={{ marginTop: '67px' }}>
        {loading ? (
          <p><Loader /></p>
        ) : (
          <ReportTable filteredData={reportData} />
        )}
        <Pagination total_page={totalPage} next={next} prev={prev} activePage={activePage} setActivePage={setActivePage} />
      </div>
    </div>
  );
}

export default Report;
