import React, { useEffect, useState } from 'react';
import './ReferalPage.css';
import Card6 from '../../../../../components/CommonComp/Card6ndTable/Card6';
import { Link, useParams } from 'react-router-dom';
import { totlReferals } from '../../../../../Api';
import { useSelector } from 'react-redux';
import Loader from '../../../../../components/CommonComp/Loader/Loader';
import Pagination from '../../../../../components/CommonComp/Pagination/Pagination';

const ReferalPage = () => {
  const token = useSelector((state) => state.user.user.token);
  const { id } = useParams();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true); 

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage]=useState(1);
  
  const [userName,setUserName]=useState('')

  const [srno,setSrNo]=useState([]);

  const fetchData = async () => {
    try {
      const response = await totlReferals({ uid: id, token,activePage});
      setUserName(response.UserName);
  
      if (response && response.history.length > 0) {
        setRowData(response.history);

      } else {
        setRowData([]); 
      }

      
      setActivePage(response.page)
      setTotalPage(response.total_page)
      setNext(response.next);
      setPrev(response.prev);
      const updatedSrNo = Array.from({ length: response.history.length }, (_, index) => (activePage - 1) * 12 + index + 1);
      setSrNo(updatedSrNo);
       setLoading(false); 
    } catch (error) {
      console.log("Error fetching referral data:", error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [activePage]);

  const tableData = {
    headers: ['SR NO', 'Name', 'Phone Number','Date'],
    rows: rowData.map((referl, index) => ([
      `${srno[index]}`,
      referl?.name,
      referl?.phoneNo,
      new Date(referl?.createdAt).toLocaleDateString()
    ]))
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="Bartender" to="/dashboard-page/bartender-section" className='All-color-breakcurm'>&nbsp;Bartender</Link>,
    <Link key="BartenderDetails" to="/dashboard-page/bartender-section/bartender-details" className='All-color-breakcurm'>&nbsp;Bartender Details</Link>,
    <Link key="BartenderName" to={`/bartender/bartenderDetails/User/profile/${id}`}className='All-color-breakcurm'>&nbsp;{userName ||"User"}</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;
    Referrals
  </span>
  ];

  return (
    <>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 
      <div className='referl-table'>
        {loading ? (
          <p><Loader/></p>
        ): (
          <>
          <Card6 data={rowData.length} data1='Referrals' tableData={tableData} />
            <Pagination total_page={totalpage} next={next} prev={prev} activePage={activePage} setActivePage={setActivePage}/>

          </>        
        )}
      </div>
    </>
  );
};

export default ReferalPage;
