import React from 'react'
import './Button1.css';
const Button1 = ({data,onClick}) => {
  return (
    <div onClick={onClick}>
       <button type="submit" className="add-faq">{data}</button>
    </div>
  )
}

export default Button1
