import React from 'react';
import './GigsDetailinfo1.css';

const GigsDetailinfo1 = ({ data }) => {
  // Destructure with default values
  const {
    title ,
    gig ,
    time ,
    pay ,
    yearofexp ,
    skills,
    dueResp,
    benifits
  } = data;

  return (
    <div>
      <div className='gig-detail-1 card-body me-4 mb-4'>
        <div className='job-title common-detail'>
          <p>Job title</p>
          <p>{title||"N/A"}</p>
        </div>
        <div className='About-the-Gig common-detail'>
          <p>About the Gig</p>
          <p style={{wordWrap:"break-word"}}>{gig||"N/A"}</p>
        </div>
        <div className='shift-time common-detail'>
          <p>Shift Time</p>
          <p>{time||"N/A"}</p>
        </div>
        <div className='yoe-title common-detail'>
          <p>Years of experience</p>
          <p>{yearofexp||"N/A"}</p>
        </div>
        <div className='pay common-detail'>
          <p>Pay</p>
          <p>{pay||"N/A"}</p>
        </div>
        <div className='skills-req'>
          <p>Skills Required</p>
          {skills.map((skill, index) => (
            <p style={{margin:'0px'}}key={index}>{skill}</p>
          ))}
        </div>
        <div className='duties-respons common-detail mt-4'>
          <p>Duties & Responsibilities</p>
          {dueResp.length>0 ? (
          <ul>
          {dueResp.map((resp, index) => (
            <li key={index}>{resp}</li>
          ))}
      </ul>
          ):(
            <p>N/A</p>
          )}
         
        </div>
       
        {benifits && (
          <div className='benefits common-detail' >
            <p>Benefits</p>
            {benifits.length >0 ?(
              <ul style={{wordWrap:"break-word"}}>
              {benifits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
            ):(
              <p>N/A</p>
            )}
            
          </div>
        )}
      </div>
    </div>
  );
};

export default GigsDetailinfo1;
