import React, { useEffect, useState } from 'react';
import './Gigs.css';
import Card5 from '../../../../../components/CommonComp/Card5/Card5';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { totlGigs } from '../../../../../Api';
import { useSelector } from 'react-redux';
import Loader from '../../../../../components/CommonComp/Loader/Loader';
import Pagination from '../../../../../components/CommonComp/Pagination/Pagination';

const Gigs = () => {
  const token = useSelector((state) => state.user.user.token);

  const { id } = useParams();
  const navigate = useNavigate();
  const [gigsDetil, setGigsDetil] = useState([]);
  const [loading, setLoading] = useState(true);
  
  
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage]=useState(1);

  const [userName,setUserName]=useState('')


  const fetchGigs = async () => {
    try {
      const response = await totlGigs({ uid: id, token ,activePage });
      setGigsDetil(response?.history);
      setUserName(response.UserName)
      setActivePage(response?.page)
      setTotalPage(response?.total_page)
      setNext(response?.next);
      setPrev(response?.prev);

      setLoading(false); 
    } catch (error) {
      console.log("Error fetching gigs:", error);
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchGigs();
  }, [activePage]);

  const headers = ["Establishment Name", "Job Title", "Shift Time", "Pay"];

  const cardStyle = {
    width: '300px',
    height: '126px',
    cursor: "default"
  };

  const handleEstablishmentClick = (uid, gig) => {
    navigate(`/dashboard/bartender/bartenderdetails/johndeo/gigsattende/abcresturant`,
      { state: { establishmentData: gig, userName: userName,} }
    );
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="Bartender" to="/dashboard-page/bartender-section" className='All-color-breakcurm'>&nbsp;Bartender</Link>,
    <Link key="BartenderDetails" to="/dashboard-page/bartender-section/bartender-details" className='All-color-breakcurm'>&nbsp;Bartender Details</Link>,
    <Link key="BartenderProfile" to={`/bartender/bartenderDetails/User/profile/${id}`}className='All-color-breakcurm'>&nbsp;{userName ||"User"}</Link>,
   <span style={{fontWeight:'bolder'}}>&nbsp;
   Gigs Attended
  </span>
  ];

  return (
    <div>
     <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 

      <div className='gigs-crd'>
        <Card5 content={gigsDetil?.length} content1='Total Gigs Attended' style={cardStyle} />
      </div>

      <div className='gigs-app-text'>Below is the list of gigs attended by {userName||"User"}</div>

      <div className='gigs-tbl'>
        {loading ? (
          <p><Loader /></p>
        ) : (
          <>
          <div className="table-responsive2 me-5 table-responsive">
            <table className="table table-responsive">
              <thead style={{ textAlign: 'left', backgroundColor: 'transparent' }}>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} scope="col" className="table-heading2">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody className='td-design2 table-responsive'>
                {gigsDetil.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length} > Gigs not available at this moment</td>
                  </tr>
                ) : (
                  gigsDetil.map((gig, index) => (
               
                    <tr key={index}>
                      <td>
                      {gig?.EstablishemtDetails?.establishmentName ? (
                          <a
                            className="establishment-link"
                            onClick={() => handleEstablishmentClick(gig.uid, gig)}
                            style={{ cursor: 'pointer', color: 'inherit' }}
                          >
                            {gig?.EstablishemtDetails?.establishmentName}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="address-cell">{gig?.jobTitle||"N/A"}
                        
                      </td>
                      <td>{gig?.shiftSTime} - {gig?.shiftETime}</td>
                      <td>{"$" + gig?.payHourly + "/hour"||"N/A"}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
           <Pagination  total_page={totalpage} next={next} prev={prev} activePage={activePage} setActivePage={setActivePage} />
           </>
        )}
      </div>
    </div>
  );
};


export default Gigs;
