// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./components/Signup/Signup";
import Verify from "./components/OtpPage/Verify";
import AccMangmnt from "./Pages/AccountManagement/AccMangmnt";
import AdminMngemnt from "./Pages/AdminManagement/AdminMngemnt";
import Setting from "./Pages/Settings/Setting";
import Home from "./components/CommonComp/Home/Home";
import withMainLayout from "./withMainLayout";
// import YourProfile from './Pages/Settings/YourProfile/YourProfile';
import PushNotification from "./Pages/Settings/PushNotification/PushNotification";
import UpdateProfile from "./Pages/AdminManagement/UpdateProfile/UpdateProfile";
// import EstablishmentDetails from './Pages/Dashboard/Establishment/EstablishMentsection';
import CommisionDetails from "./Pages/Dashboard/Commision/CommisionDetails";
import BartenderSec from "./Pages/Dashboard/BartenderSection/BartenderSec/BartenderSec";
import BartenderDetails from "./Pages/Dashboard/BartenderSection/BartenderSec/BartenderDetails/BartenderDetails";
import TicketMang from "./Pages/Dashboard/BartenderSection/TicketMang/TicketMang";
// import ContentMngItems from './Pages/Dashboard/BartenderSection/ContentMng/ContentMngItems/ContentMngItems';
import Report from "./Pages/Dashboard/BartenderSection/Reports/Report";
import EstablishMentsection from "./Pages/Dashboard/Establishment/EstablishmentSection/EstablishMentsection";
import Ticket from "./Pages/Dashboard/Establishment/TicketMang/Ticket";
// import ContentMngItem from './Pages/Dashboard/Establishment/ContentMng/ContentMngItem/ContentMngItem';
import ReportEst from "./Pages/Dashboard/Establishment/Reports/ReportEst";
import Suscription from "./Pages/Dashboard/Establishment/Suscription/Suscription";
import Establishmentdetails from "./Pages/Dashboard/Establishment/EstablishmentSection/EstablishmentDetails/Establishmentdetails";
import BartenderProfile from "./Pages/Dashboard/BartenderSection/BartenderSec/BartenderProfile/BartenderProfile";
import CreateUserPage from "./Pages/Dashboard/BartenderSection/BartenderSec/CreateUserPage/CreateUserPage";
import EarningsPage from "./Pages/Dashboard/BartenderSection/BartenderSec/EarningsPage/EarningsPage";
import ReferalPage from "./Pages/Dashboard/BartenderSection/BartenderSec/ReferalPage/ReferalPage";
import ReviewPage from "./Pages/Dashboard/BartenderSection/BartenderSec/ReviewPage/ReviewPage";
// import Referal from './Pages/Dashboard/BartenderSection/ContentMng/Referal/Referal';
import FAQ from "./Pages/ContentManagement/Faq/FAQ";
import AboutSection from "./Pages/ContentManagement/AboutSec/AboutSection";
import Termsandcon from "./Pages/ContentManagement/TermsndCond/Termsandcon";
import Privacy from "./Pages/ContentManagement/Privacy/Privacy";
import CreateuserPage from "./Pages/Dashboard/Establishment/EstablishmentSection/CreateUserPage/CreateuserPage";
import EstabProfile from "./Pages/Dashboard/Establishment/EstablishmentSection/Estab-Profile/EstabProfile";
// import Faq from './Pages/Dashboard/Establishment/ContentMng/FAQ/Faq';
// import Aboutsec from './Pages/Dashboard/Establishment/ContentMng/AboutSection/Aboutsec';
// import TermsCon from './Pages/Dashboard/Establishment/ContentMng/Terms&conditon/TermsCon';
// import Privacy1 from './Pages/Dashboard/Establishment/ContentMng/Privacy/Privacy1';
// import Referal1 from './Pages/Dashboard/Establishment/ContentMng/Referal/Referal1';
import ManagePlan from "./Pages/Dashboard/Establishment/Suscription/ManagePlns/ManagePlan";
import ViewSusciption from "./Pages/Dashboard/Establishment/Suscription/ViewSuscriptionPage/ViewSusciption";
import Suscript from "./components/CommonComp/Suscript/Suscript";
import Gigs from "./Pages/Dashboard/BartenderSection/BartenderSec/Gigs/Gigs";
import AbcRes from "./components/CommonComp/ABCrestuarnt/AbcRes";
import EstbReview from "./Pages/Dashboard/Establishment/EstablishmentSection/Estab-Profile/EstbReview/EstbReview";
import CandidateHired from "./Pages/Dashboard/Establishment/EstablishmentSection/Estab-Profile/CandidateHired/CandidateHired";
import EstbReferal from "./Pages/Dashboard/Establishment/EstablishmentSection/Estab-Profile/EstbReferal/EstbReferal";
import CandidateJob from "./Pages/Dashboard/Establishment/EstablishmentSection/Estab-Profile/CandidateHired/Candidatejob/CandidateJob";
import CandidateProfile from "./Pages/Dashboard/Establishment/EstablishmentSection/Estab-Profile/CandidateHired/CandidateProfile/CandidateProfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Content from "./Pages/ContentManagement/Content";
// import ContentMngItem from './Pages/Dashboard/Establishment/ContentMng/ContentMngItem';
import Payment from "./Pages/Dashboard/BartenderSection/BartenderSec/Payment/Payment";
import ForgetPass from "./components/Forget/ForgetPass";
import Charge from "./Pages/ChargeManagement/Charge";
import ReportsContent from "./Pages/ContentManagement/Reports/ReportsContent";
import MarkNoShowBar from "./Pages/Dashboard/Establishment/MarkNoShow/MarkNoShowBar";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Signup />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgetPass" element={<ForgetPass />} />

          <Route path="/dashboard-page/*" element={withMainLayout(Home)()} />

          {/* bartendders */}
          <Route
            path="/dashboard-page/bartender-section"
            element={withMainLayout(BartenderSec)()}
          />

          <Route
            path="/dashboard-page/bartender-section/bartender-details"
            element={withMainLayout(BartenderDetails)()}
          />
          <Route
            path="/dashboard-page/bartender-section/ticket-management"
            element={withMainLayout(TicketMang)()}
          />
          {/* <Route path='/dashboard-page/bartender-section/content-management' element={withMainLayout(ContentMngItems)()}/> */}
          <Route
            path="/dashboard-page/bartender-section/report-item"
            element={withMainLayout(Report)()}
          />

          {/* establish ments */}
          <Route
            path="/dashboard-page/Establishment-section"
            element={withMainLayout(EstablishMentsection)()}
          />
          <Route
            path="/dashboard-page/Establishment-section/establishmnet-details"
            element={withMainLayout(Establishmentdetails)()}
          />
          <Route
            path="/dashboard-page/Establishment-section/ticket-management"
            element={withMainLayout(Ticket)()}
          />
          {/* <Route path='/dashboard-page/Establishment-section/content-management' element={withMainLayout(ContentMngItem)()}/> */}
          <Route
            path="/dashboard-page/Establishment-section/report-item"
            element={withMainLayout(ReportEst)()}
          />
          <Route
            path="/dashboard-page/Establishment-section/suscription-item"
            element={withMainLayout(Suscription)()}
          />
          <Route
            path="/dashboard-page/Establishment-section/mark-no-show"
            element={withMainLayout(MarkNoShowBar)()}
          />

          {/* suscriptionmamnegt internal routing */}
          <Route
            path="/dashboard/Establishment/SubscriptionManagement/ManageYourPlans"
            element={withMainLayout(ManagePlan)()}
          />
          <Route
            path="/dashboard/establishment/subscription/manageyourplans/viewsuscription"
            element={withMainLayout(ViewSusciption)()}
          />

          {/* internl roting of estb profile like referl,candidate hired, reveiw */}

          <Route
            path="/dashboard/establishment/establishmentDetails/User/establishmentreview/:id"
            element={withMainLayout(EstbReview)()}
          />
          <Route
            path="/dashboard/establishment/establishmentDetails/User/establishmentcandidatehired/:id"
            element={withMainLayout(CandidateHired)()}
          />
          <Route
            path="/dashboard/establishment/establishmentDetails/User/referal"
            element={withMainLayout(EstbReferal)()}
          />

          <Route
            path="/jobposting/:id"
            element={withMainLayout(CandidateJob)()}
          />
          <Route
            path="/candidateProfile/:id"
            element={withMainLayout(CandidateProfile)()}
          />

          {/* commison */}

          <Route
            path="/dashboard-page/Commission-section"
            element={withMainLayout(CommisionDetails)()}
          />

          {/* account */}

          <Route
            path="/account-management"
            element={withMainLayout(AccMangmnt)()}
          />
          <Route
            path="/content-management"
            element={withMainLayout(Content)()}
          />
          <Route
            path="/payment-management"
            element={withMainLayout(CommisionDetails)()}
          />
          <Route path="/charge-management" element={withMainLayout(Charge)()} />

          {/* admin */}

          <Route
            path="/admin-management/*"
            element={withMainLayout(AdminMngemnt)()}
          />
          <Route
            path="/admin-management/update-profile"
            element={withMainLayout(UpdateProfile)()}
          />

          {/* john profile */}
          <Route
            path="/dashboard-page/bartender-section/bartender-details/paymentdetails/:id"
            element={withMainLayout(Payment)()}
          />
          <Route
            path="/bartender/bartenderDetails/User/profile/:id"
            element={withMainLayout(BartenderProfile)()}
          />
          <Route
            path="/bartender/bartenderDetails/CreateUser"
            element={withMainLayout(CreateUserPage)()}
          />
          <Route
            path="/bartender/bartenderDetails/User/earnings/:id"
            element={withMainLayout(EarningsPage)()}
          />
          <Route
            path="/bartender/bartenderDetails/User/review/:id"
            element={withMainLayout(ReviewPage)()}
          />
          <Route
            path="/bartender/bartenderDetails/User/referal/:id"
            element={withMainLayout(ReferalPage)()}
          />
          {/* gigs left */}
          <Route
            path="/bartender/bartenderDetails/User/gigs/:id"
            element={withMainLayout(Gigs)()}
          />

          {/* {inside  gigs table data route } */}

          <Route
            path="/dashboard/bartender/bartenderdetails/johndeo/gigsattende/abcresturant"
            element={withMainLayout(AbcRes)()}
          />

          {/* bartender faq,privacy,and other routes add /dashboard to strt of link */}
          <Route
            path="/content-management/FAQ"
            element={withMainLayout(FAQ)()}
          />
          <Route
            path="/content-management/About"
            element={withMainLayout(AboutSection)()}
          />
          <Route
            path="/content-management/Terms&Condition"
            element={withMainLayout(Termsandcon)()}
          />
          <Route
            path="/content-management/privacyPolicy"
            element={withMainLayout(Privacy)()}
          />
          <Route
            path="/content-management/reprotcontent"
            element={withMainLayout(ReportsContent)()}
          />
          {/* <Route path='/contentmanagement/Referrals' element={withMainLayout(Referal)()}/> */}

          {/* establishmnet faq, privancy,referal */}

          {/* <Route path='/dashboard/Establishment/contentManagement/FAQ' element={withMainLayout(Faq)()}/>
          <Route path='/dashboard/Establishment/contentManagement/aboutsection' element={withMainLayout(Aboutsec)()}/>
          <Route path='/dashboard/Establishment/contentManagement/termsandcondition' element={withMainLayout(TermsCon)()}/>
          <Route path='/dashboard/Establishment/contentManagement/privacypolicy' element={withMainLayout(Privacy1)()}/>
          <Route path='/dashboard/Establishment/contentManagement/referrals' element={withMainLayout(Referal1)()}/> */}

          <Route
            path="/dashboard/establishment/subscription/manageyourplans/editSuscription"
            element={withMainLayout(Suscript)()}
          />

          {/* seting */}

          <Route path="/settings/*" element={withMainLayout(Setting)()} />
          <Route
            path="/settings/push-notification"
            element={withMainLayout(PushNotification)()}
          />
          {/* <Route path="/settings/your-profile" element={withMainLayout(YourProfile)()} /> */}

          <Route
            path="/estb/estbdetails/userprofile"
            element={withMainLayout(CreateuserPage)()}
          />

          <Route
            path="/estb/estbdetails/profile/:id"
            element={withMainLayout(EstabProfile)()}
          />

          {/* <Route path='/establishment/establishmentDetails/User/establishment1review' element={withMainLayout(Tabe)}/> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
