import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { termAndCondition,updteTemsCondition } from '../../../Api'; 
import TextEditor from '../../../components/CommonComp/TextEditor/TextEditor';
import Button from '../../../components/CommonComp/Button/Button';
import Loader from '../../../components/CommonComp/Loader/Loader';
import Reactquil from '../../../components/CommonComp/Reactquill/Reactquil';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Termsandcon = () => {

  const token = useSelector((state) => state.user.user.token);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [loading, setLoading] = useState(true);


// uptdet terms 
  const updateTerms = async () => {
    try {
      const response = await updteTemsCondition({ data: termsAndConditions, token });
      toast.success("Terms and Conditions Updated")
     
    } catch (err) {
      toast.warn("Some error occured")
      console.log(err);
      
    }
  };

  // geterms
  const getTerms = async () => {
    try {
      const response = await termAndCondition({ token });
      setTermsAndConditions(response.TermsCondition);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  const handleTermsContentChange = (content) => {
    setTermsAndConditions(content);
  
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="contentTermsandCondition" to="/content-management" className='All-color-breakcurm'>&nbsp;Content Management</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;Terms & Condition</span>
  ];

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
            </div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: '40px' }}>
          {/* <TextEditor
            divStyle={{ width: '1069px', height: '520px', backgroundColor: 'white' }}
            inputFieldVisible={false}
            onAnswerChange={handleTermsContentChange}
            value={termsAndConditions}
          /> */}
          <Reactquil
            initialContent={termsAndConditions}
            onContentChange={handleTermsContentChange}
          />
        </div>
      )}
      <div className='about-btn'>
        <Button title='Save Changes' onClick={updateTerms} />
      </div>
    </div>
  );
};

export default Termsandcon;
