import React from 'react'
import './GigsDetailinfo2.css'
const GigsDetailinfo2 = ({data}) => {

    const {aboutEstab,service,dressCode,posSoftware,custormFlow,businnesYer,webLink}=data
  return (
    <div>
                <div className='gig-detail-2 card-body me-4 mb-4'>

                    <div className='about-estbalishmnet same-design-all'>
                        <p>About the Establishment</p>
                        <p>{aboutEstab||"N/A"}
                        </p>
                    </div>

                    <div className='cuisine-service same-design-all'>
                        <p>Type of cuisine/service</p>
                        <p>{service||"N/A"}</p>
                    </div>

                    <div className='Dress-Code same-design-all'>
                        <p>Dress Code (Optional)</p>
                        <p>{dressCode||"N/A"}</p>
                    </div>

                    <div className='POS-Software same-design-all'>
                        <p>POS Software (Optional)</p>
                        <p>{posSoftware||"N/A"}</p>
                    </div>

                    <div className='Customer-flow same-design-all'>
                        <p>Customer flow</p>
                        <p>{custormFlow||"N/A"}</p>
                    </div>

                    <div className='Number-of-yer same-design-all'>
                        <p>Number of years in business</p>
                        <p>{businnesYer||"N/A"}</p>
                    </div>

                    <div className='web-link same-design-all'>
                        <p>Establishment Website Link</p>
                        <p>{webLink||"N/A"}</p>
                    </div>

                </div>

    </div>
  )
}

export default GigsDetailinfo2
