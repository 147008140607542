import React, { useState, useEffect, useRef } from "react";
import "./CommisionDet.css";

import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import MultiIcon from "../../../components/CommonComp/Assests/mi_filter.svg";
import { ensureString } from "../../../utils/inputFormatter";

const SearchBar2 = ({ placeholder = "Search by Bartender name", onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [payStatus, setPayStatus] = useState("");

  // const dropdownRef = useRef(null);

  const handleInputChange = (value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const filterData = (e) => {
    setPayStatus(e.target.value);
    onSearch(searchQuery, e.target.value);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  useEffect(()=>{
    handleSearch()
  },[])
  // const handleDropdownToggle = () => {
  //   setShowDropdown(!showDropdown);
  // };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowDropdown(false);
  //   }
  // };

  // useEffect(() => {
  //   handleSearch();
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  return (
    <>
      <div className="custom-search-input-container-3">
        <div className="custom-search-icon-3">
          <img
            src={SearchIcon}
            alt="Search Icon"
            className="custom-icon-size-3"
          />
        </div>
        <input
          type="search"
          placeholder={placeholder}
          className="custom-search-input-3 me-4"
          value={searchQuery}
          // onChange={handleInputChange}
          onChange={(e) => {
            if (e.target.value.trim() !== "" || e.target.value === "") {
              let value = ensureString({ value: e.target.value });
              handleInputChange(value);
            }
          }}
        />
        <div className="custom-multi-select-icon-3">
          <select
            id="selectField"
            className="form-select custom-select"
            style={{
              borderRadius: "50px",
              width: "95px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              marginLeft: "-10px",
            }}
            value={payStatus}
            onChange={(e) => filterData(e)}>
            <option value="">All</option>
            <option value="NotProcessed">Not Processed</option>
            <option value="ToSettle">To be settled</option>
            <option value="Settled">Settled</option>
            <option value="Failed">Failed</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default SearchBar2;
