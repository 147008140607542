import React from 'react'
import './GigsDetailinfo3.css';
const GigsDetailinfo3 = ({data}) => {

  const {benifits}=data
  return (
    <div>
      <div className='benfits-contnr card-body me-3'>
            <div className='benfits'>
                <p>Benefits</p>

                {benifits.length >0 ?(
                    <ul>
                  
                    {benifits.map((benfits, index) => (
                        <li key={index}>{benfits}</li>
                      ))}
                      </ul>
                  ):(
                      <p style={{color:'#919191'}}>N/A</p>
                  )}
                
            </div>
      </div>
    </div>
  )
}

export default GigsDetailinfo3
