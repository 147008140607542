import React, { useEffect, useState } from "react";
import OTPInput from 'otp-input-react';
import BaroomLogo from '../../components/CommonComp/Assests/Barooom 1.png';

import '../ForgetPass/code.css'; // Ensure you have this CSS file for custom styles

export default function CodeVerifyPass(props) {
  const [otp, setOtp] = useState(""); // State to hold OTP values
  const [error, setError] = useState(props.error); // State to hold error message

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  // Function to handle OTP verification
  const veri = () => {
    props.verifyOtp(otp);
  }

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 imgeset">
      <div className="d-flex flex-column align-items-center px-5 pt-5 pb-3 bg-white border border-5 border-warning rounded-3 custom-container-verify">
        <img src={BaroomLogo} alt="baroom-logo" className="mb-4 custom-logo-verify" />
        {/* <div className="mt-4">Code Verification</div> */}
        <div className="code-verify-text" style={{fontWeight:"700",fontSize:'35px'}}>Code Verification</div>
        <div className="mt-2 h5 text-center text-muted sent-mails">
          Verification code has been sent to your mail.
        </div>
        <div className="d-flex gap-3 justify-content-between mt-5 text-dark custom-otp-input-verify">
          <OTPInput
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            renderSeparator={<span></span>}
            renderInput={(props) => (
              <input 
                {...props} 
                className="otp-input-verify" 
                style={{
                  height: "55px",
                  width: "55px",
                  textAlign: "center",
                  borderRadius: "50%",
                  border: "2px solid #000",
                  margin: "0 5px",
                  outline: "none",
                  fontSize: "14px",
                }} 
              />
            )}
          />
        </div>
        {/* {error && <div className="mt-2 text-danger">{error.message}</div>}
        {props.success && <div className="mt-2 text-success">{props.success}</div>} */}
        <div className="mt-4 text-center w-100">
          <div className="text-dark mt-2">Didn’t receive any code?</div>
          <span className="text-danger" style={{cursor:'pointer'}} onClick={props.newOtp}>
            Request new code again.
          </span>
        </div>
        <button
          onClick={veri}
          className="mt-3 verifyres"
        >
          {props.type === 'forget' ? "Verify & Reset Password" : "Verify & Login"}
        </button>
      </div>
    </div>
  );
}
