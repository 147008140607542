import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./CandidateHired.css";
import Card5 from "../../../../../../components/CommonComp/Card5/Card5";
import { hiredCandidate } from "../../../../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../../../../components/CommonComp/Pagination/Pagination";

const CandidateHired = () => {
  const token = useSelector((state) => state.user.user.token);
  const { id } = useParams();
  const location = useLocation();
  const { establishmentData } = location.state || {};
  const navigate = useNavigate();

  const [hired, setHired] = useState([]);
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(true);

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [estab, setEstab] = useState({
    userName: "",
    estbName: "",
    uid:''
  });

  const handleHiredCandidate = async () => {
    try {
      const response = await hiredCandidate({ eid: id, token, activePage });
      setHired(response.history);
      setEstab({ estbName: response.estabName, userName: response.userName, uid:response.uid });
      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);
      setCount(response.total_count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  useEffect(() => {
    handleHiredCandidate();
  }, [activePage]);

  const handleJob = (id, candidate) => {
    navigate(`/jobposting/${id}`, { state: { hiredJob: candidate } });
  };
  const cardStyle = {
    width: "287px",
    minHeight: "126px",
    cursor: "default",
    display: "inline-block",
  };
  const headers = ["Candidate Name", "Email ID", "Phone Number", " "];

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="EstablishmentSection" to="/dashboard-page/Establishment-section" className='All-color-breakcurm'>&nbsp;Establishment</Link>,
    <Link key="EstablishmentDetails" to="/dashboard-page/Establishment-section/establishmnet-details" className='All-color-breakcurm'>&nbsp;Establishment Details</Link>,
    <Link key="EstablishmentProfile" to={`/estb/estbdetails/profile/${estab?.uid}`} className='All-color-breakcurm'>&nbsp;{estab?.userName||"User"}</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;{estab.estbName.split(" ")[0]} Candidates Hired</span>
  ];

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
        </div>

      <div className="estb-candidate-hired">
        <Card5 content={count} content1="Candidates Hired" style={cardStyle} />
      </div>

      <div className="Candidate-hired">
        Below is the list of candidates for Establishment {estab?.estbName||""}
      </div>

      <div className="candidate-tbl">
        <div className="table-responsive2 me-5 table-responsive">
          <table className="table table-responsive">
            <thead
              style={{ textAlign: "left", backgroundColor: "transparent" }}>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} scope="col" className="table-heading2">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="td-design2 table-responsive">
              {loading ? (
                <tr>
                  <td colSpan="4">
                    <Loader />
                  </td>
                </tr>
              ) : hired.length === 0 ? (
                <tr>
                  <td colSpan="4">No candidate hired</td>
                </tr>
              ) : (
                hired.map((candidate, index) => (
                  <tr key={candidate._id}>
                    <td className="cnditn-nme">
                      {candidate?.name || "Name not available"}
                      <div>
                        <Link
                          to={`/candidateProfile/${candidate._id}`}
                          className="view-profile-link">
                          View Profile
                        </Link>
                      </div>
                    </td>
                    <td>{candidate?.email || "Email not available"}</td>
                    <td>{candidate?.phoneNo || "PhoneNo not available"}</td>
                    <td>
                      <div>
                        <a
                          onClick={() => handleJob(candidate?._id, candidate)}
                          className="view-profile-link"
                          style={{ cursor: "pointer" }}>
                          View Job Posting
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          total_page={totalpage}
          next={next}
          prev={prev}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
};

export default CandidateHired;
