// Inside EstbReferal.js

import React, { useEffect, useState } from 'react';
import './EstbReferal.css';
import Card6 from '../../../../../../components/CommonComp/Card6ndTable/Card6';
import { totlReferals } from '../../../../../../Api';
import { useSelector } from 'react-redux';
import Pagination from '../../../../../../components/CommonComp/Pagination/Pagination';
import Loader from '../../../../../../components/CommonComp/Loader/Loader';
import { Link, useLocation, useParams } from 'react-router-dom';

const EstbReferal = () => {
  const token = useSelector((state) => state.user.user.token);
  const location = useLocation();
  const id = location.state.referId;

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [totalPage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [srno, setSrNo] = useState([]);
  const [userName,setUserName]=useState('')




  const fetchData = async () => {
    try {
      const response = await totlReferals({ uid: id, token, activePage });
      setUserName(response.UserName);
      
      if (response && response.history.length > 0) {
        setRowData(response.history);
      } else {
        setRowData([]);
      }

      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);

      const updatedSrNo = Array.from({ length: response.history.length }, (_, index) => (activePage - 1) * 12 + index + 1);
      setSrNo(updatedSrNo);

      setLoading(false);
    } catch (error) {
      console.log("Error fetching referral data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activePage]);

  const tableData = {
    headers: ['SR NO', 'Name', 'Phone Number', 'Date'],
    rows: rowData.map((referl, index) => ([
      `${srno[index]}`,
      referl?.name,
      referl?.phoneNo,
      new Date(referl?.createdAt).toLocaleDateString()
    ]))
  };

  const divStyle = {
    width: '55%'
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="EstablishmentSection" to="/dashboard-page/Establishment-section" className='All-color-breakcurm'>&nbsp;Establishment</Link>,
    <Link key="EstablishmentDetails" to="/dashboard-page/Establishment-section/establishmnet-details" className='All-color-breakcurm'>&nbsp;Establishment Details</Link>,
    <Link key="EstablishmentProfile" to={`/estb/estbdetails/profile/${id}`} className='All-color-breakcurm'>&nbsp;{userName||"Name not avilable"}</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;Referrals</span>
  ];
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
            </div>

      {loading ? (
        <p><Loader /></p>
      ) : (
          <div className='estb-profile-referl'>
            <Card6
              data={rowData.length}
              data1='Referrals'
              tableData={tableData}
              style={divStyle}
              totalPage={totalPage}
              next={next}
              prev={prev}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          </div>
        )}
    </div>
  )
}

export default EstbReferal;
