import React, { useEffect, useState } from "react";
import ComnTick from "../../../../components/CommonComp/ComnTick/ComnTick";
import Loader from "../../../../components/CommonComp/Loader/Loader";
import { HandleTicketMangEstb } from "../../../../Api";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/CommonComp/Pagination/Pagination";
import { Link } from "react-router-dom";

const Ticket = () => {
  // const [loading, setLoading] = useState(true);

  const role=2;

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="EstablishmentTicket"
      to="/dashboard-page/Establishment-section"
      className="All-color-breakcurm">
      &nbsp;Establishment
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Ticket Management</span>,
  ];
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>

      {/* {loading ? (
        <p>
          <Loader />
        </p>
      ) : ( */}
      <>
        <ComnTick  role={role}/>
        {/* <Pagination
            total_page={totalpage}
            next={next}
            prev={prev}
            activePage={activePage}
            setActivePage={setActivePage}
          /> */}
      </>
      {/* )} */}
    </div>
  );
};

export default Ticket;
