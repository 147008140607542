import React, { useState } from 'react'
import './Suscript.css';
import Button3 from '../Button3/Button3';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';


const Suscript = () => {

    const location =useLocation()
    // const planData = location.state.planData;
    const planData = location.state ? location.state.planData : null;

    const [isActive, setIsActive] = useState(planData && planData.status === 'active');
    const handleToggle = () => {
        setIsActive(!isActive);
       
      };
    
    // const ;
let navigate=useNavigate();

const handleedit=()=>{
    navigate('/dashboard/Establishment/SubscriptionManagement/ManageYourPlans')
   
}
  return (
    <div>

<div className='algn-top-bar me-5'>
  <div className='brd-crum'>
    Home &gt; Establishment &gt; Subscription Management &gt; Manage Your Plans &gt;
    {planData ? <b>Edit subscription</b>: <b>Add subscription</b>}
  </div>
</div>

        <div className='add-suscrpt-container'>

    <div className='plan same-margin'>
      <label htmlFor='Subscription Plan Name'>Subscription Plan Name</label>
      <div className='inpt-containr1'><input type='text' className='suscrpt-plan' value={planData && planData.name}/></div>
    </div>

    <div className='price same-margin'>
      <label htmlFor='Original Price' className='mt-3'>Original Price</label>
      <div className='inpt-containr1'><input type='text' className='suscrpt-plan' value={planData && planData.price}/></div>
    </div>

    <div className='discnt-price same-margin'>
      <label htmlFor='Discount Price'className='mt-3' >Discount Price</label>
      <div className='inpt-containr1'> <input type='text' className='suscrpt-plan' value={planData && planData.discountPrice}/> </div>
    </div>

            <div className='features same-margin'>
                <label htmlFor='features' className='mt-3'>Features</label>
                <div className='inpt-containr1'>
                <textarea id='features' name='features'  rows={5} cols={166} value={planData && planData.features}></textarea>
            </div>
        </div>


        <div className='toggle-switch'>
          <label className="switch">
            <input type="checkbox" checked={isActive} onChange={handleToggle} />
            <span className="slider round"></span>
          </label>
          <p>{isActive ? 'Active' : 'Inactive'}</p>
        </div>

    

         <div className='viewsusc-edit'>          
            <Button3 text="Submit" width= "229px" onclick={handleedit} />
            <Button3 text='Reset'  width="229px"  backgroundColor="white" color='black'/>
          </div> 
  </div>

    </div>
  )
}

export default Suscript
