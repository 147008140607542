import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import "./CommisionDet.css";
import SearchCommision from "./SearchCommision";
import {
  getPaymentDetails,
  payfigureData,
  settleAtOnce,
  settleBartenderPaymemt,
} from "../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CommisionDetails = () => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [paymentData, setPaymentData] = useState([]);
  const [payfigures, setPayFigures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("");

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [srno, setSrNo] = useState([]);
  const [myTimout, setMyTimeOut] = useState();
  const [chagne, setChange] = useState(false);

  // payment figures  data
  const fetchPayFigure = async () => {
    try {
      const response = await payfigureData({ token });
      setPayFigures(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching payment figures");
    }
  };

  // getpay details in tble
  const fetchPaymentInfo = async () => {
    try {
      const response = await getPaymentDetails({
        token,
        activePage,
        searchQuery,
        filter,
      });
      setPaymentData(response.history);

      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);

      const updatedSrNo = Array.from(
        { length: response.history.length },
        (_, index) => (activePage - 1) * 12 + index + 1
      );
      setSrNo(updatedSrNo);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching payment information");
    }
  };

  const handleSettleAtOnce = async () => {
    try {
      // Display the SweetAlert2 confirmation dialog
      const { isConfirmed: userConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to settle all payments at once?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, cancel!",
      });

      // Check if the user clicked the confirm button
      if (userConfirmed) {
        setLoading(true);

        // Call the settleAtOnce function and handle the response
        settleAtOnce(token)
          .then((res) => {
            setLoading(false);
            Swal.fire("Payment Success", "", "success");
          })
          .catch((err) => {
            setLoading(false);
            const errorMessage =
              err.response && err.response.data && err.response.data.error
                ? err.response.data.error
                : "An unknown error occurred";
            Swal.fire("Error", errorMessage, "error");
          });
      }
    } catch (error) {
      setError("Error in payout for bartender payment");
      console.log("payatonce error", error);
    }
  };

  // settle by particular btn in tble

  const handleSettle = async (orderId) => {
    try {
      // Display the SweetAlert2 confirmation dialog
      const { isConfirmed: userConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to proceed with the payment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, cancel!",
      });

      // Check if the user clicked the confirm button
      if (userConfirmed) {
        setLoading(true);

        // Call the settleBartenderPayment function and handle the response
        settleBartenderPaymemt({ token, orderId })
          .then((res) => {
            setTimeout(() => {
              setLoading(false);
              setChange(!chagne);
              Swal.fire({
                title: "Payment Success",
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }, 10000);
          })
          .catch((err) => {
            setLoading(false);
            const errorMessage =
              err.response && err.response.data && err.response.data.error
                ? err.response.data.error
                : "An unknown error occurred";
            Swal.fire("Error", errorMessage, "error");
          });
      }
    } catch (error) {
      setError("Error in payout for bartender payment");
    }
  };

  useEffect(() => {
    fetchPayFigure();
  }, [chagne]);

  useEffect(() => {
    fetchPaymentInfo();
  }, [activePage, filter, chagne, loading]);
  // }, [activePage, filter,loading]);

  useEffect(() => {
    clearTimeout(myTimout);
    setMyTimeOut(setTimeout(fetchPaymentInfo, 500));
  }, [searchQuery]);

  const handleSearch = (searchQuery, payStatus) => {
    setSearchQuery(searchQuery);
    setFilter(payStatus);
    setActivePage(1);
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };

  const headers = [
    "ID",
    "Bartender Name",
    "Establishment Name",
    "Amount",
    "Date of Payment",
    "Date of Job",
    " ",
  ];

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Payment Management</span>,
  ];

  return (
    <>
      <div className="d-flex justify-content-start align-item-center me-5">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="container main-payment-box">
        <div className="commoinsion-card-row">
          <div className="commoinsion-card-col">
            <div className="card contnt-mng">
              <div className="card-body text-center p-5">
                <h5 className="card-title totl-trnsc">Total Transaction</h5>
                <p className="card-text trns-text">
                  $
                  {payfigures?.Total
                    ? parseFloat(payfigures?.Total).toFixed(2)
                    : "0"}
                </p>
              </div>
            </div>
          </div>
          <div className="commoinsion-card-col">
            <div className="card contnt-mng">
              <div className="card-body text-center p-5">
                <h5 className="card-title totl-trnsc">Amount to be settled</h5>
                <p className="card-text trns-text">
                  $
                  {payfigures?.ToBeSettled
                    ? parseFloat(payfigures?.ToBeSettled).toFixed(2)
                    : "0"}
                </p>
              </div>
            </div>
          </div>
          <div className="commoinsion-card-col">
            <div className="card contnt-mng">
              <div className="card-body text-center p-5">
                <h5 className="card-title totl-trnsc">Total Settled Amount</h5>
                <p className="card-text trns-text">
                  $
                  {payfigures?.Settled
                    ? parseFloat(payfigures?.Settled).toFixed(2)
                    : "0"}
                </p>
              </div>
            </div>
          </div>
          <div className="commoinsion-card-col">
            <div className="card contnt-mng">
              <div className="card-body text-center p-5">
                <h5 className="card-title totl-trnsc">
                  Total Commission Received
                </h5>
                <p className="card-text trns-text">
                  $
                  {payfigures?.Commission
                    ? parseFloat(payfigures?.Commission).toFixed(2)
                    : "0"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serchbar-settllebtn">
        <SearchCommision onSearch={handleSearch} />
        {/* {paymentData.map((item,index)=>(
              
              item?.ToSettle ? (
                <button
                key={item._id}
                className="settle-once me-4"
                onClick={() => handleSettleAtOnce()}>
                Settle at once
              </button>
              ):(
                  null
              )
              
        ))} */}

        {paymentData.length > 0 &&
        paymentData.some((elem) => elem.ToSettle === true) ? (
          <button
            className="settle-once me-4"
            onClick={() => handleSettleAtOnce()}>
            Settle at once
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="table-responsive me-4 mt-3">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table className="table table-borderless tbl-design rounded-corners">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} className="text-center">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="commision-table-data">
                {paymentData.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center">
                      Payments data not available at this moment
                    </td>
                  </tr>
                ) : (
                  paymentData.map((item, index) => (
                    <tr key={index}>
                      <td>{srno[index]}</td>
                      <td>
                        {item?.assignedBartenders?.name || "Name not available"}
                      </td>
                      <td>
                        {item?.Establishment?.establishmentName ||
                          "Establishment name not available"}
                      </td>
                      <td>
                        {/* {item?.NewTotal ? `$${item?.NewTotal}` : "-"} */}$
                        {item?.NewTotal
                          ? parseFloat(item?.NewTotal).toFixed(2)
                          : "0"}
                      </td>
                      <td>
                        {item?.pay
                          ? new Date(item?.pay?.updatedAt).toLocaleDateString()
                          : "Payment not initialized"}
                      </td>
                      <td>
                        {item?.JobDate
                          ? new Date(item?.JobDate).toLocaleDateString()
                          : "Date not available"}
                      </td>

                      <td>
                        {item?.ToSettle ? (
                          <button
                            onClick={() => handleSettle(item?.pay?.orderId)}
                            className="settle-btn">
                            Settle
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
      <Pagination
        total_page={totalpage}
        next={next}
        prev={prev}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </>
  );
};

export default CommisionDetails;
