import React, { useEffect, useState } from "react";
import "./PushNotification.css";
import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import AddIcn from "../../../components/CommonComp/Assests/Add Circle.svg";
import test from "../../../components/CommonComp/Assests/sucess.png";
import UserAvtar from "../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import { getAllUser } from "../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import { toast } from "react-toastify";
import { ensureString } from "../../../utils/inputFormatter";

const GroupModal = ({ setActiveId, setGid, setActiveGroup }) => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [grops, setGroups] = useState([]);
  const [allGroup, setAllGroup] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [myTimout, setMyTimeOut] = useState();

  // const [selectedGroupId, setSelectedGroupId] = useState(null);

  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const GroupMod = async () => {
    try {
      const response = await getAllUser({ token, activePage, searchQuery });

      setGroups(response.history);
      setActivePage(response.page);
      setTotalPage(response.total_page);
      setNext(response.next);
      setPrev(response.prev);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("log form grop", error);
    }
  };

  useEffect(() => {
    GroupMod();
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);

    clearTimeout(myTimout);

    setMyTimeOut(setTimeout(GroupMod, 500));
  }, [searchQuery]);

  const handleSelectGroup = (id) => {
    if (selectedGroupIds.includes(id)) {
      setSelectedGroupIds(selectedGroupIds.filter((groupId) => groupId !== id)); // Deselect if already selected
    } else {
      setSelectedGroupIds([...selectedGroupIds, id]); // Select new group
    }
    // setGid([id])
  };

  const handleViewDetails = (group) => {
    setActiveGroup({
      gName: group.groupImg,
      gImg: group.groupName,
      gUesr: group.Users?.length,
    });
    setGid([group._id]);
    setActiveId(4);
  };

  const handleNext = () => {
    if (selectedGroupIds.length === 0) {
      toast.error("Select at least one group");
    } else {
      setActiveId(1);
    }
  };

  return (
    <div className="adad" style={{ width: "90%" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="serch-and-grop-container d-flex justify-content-between flex-wrap"
            style={{ width: "95%", marginTop: "-40px", marginLeft: "-16px" }}>
            <div class="search-container5">
              <img src={SearchIcon} alt="search-icon" />
              <input
                type="search"
                class="specificGropSeacrh ps-5"
                placeholder="Search Groups"
                value={searchQuery}
                // onChange={(e) => setSearchQuery(e.target.value)}
                onChange={(e) => {
                  if (e.target.value.trim() !== "" || e.target.value === "") {
                let value = ensureString({ value: e.target.value });
             
                  setSearchQuery(value);
                
              }
              }}
              />
            </div>

            <div className="cret-new-grp">
              <img
                src={AddIcn}
                alt="plus-icon"
                style={{ cursor: "pointer", width: "15px" }}
              />
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setActiveId(6)}>
                Create New Group
              </span>
            </div>
          </div>

          <div className="mt-4">
            {grops.length === 0 ? (
              <div className="no-groups-found text-center">No groups found</div>
            ) : (
              grops.map((item, index) => (
                <div
                  className="form-check d-flex justify-content-between group-select-item align-items-center"
                  key={`Group_SO_${index}`}>
                  <div className="input-img-name d-flex justify-content-around align-items-center">
                    <input
                      className="form-check-input chkDesing"
                      type="checkbox"
                      checked={selectedGroupIds.indexOf(item._id) !== -1}
                      onChange={() => handleSelectGroup(item._id)}
                    />
                    <label
                      className="form-check-label d-flex align-items-center"
                      htmlFor="user1">
                      <img
                        src={
                          item?.groupImg
                            ? `https://d28qcxi1juf339.cloudfront.net/${item?.groupImg}`
                            : UserAvtar
                        }
                        alt="User img"
                        className="user-img"
                      />
                      <div className="ms-3">
                        <div className="user-name">
                          {item?.groupName || "No group Name"}
                        </div>
                        <div className="user-email text-muted">
                          {item?.Users?.length || "0"} Users
                        </div>
                      </div>
                    </label>
                  </div>

                  <div
                    className="view-details"
                    onClick={() => handleViewDetails(item)}>
                    View Details
                  </div>
                </div>
              ))
            )}
          </div>


          <Pagination
            total_page={totalpage}
            next={next}
            prev={prev}
            activePage={activePage}
            setActivePage={setActivePage}
          />
          <div className="d-flex justify-content-center">
            <button className="custom-button-now mb-4" onClick={handleNext}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupModal;
