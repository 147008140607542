import React, { useEffect, useState } from "react";
import "./PushNotification.css";
import test from "../../../components/CommonComp/Assests/sucess.png";
import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import UserAvtar from "../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import { useSelector } from "react-redux";
import { getfechUser } from "../../../Api";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import Loader from "../../../components/CommonComp/Loader/Loader";
import { all } from "axios";
import { toast } from "react-toastify";
import { ensureString } from "../../../utils/inputFormatter";

const SpecificUserModal = ({ setIds, setActiveId }) => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [allUsers, setAllUsers] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);

  const getAllGroupUser = async () => {
    try {
      const resp = await getfechUser({ token, activePage, searchQuery });
      setAllUsers(resp.history);

      setActivePage(resp.page);
      setTotalPage(resp.total_page);
      setNext(resp.next);
      setPrev(resp.prev);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAllGroupUser();
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    const timeout = setTimeout(getAllGroupUser, 500);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const handleSpecificUserId = (item) => {
    const { _id } = item;
    setIds((prevIds) => {
      if (!prevIds.includes(_id)) {
        return [...prevIds, _id];
      } else {
        return prevIds;
      }
    });

    setSelectedIds((prevSelected) => {
      if (!prevSelected.includes(_id)) {
        return [...prevSelected, _id];
      } else {
        return prevSelected.filter((id) => id !== _id);
      }
    });
  };

  const handleNextButtonClick = () => {
    if (selectedIds.length === 0) {
      toast.error("Select at least one user to proceed.");
    } else {
      // console.log("Selected users to notify:", selectedIds);
      setActiveId(1);
    }
  };

  return (
    <div style={{ width: "95%", marginTop: "-42px" }}>
      <div className="ms-2 mt-2 fs-4">
        <div class="search-container">
          <img src={SearchIcon} alt="search-icon" />
          <input
            type="search"
            class="specificUserSeacrh w-75"
            placeholder="Search Users"
            value={searchQuery}
            onChange={(e) => {
              if (e.target.value.trim() !== "" || e.target.value === "") {
                let value = ensureString({ value: e.target.value });
                setSearchQuery(value);
              }
            }}
          />
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {allUsers.length === 0 ? (
            <p className="text-center">No users found.</p>
          ) : (
            allUsers.map((item, index) => (
              <div className="mt-4 mb-4" key={item._id}>
                <div className="w-100 ms-3">
                  <div className="form-check user-select-item1">
                    <input
                      className="form-check-input chkDesing"
                      type="checkbox"
                      // value={selectedIds.includes(item._id)}
                      checked={
                        selectedIds.indexOf(item._id) !== -1 ? true : false
                      }
                      onChange={() => handleSpecificUserId(item)}
                    />
                    <label className="form-check-label d-flex align-items-center">
                      <img
                        src={
                          item?.pic
                            ? `https://d28qcxi1juf339.cloudfront.net/${item?.pic}`
                            : UserAvtar
                        }
                        alt="User-img"
                        className="user-img"
                      />
                      <div className="ms-3">
                        <div className="user-name">
                          {item?.name || "Name not available"}
                        </div>
                        <div className="user-email">
                          {item?.email || "Email not available"}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            ))
          )}
        </>
      )}

      <Pagination
        total_page={totalpage}
        next={next}
        prev={prev}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <div className="d-flex justify-content-center">
        <button
          className="custom-button-now mb-4"
          onClick={handleNextButtonClick}>
          Next
        </button>
      </div>
    </div>
  );
};

export default SpecificUserModal;
