import React, { useEffect, useState } from 'react';
import CommonReview from '../../../../../components/CommonComp/Reactquill/Reactquil';
import Table1 from '../../../../../components/CommonComp/ReviewTable/Table1';
import { Link, useParams } from 'react-router-dom';
import { deletReview, deleteReview, totlReview } from '../../../../../Api';
import { useSelector } from 'react-redux';
import Loader from '../../../../../components/CommonComp/Loader/Loader';
import { toast } from 'react-toastify';
import Pagination from '../../../../../components/CommonComp/Pagination/Pagination';

const ReviewPage = () => {
  const token = useSelector((state) => state.user.user.token);
  const { id, uid } = useParams();

  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  
  const [totalPage, setTotalPage] = useState(0);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [userName,setUserName]=useState('')
  
  const fetchData = async () => {
    try {
      const response = await totlReview({ uid: id, token: token, activePage });
      setUserName(response?.UserName);
        setReviewData(response?.history);
        setActivePage(response?.page);
        setTotalPage(response?.total_page);
        setNext(response?.next);
        setPrev(response?.prev);
      
      
      setLoading(false);
    } catch (error) {
      console.log("Error fetching Review data:", error);
      setLoading(false);
      
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete this Review?");
      if (!isConfirmed) {
        return;
      }
      let type=1;
      const response = await deleteReview({ rid: id, token: token,type:type });
      setChange(!change);
      toast.success("Review Deleted Successfully");
      
    } catch (error) {
      toast.warning("Some error occurred");
      console.log("Error deleting review:", error);
    }
  };
  


  useEffect(() => {
    fetchData();
  }, [change, activePage]);

  const headers = ["Name", "Review", "Rating", "Date", "Action"];


  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="Bartender" to="/dashboard-page/bartender-section" className='All-color-breakcurm'>&nbsp;Bartender</Link>,
    <Link key="BartenderDetails" to="/dashboard-page/bartender-section/bartender-details" className='All-color-breakcurm'>&nbsp;Bartender Details</Link>,
    <Link key="BartenderEarnings" to={`/bartender/bartenderDetails/User/profile/${id}`}className='All-color-breakcurm'>&nbsp;{userName ||"User"}</Link>,
   <span style={{fontWeight:'bolder'}}>&nbsp;
    Reviews
  </span>
  ];

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
          </div> 

      <div className='fixed-size-div'>
        <p className='fixed-size-text'>{reviewData?.length}</p>
        <p className='fixed-size-text1'>Total Review</p>
      </div>
      
      <div className="listof-user">Below is the list of user reviews.</div>
      {loading ? (
        <Loader />
      ): (
        <>
          <Table1 headers={headers} reviewData={reviewData} change={change} setChange={setChange} handleDeleteReview={handleDeleteReview}/>
        </>
      )}
      <Pagination total_page={totalPage} next={next} prev={prev} activePage={activePage} setActivePage={setActivePage} />
    </div>
  );
}

export default ReviewPage;
