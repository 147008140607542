import React, { useEffect, useState } from "react";
import Card4 from "../../../../../components/CommonComp/Card4/Card4";
import CreateUserBtn from "../../../../../components/CommonComp/CreateUserBtn/CreateUserBtn";
import { Link, useNavigate } from "react-router-dom";
import "./Establishmnetdetails.css";
import SearchBar2 from "../../../../../components/CommonComp/SearchBar2/SearchBar2";
import { fetchEstbDetails, handleResumeUser, handleSuspendUser } from "../../../../../Api";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/CommonComp/Loader/Loader";
import Pagination from "../../../../../components/CommonComp/Pagination/Pagination";
import { toast } from "react-toastify";
import { Modal, Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const Establishmentdetails = () => {
  const token = useSelector((state) => state.user.user.token);
  const navigate = useNavigate();


  const handleClose = () => setSelectedEstbId(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [estbData, setEstbData] = useState([]);
  const [originalEstbData, setOriginalEstbData] = useState([]);
  const [activeUsersCount, setActiveUsersCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedEstbId, setSelectedEstbId] = useState(null);
  const [selectedEstb, setSelectedEstb] = useState(null);
  const [totalActiveUsersThisMonth, setTotalActiveUsersThisMonth] = useState(0);
  const [totalDataLength, setTotalDataLength] = useState(0);
  const [myTimout, setMyTimeOut] = useState();

  const [filter, setFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [srno, setSrNo] = useState([]);

  

  const fetchData = async () => {
    try {
      const res = await fetchEstbDetails({
        searchQuery,
        filter,
        startDate,
        endDate,
        activePage,
        token,
        setError,
      });
      setEstbData(res?.history);
      setOriginalEstbData(res);
      setActiveUsersCount(res?.total_active_user);
      setTotalActiveUsersThisMonth(res?.total_user_month);
      setTotalDataLength(res?.total_count);

      setActivePage(res.page);
      setTotalPage(res.total_page);
      setNext(res.next);
      setPrev(res.prev);

      const updatedSrNo = Array.from(
        { length: res.history.length },
        (_, index) => (activePage - 1) * 12 + index + 1
      );
      setSrNo(updatedSrNo);

      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activePage, filter, startDate, endDate]);

  useEffect(() => {
    clearTimeout(myTimout);

    setMyTimeOut(setTimeout(fetchData, 500));
  }, [searchQuery]);

  const handleEstable = (estbId) => {
    navigate(`/estb/estbdetails/profile/${estbId}`);
  };

  const handleSearch = (searchQuery, accountstatus, startDate, endDate) => {
    setSearchQuery(searchQuery);
    setFilter(accountstatus);
    setStartDate(startDate || "");
    setEndDate(endDate || "");
    setActivePage(1);
  };

  const handleEstbModal = (id) => {
    setSelectedEstbId(id);
    const estb = estbData.find((estb) => estb._id === id);
    setSelectedEstb(estb);
  };


  const handleResumeEstb=async(uid)=>{
      try {
        const isConfirmed = window.confirm(
          "Are you sure you want to Resume this Establishment Account?"
        );
        if (!isConfirmed) {
          return;
        }
      const response= await handleResumeUser({ uid, token });
        fetchData();
        toast.success(response.message);
      } catch (err) {
        toast.error(err.message )
        console.error("Error resuming user:", err);
      }
  
  }
  const handleSuspendEstb = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to suspend this Establishment?"
      );
      if (!isConfirmed) {
        return;
      }

     const response= await handleSuspendUser({ uid: id, token });
      fetchData()
      toast.success(response.message);
    } catch (error) {
      toast.error("Some Error occurred");
      console.error("Error suspending Establishment:", error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "gray"; // Inactive
      case 1:
        return "#00992B"; // Active
      case 2:
        return "#F33"; // Blocked
      case 3:
        return "#F33"; // Suspended
      case 4:
        return "#F33"; // Deleted
      case 5:
        return "#FFBA00";   //Pending Verification
      default:
        return "gray";
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="EstablishmentDetails" to="/dashboard-page/Establishment-section" className='All-color-breakcurm'>&nbsp;Establishment</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;Establishemt Details</span>
  ];
  return (
    <>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
            <div className="me-5">
          <CreateUserBtn
            data="Create New User"
            onClick={() => navigate("/estb/estbdetails/userprofile")}
          />
        </div>
      </div>

      <Card4
          data2={totalDataLength ?? 0}
          data3={totalActiveUsersThisMonth ?? 0}
          data4={activeUsersCount ?? 0}
        />


      <div className="Estb-app-text mt-3 fs-4 mb-3">
        Below is the list of establishments in BAROOOM app
      </div>

      <SearchBar2 onSearch={handleSearch} />

      <div className="est-details">
        <div className="me-5 allestablishment-details-table">
          {loading ? (
            <p>
              <Loader />
            </p>
          ) : (
            <div className="table-responsive">
              <table className="table table-borderless tbl-design rounded-corners">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Establishment Representative</th>
                    <th>Email ID</th>
                    <th>Phone Number</th>
                    <th>No. of Establishments</th>
                    <th>Bartenders Hired</th>
                    <th>Ratings</th>
                    <th>Status</th>
                    <th>Joined Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="tbpading">
                  {estbData.length > 0 ? (
                    estbData.map((estab, index) => (
                      <tr key={estab._id}>
                        <td>{srno[index]}</td>
                        <td>
                          <a
                            className="anchr-clik"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEstable(estab._id)}>
                            {estab?.name || "-"}
                          </a>
                        </td>
                        <td>{estab?.email || "-"}</td>
                        <td>{estab?.phoneNo || "-"}</td>
                        <td>
                          <a
                            className="anchr-clik"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEstbModal(estab._id)}>
                            {estab?.NoEstablishment || "-"}
                          </a>
                        </td>
                        <td>{estab?.BartenderHired || "-"}</td>
                        <td>{estab?.Rating || "-"}</td>
                        <td
                          style={{
                            color: getStatusColor(estab?.account_status),
                          }}>
                          {(() => {
                            switch (estab?.account_status) {
                              case 0:
                                return "Inactive";
                              case 1:
                                return "Active";
                              case 2:
                                return "Blocked";
                              case 3:
                                return "Suspended";
                              case 4:
                                return "Deleted";
                              case 5:
                                return "Pending Verification"; 
                              default:
                                return "Inactive";
                            }
                          })()}
                        </td>

                        <td>
                          {new Date(estab.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td>
                          <div className="dropdown">
                            <button
                              className="btn"
                              type="button"
                              id={`dropdownMenuButton${index}`}
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <b>...</b>
                            </button>
                            <ul
                              className="dropdown-menu bordr-drp"
                              aria-labelledby={`dropdownMenuButton${index}`}>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleEstable(estab._id)}>
                                  View User 
                                </a>
                              </li>
                              <li>
                              {estab?.account_status === 3 ? (
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() => handleResumeEstb(estab._id)}>
                                      Resume User
                                    </a>
                                  </li>
                                ) : (
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() => handleSuspendEstb(estab._id)}>
                                      Suspend User 
                                    </a>
                                  </li>
                                )}
                                </li>
                                  {/* <a
                                  className="dropdown-item"
                                  onClick={() => handleSuspendEstb(estab._id)}>
                                  Suspend User Account
                                </a>
                              </li> */}
                              {/* <li><a className="dropdown-item">Delete User Account</a></li> */}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        No Establishment available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
             
            </div>
          )}
           <Pagination
                total_page={totalpage}
                next={next}
                prev={prev}
                activePage={activePage}
                setActivePage={setActivePage}
              />
        </div>
      </div>

      {/* {selectedEstbId && selectedEstb && (
        <>
          <div
            className="modal-overlay"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 999,
            }}
            onClick={() => setSelectedEstbId(null)}
          />
          <div
            className="modal"
            style={{ display: "block", zIndex: 1000 }}
            tabIndex="-1"
            role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content modal-content-scroll"
                style={{ overflowY: "auto" }}>
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setSelectedEstbId(null)}
                    aria-label="Close"
                    style={{ float: "right" }}></button>
                  {selectedEstb?.Establishment_Details &&
                  selectedEstb.Establishment_Details.length > 0 ? (
                    selectedEstb?.Establishment_Details?.map(
                      (detail, index) => (
                        <div key={index}>
                          <h3 style={{color:'#F33'}}>
                            {detail?.establishmentName || "No Establishment"}
                          </h3>
                          <p>
                            {detail?.location ? (
                              <>
                                {detail.location.streetName || "No data"},{" "}
                                {detail.location.city || "No data"},{" "}
                                {detail.location.state || "No data"},{" "}
                                {detail.location.postalCode || "No data"}
                              </>
                            ) : (
                              "No Location Avilable"
                            )}
                          </p>
                        </div>
                      )
                    )
                  ) : (
                    <div>
                      <h3>Establishment details not available</h3>
                     
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}

<Modal
      open={Boolean(selectedEstbId && selectedEstb)}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          width: isSmallScreen ? '90%' : 523,
          borderRadius: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
          // borderColor:'white'
        }}
      >
        <IconButton
          onClick={handleClose}
          aria-label="Close"
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        {selectedEstb?.Establishment_Details &&
        selectedEstb.Establishment_Details.length > 0 ? (
          selectedEstb?.Establishment_Details?.map((detail, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="h6" component="h3" sx={{ color: '#F33' }}>
                {detail?.establishmentName || "No Establishment"}
              </Typography>
              <Typography variant="body1">
                {detail?.location ? (
                  <>
                    {detail.location.streetName || "No data"},{" "}
                    {detail.location.city || "No data"},{" "}
                    {detail.location.state || "No data"},{" "}
                    {detail.location.postalCode || "No data"}
                  </>
                ) : (
                  "No Location Available"
                )}
              </Typography>
            </Box>
          ))
        ) : (
          <Box>
            <Typography variant="h6" component="h3">
              Establishment details not available
            </Typography>
            {/* <Typography variant="body1">No details available for this establishment.</Typography> */}
          </Box>
        )}
      </Box>
    </Modal>
    </>
  );
};

export default Establishmentdetails;
