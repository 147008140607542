import React from "react";
import "./Setting.css";
import Card from "../../components/CommonComp/Card/Card";
import { Link, useLocation } from "react-router-dom";
import Notification from "../../components/CommonComp/Assests/Notification.png";
const Setting = () => {
  // const location = useLocation();

  // Function to get the current route name based on the path
  // const getCurrentRouteName = () => {
  //   switch (location.pathname) {
  //     case '/settings/push-notification':
  //       return 'Push Notifications';
  //     case 'Home/settings/your-profile':
  //       return 'Your Profile';
  //     default:
  //       return 'Settings';
  //   }
  // };

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Settings</span>,
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
      </div>
      <div className="container setting-crd-design">
        <div className="row">
          <div className="col-md-6 mb-4 settting-design">
            <Link to="/settings/push-notification" className="nav-link">
              <Card img={Notification} content="Push Notifications" />
            </Link>
          </div>
          {/* <div className="col-md-6 mb-4 settting-design"> */}

          {/* <Link to="/settings/your-profile" className="nav-link">
            <Card  content='Your Profile'/>
        </Link> */}

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Setting;
