import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
 
const firebaseConfig = {
  apiKey: "AIzaSyAO7FHZ97nRhSynG9UoqsCpxY6zR99-usc",
  authDomain: "barooom-app.firebaseapp.com",
  projectId: "barooom-app",
  storageBucket: "barooom-app.appspot.com",
  messagingSenderId: "163811567172",
  appId: "1:163811567172:web:e401acef52cd2cc5a7be11",
  measurementId: "G-07P0JDTLGP"
};
 
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)