import React from 'react'
import './PushNotification.css';
import { notificationtToAny } from '../../../Api';
import { useSelector } from 'react-redux';

const FiveBtnModal = ({type,setType,setActiveId}) => {

 

  return (
    <div className='d-flex flex-column'>
        <button className="custom-button"  onClick={() => {setType(0);setActiveId(1)}}>Send To All Users</button>
        <button className="custom-button" onClick={() => {setType(1);setActiveId(1)}}>Send To All Establishments</button>
        <button className="custom-button"  onClick={() => {setType(2);setActiveId(1)}}>Send To All Bartenders</button>
        <button className="custom-button"    onClick={() => {setType(3);setActiveId(3)}}>Send To Groups</button>
        <button className="custom-button mb-5"  onClick={() => {setType(4);setActiveId(7)}}>Send To Specific Users</button>
      </div>
  )
}

export default FiveBtnModal;
