import React, { useEffect, useState } from "react";
import "./PushNotification.css";
import test from "../../../components/CommonComp/Assests/sucess.png";
import SearchIcon from "../../../components/CommonComp/Assests/charm_search.svg";
import LessthanIcon from "../../../components/CommonComp/Assests/Vector.png";
import { addNewUserToGroup, getAllUser, getfechUser } from "../../../Api";
import { useSelector } from "react-redux";
import Pagination from "../../../components/CommonComp/Pagination/Pagination";
import Loader from "../../../components/CommonComp/Loader/Loader";
import UserAvtar from "../../../components/CommonComp/Assests/carbon_user-avatar.svg";
import { toast } from "react-toastify";
import { ensureString } from "../../../utils/inputFormatter";
const FinalUserPageModal = ({ setActiveId, gid, groupUserIds }) => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [allUsers, setAllUsers] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalpage, setTotalPage] = useState();
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [ids, setIds] = useState([]);

  const getAllGroupUser = async () => {
    try {
      const resp = await getfechUser({
        token,
        activePage,
        searchQuery,
        gId: gid,
      });
      setAllUsers(resp.history);

      setActivePage(resp.page);
      setTotalPage(resp.total_page);
      setNext(resp.next);
      setPrev(resp.prev);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAllGroupUser();
  }, [activePage]);

  useEffect(() => {
    setActivePage(0);
    const timeout = setTimeout(getAllGroupUser, 500);
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const handleUserId = (id) => {
    // console.log("heyyy",id);

    setIds((prevIds) => {
      if (!prevIds.includes(id)) {
        return [...prevIds, id];
      } else {
        return prevIds.filter((userId) => userId !== id);
      }
    });
    // setIds([id])

    // setIds((prevIds) => {
    //   if (!prevIds.includes(id)) {
    //     return [...prevIds,id];
    //   } else {
    //     return prevIds;
    //   }
    // });
  };

  const handleAddUser = async () => {
    try {
      let passGid = null;
      if (Array.isArray(gid)) {
        passGid = gid[0];
      } else {
        passGid = gid;
      }

      if (ids.length === 0) {
        toast.error("Please select at least one user.");
        return;
      }
      const action = true;
      const response = await addNewUserToGroup({
        token,
        action,
        ids,
        gid: passGid,
      });
      setActiveId(4);
      toast.success("User Added Sucessfully");
    } catch (error) {
      toast.error("Some error occured");
      console.log("error in addiing", error);
    }
  };

  // const isChecked = (groupUserIds) => {
  //   console.log('groupUserIds:', groupUserIds);
  //   console.log('allUsers:', allUsers);

  //   for (let i = 0; i < groupUserIds.length; i++) {
  //     for (let j = 0; j < allUsers.length; j++) {
  //       if (allUsers[j]._id === groupUserIds[i]) {
  //         console.log(`Match found for ID: ${groupUserIds[i]}`);
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // };

  // const isChecked = (id, groupUserIds, allUsers) => {

  //   const isIdInGroup = groupUserIds.includes(id);

  //   const isIdInAllUsers = allUsers.some(user => user._id === id);

  //   return isIdInGroup && isIdInAllUsers;
  // };

  return (
    <div style={{ width: "100%" }}>
      <div
        className="d-flex justify-content-between align-items-center fs-4"
        style={{ width: "70%", marginTop: "-40px" }}>
        <div className="d-flex align-items-center">
          &nbsp;
          <img
            src={LessthanIcon}
            alt="less-than-icon"
            style={{ cursor: "pointer" }}
            onClick={() => setActiveId(4)}
          />
          {/* <div className="form-check user-item-addmorepage">
            <img
              src={
                gImg ? `https://d28qcxi1juf339.cloudfront.net/${gImg}` : test
              }
              alt="User img"
              className="user-img"
            />

            <div className="ms-3">
              <div className="user-name">{gName}</div>
              <div className="user-email">{gUser} Users</div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="ms-2 mt-2 fs-4 align-items-stretch">
        <div class="search-container w-100">
          <img src={SearchIcon} alt="search-icon" />
          <input
            type="search"
            class="specificUserSeacrh w-100"
            placeholder="Search Users"
            // onChange={(e) => setSearchQuery(e.target.value)}
            onChange={(e) => {
              if (e.target.value.trim() !== "" || e.target.value === "") {
              let value = ensureString({ value: e.target.value });
         
              setSearchQuery(value);
            
          }
          }}
          />
        </div>
      </div>

      {loading ? (
        <div>
          <Loader />
        </div>
      ) : allUsers && allUsers.length == 0 ? (
        <div className="text-center mt-4">No users found.</div>
      ) : (
        allUsers.map((item, index) => (
          <>
            <div
              className="d-flex flex-row justify-content-between mt-4 mb-4"
              key={item._id}>
              <div className="ms-3 ">
                <div className="form-check user-item-addmorepage">
                  <input
                    className="form-check-input chkDesing"
                    type="checkbox"
                    checked={ids.indexOf(item._id) !== -1 ? true : false}
                    onChange={() => handleUserId(item._id)}
                    // checked={isChecked(groupUserIds)}
                    // checked={isChecked(item._id, groupUserIds, allUsers)}
                  />
                  <label
                    className="form-check-label d-flex align-items-center"
                    htmlFor="user1">
                    <img
                      src={
                        item.pic
                          ? `https://d28qcxi1juf339.cloudfront.net/${item.pic}`
                          : UserAvtar
                      }
                      alt="User img"
                      className="user-img ms-4"
                    />

                    <div className="ms-3">
                      <div className="user-name">
                        {item?.name || "Name not avilable"}
                      </div>
                      <div className="user-email">
                        {item?.email || "Email not avilable"}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </>
        ))
      )}

      <Pagination
        total_page={totalpage}
        next={next}
        prev={prev}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      <div className="d-flex justify-content-center">
        <button
          className="custom-button-now mb-4"
          onClick={() => handleAddUser()}>
          Add User
        </button>
      </div>
    </div>
  );
};

export default FinalUserPageModal;
