import React, { memo, useEffect, useState, useCallback } from 'react';
import './ProfileImg.css';
import { GoPencil } from "react-icons/go";

const ProfileImg = ({ user, setUser }) => {
  const [imagePreview, setImagePreview] = useState(null);

  const readURL = useCallback((input) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }, []);

  const chkImg=()=>{
    if(user.profile!=null){
      setImagePreview(user.profile)
    }
    else{
    setImagePreview(null);
    }
  }
    // added 
    // useEffect(()=>{
    //   chkImg()

    // },[])

  // const handleInputChange = useCallback((e) => {
  //   readURL(e.target);
  //   setUser({...user,profile:e.target.files[0]})
  // }, [readURL, setUser]);

  const handleInputChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      readURL(e.target);
      setUser(prevUser => ({ ...prevUser, profile: file }));
    }
  }, [readURL, setUser]);

  return (
    <div>
      <div className="avatar-upload">
        
        <div className="avatar-edit">
          <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleInputChange} />
          <label htmlFor="imageUpload"><GoPencil className='gopencil' /></label>
        </div>
        <div className="avatar-preview">
          {imagePreview ? (
            <div id="imagePreview" style={{ backgroundImage: `url(${imagePreview})`, width: '148px', height: '146px' }}></div>
          ) : (
            <div id="imagePreview" style={{ backgroundImage: `url('https://d28qcxi1juf339.cloudfront.net/${user.profile}')`, width: '148px', height: '146px' }}></div>
          )}
        </div>
        {/* <div className='half-curve-design'>
       </div> */}
        {(user.firstName || user.lastName) && (
          <div className='profile-ext' style=
          // width:175px;
          {{width:'152px'}}>
            <div className='elips-text' style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
              {`${user.firstName} ${user.lastName||""}`}
            </div>
            <div className='elips-email'>
              {user.email}
            </div>
          </div>
         )} 
      </div>
    </div>
  );
};

export default memo(ProfileImg);
