import { createSlice, current } from '@reduxjs/toolkit';


const initialState = {
  user: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers:{
    login : (state, action)=>{
        state.user = action.payload;

       

        if(action.payload.keepLogged){

          localStorage.setItem("bsuser",JSON.stringify(state.user));
        }
        else{
          const now= new Date()
          
          
           const expiry=now.getTime()+1*60*1000
          
          sessionStorage.setItem("bsuser",JSON.stringify({...state.user,expiry}));
        }
    },
    logout:(state)=>{
        state.user = null;
        sessionStorage.removeItem("bsuser");
        localStorage.removeItem("bsuser");
    },
    updateUser : (state, action)=>{
      state.user= {...state.user, ...action.payload}
      if(localStorage.getItem("bsuser")){
        localStorage.setItem("bsuser",JSON.stringify(state.user));
      }
      else{
        sessionStorage.setItem("bsuser",JSON.stringify(state.user));
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
    login,
    logout,
    updateUser
   } = userSlice.actions

export default userSlice.reducer