import React, { useState } from "react";
import "./ReportTable.css";
import UserAvatar from "../Assests/carbon_user-avatar.svg";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const ReportTable = ({ filteredData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const headers = ["Name", "No of times reported", "Date", "  "];

  const handleViewReport = (userData) => {
    setSelectedUser(userData);
    setShowModal(true);
  };

  return (
    <div>
      <div className="table-responsive2 me-5 table-responsive">
        <table className="table table-responsive">
          <thead
            style={{ textAlign: "justify", backgroundColor: "transparent" }}>
            <tr>
              {headers.map((header, index) => (
                <th key={index} scope="col" className="table-heading2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="td-design2 table-responsive">
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="4" className="no-reports">
                  Reports not available at this moment
                </td>
              </tr>
            ) : (
              filteredData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <div className="user">
                      <img
                        src={
                          rowData?.UserDetails?.pic
                            ? `https://d28qcxi1juf339.cloudfront.net/${rowData?.UserDetails.pic}`
                            : UserAvatar
                        }
                        alt="User-Image"
                      />
                      <div className="user-details">
                        <div className="name">
                          {rowData?.UserDetails?.name || "No Data"}
                        </div>
                        <div className="email mt-1">
                          {rowData?.UserDetails?.email || "No Data"}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="centered-text">
                    {rowData?.TotalReports || "No Data"}
                  </td>
                  <td className="centered-text">
                    {rowData?.Created || "No Data"}
                  </td>
                  <td className="centered-text">
                    <button
                      className="view-report"
                      onClick={() => handleViewReport(rowData)}>
                      View Report
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSmallScreen ? "90%" : 435,
            height: 264,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            maxHeight: "90vh",
            overflowY: "auto",
          }}>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography id="modal-title" variant="h6" component="h2">
              <b>Report Details</b>
            </Typography>
            <IconButton onClick={() => setShowModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box id="modal-description">
            {selectedUser &&
              selectedUser.complains.map((res, index) => (
                <Typography mt={1}>
                  {res?.reason || "No Content"}{" "}
                  <span style={{ color: "red" }}>({res?.count})</span>
                </Typography>
              ))}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ReportTable;
