import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './ViewSuscription.css';

import Button3 from '../../../../../components/CommonComp/Button3/Button3';


const ViewSusciption = () => {

  const location = useLocation();
  const planData = location.state.planData;
  console.log(planData)


  const navigate = useNavigate();



  const routetoEditPage=()=>{
    navigate(
      '/dashboard/establishment/subscription/manageyourplans/editSuscription',
      {state: { planData: planData }}
  )
}

  const widthAdjust = "229px";
  const color="white"
  return (


    <>
      <div className="algn-top-bar">
        <div className="brd-crum">
          Home &gt; Establishment &gt;Suscription &gt; Manage Your Plans &gt;<b>View Subscription</b> 
          
        </div>
      </div>

        <div className='view-suscription-wrapper'>
            <div className='suscription-pln'>
              <p>Subscription Plan Name</p>
              <p>{planData.name}</p>
            </div>

            <div className='suscription-pln'>
              <p>Original Price</p>
              <p>{planData.price}</p>
            </div>

            <div className='suscription-pln'>
              <p>Discounted Price</p>
              <p>{planData.discountPrice}</p>
            </div>

            <div className='suscription-pln'>
              <p>Features </p>
              <p>{planData.features} </p>
            </div>


        {/* <div className='togle'>
            <label className="switch">
              <input type="checkbox" checked/>
              <span className="slider round"></span>
              
            </label>
            <p>Active</p>
            </div> */}

            <div className='viewsusc-edit'>          
             <Button3 text="Edit" width={widthAdjust} handleedit={routetoEditPage} />
            </div>  
          </div>     
    </>
// /dashboard/Establishment/SubscriptionManagement/ManageYourPlans
  )

}

export default ViewSusciption
