import React from "react";
import Card from "../../../../components/CommonComp/Card/Card";

import BartenderLogo from "../../../../components/CommonComp/Assests/bartender 1.svg";
import ReportIcon from "../../../../components/CommonComp/Assests/shutterstock_1409636174.png";
import SuscriptionIcon from "../../../../components/CommonComp/Assests/pixelarticons_subscriptions.svg";
import TicketIcon from "../../../../components/CommonComp/Assests/Ticket.png";
import ContentMngMentIcon from "../../../../components/CommonComp/Assests/jam_write-f.svg";
import ElipseIcon from "../../../../components/CommonComp/Assests/Ellipse 63.svg";
import { Link, NavLink } from "react-router-dom";
import Header1 from "../../../../components/CommonComp/Header1/Header1";

const EstablishMentsection = () => {
  return (
    <div>
      <Header1 />

      <div className="row card-row me-5 barsss">
        <div className="col-lg-4 mb-3 mb-md-0">
          <Link
            to="/dashboard-page/Establishment-section/establishmnet-details"
            className="nav-link">
            <Card img={BartenderLogo} content="Establishment Details" />
          </Link>
        </div>
        <div className="col-lg-4  margin-25">
          <Link
            to="/dashboard-page/Establishment-section/ticket-management"
            className="nav-link">
            <Card img={TicketIcon} content="Ticket Management" />
          </Link>
        </div>
        {/* <div className='col-lg-4  margin-25'>
                    <Link to='/dashboard-page/Establishment-section/content-management' className='nav-link'>
                         <Card img={ContentMngMentIcon} content='Content Management ' />
                    </Link>
                </div> */}

        <div className="col-lg-4  margin-25">
          <Link
            to="/dashboard-page/Establishment-section/report-item"
            className="nav-link">
            <Card img={ReportIcon} content="Reported Establishments" />
          </Link>
        </div>
        <div className="col-lg-4  margin-25">
          <Link
            to="/dashboard-page/Establishment-section/mark-no-show"
            className="nav-link">
            <Card
              img={ReportIcon}
              content="Absent Bartender's (Mark No Show)"
            />
          </Link>
        </div>

        {/* <div className='col-lg-4  margin-25'>
                    <Link to='/dashboard-page/Establishment-section/suscription-item' className='nav-link'>
                         <Card img={SuscriptionIcon} content='Subscription Management'/>
                    </Link>
                </div> */}
      </div>
    </div>
  );
};

export default EstablishMentsection;
