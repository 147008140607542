export const list = [
  "US/Alaska",
  "US/Arizona",
  "US/Central",
  "US/Eastern",
  "US/Hawaii",
  "US/Mountain",
  "US/Pacific",
];

export const getAntStyleList = () => {
  return list.map((elem) => ({ label: elem, value: elem }));
};
