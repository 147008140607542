import React, { useState } from "react";
import "./CreateUserPage.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createBartenderDetails } from "../../../../../Api";
import ProfileImg from "../../../../../components/CommonComp/ProfileImg/ProfileImg";
import UserPage from "../../../../../components/CommonComp/UserPage/UserPage";

const CreateuserPage = () => {
  const token = useSelector((state) => state.user.user.token);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    city: "",
    state: "",
    zipCode: "",
    dob: "",
    role: 2,
    profile: ""
  });

  
  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  // const validateDOB = (dob) => {
  //   const pattern = /^([0-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;

  // if (!pattern.test(dob)) {
  //   return false;
  // }

  // return true;
  // };

  const handleSubmit = async () => {
    // Check if any mandatory field is missing
    if (
        !formData.name.trim() ||
        !formData.email.trim() ||
        !formData.phoneNo.trim() ||
        !formData.city.trim() ||
        !formData.state.trim() ||
        !formData.zipCode.trim() ||
        formData.role !== 2 ||
        !formData.dob.trim()
    ) {
        toast.error("All Fields Mandatory!");
        return;
    }

    // Validate email format
    if (!validateEmail(formData.email)) {
        toast.error("Please provide a valid email address.");
        return;
    }

    try {
        const response = await createBartenderDetails({ token, userData: formData });

  
      
            setFormData({
                name: "",
                email: "",
                phoneNo: "",
                city: "",
                state: "",
                zipCode: "",
                dob: "",
                // role: 2,
                profile: ""
            });
            toast.success(response.message);
            navigate('/dashboard-page/Establishment-section/establishmnet-details');
        
    } catch (err) {
        toast.error(err.message);
        console.error("Error during API call:", err);
    }
};

  
  

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="EstablishmentSection" to="/dashboard-page/Establishment-section" className='All-color-breakcurm'>&nbsp;Establishment</Link>,
    <Link key="EstablishmentDetails" to="/dashboard-page/Establishment-section/establishmnet-details" className='All-color-breakcurm'>&nbsp;Establishment Details</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;Create User</span>
  ];
  
  return (
    <div>
     <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
            </div>

      <div className="unique-grid row justify-content-center text-center">
        <ProfileImg user={formData} setUser={setFormData} />
        <UserPage formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}

export default CreateuserPage;
